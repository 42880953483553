import { HighchartsWithAccessibility, sharedChartOptions } from "../helpers";
import HighchartsReact from "highcharts-react-official";
import { memo } from "react";
import { CHART_DATA_LIMIT } from "../../../Constants/constants";
import { Loading } from "../../LoadingSpinner/Loading";
import { ChartErrorState } from "../ChartErrorState/ChartErrorState";
import ChartWarning from "../ChartWarning/ChartWarning";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";

const ScatterChartH = ({ data, height, width, chartConfig, loading, error, totalRows }) => {
  const { _ } = useLingui();
  // Identify which columns to use for x and y axes
  if (loading || !chartConfig || !chartConfig.columns || !data) {
    return <Loading />;
  }
  if (error) {
    return <ChartErrorState />;
  }
  const xColumn = chartConfig?.columns.find(
    (column: string | number) =>
      chartConfig.column_types[column] === "numerical" && column !== chartConfig.columns[1]
  );
  const yColumn = chartConfig?.columns.find(
    (column: string | number) =>
      chartConfig.column_types[column] === "numerical" && column !== xColumn
  );
  const categoryColumn = chartConfig?.columns.find(
    (column: string | number) => chartConfig.column_types[column] === "categorical"
  );

  // Prepare the series data based on whether there is a categorical column
  const series: HighchartsWithAccessibility.SeriesScatterOptions[] = categoryColumn
    ? // Group data by categories and map each category to a series
      Array.from(new Set(data.map((item: { [x: string]: any }) => item[categoryColumn]))).map(
        (category: string) => ({
          name: category,
          type: "scatter" as const,
          data: data
            .filter((item: { [x: string]: string }) => item[categoryColumn] === category)
            .map((item: { [x: string]: any }) => [item[xColumn], item[yColumn]]),
          marker: {
            // Different symbols can be used per category if needed
            symbol: category === "Category1" ? "square" : "circle",
          },
        })
      )
    : // Single series without categorization
      [
        {
          name: _(msg`Data Points`),
          type: "scatter" as const,
          data: data.map((item: { [x: string]: any }) => [item[xColumn], item[yColumn]]),
          marker: {
            symbol: "circle",
          },
        },
      ];

  const options: HighchartsWithAccessibility.Options = {
    ...sharedChartOptions,
    chart: {
      ...sharedChartOptions.chart,
      type: "scatter" as const,
      height,
      width,
    },
    title: {
      text: chartConfig.chart_title || _(msg`Scatter Plot Visualization`),
    },
    xAxis: {
      title: {
        text: xColumn ? xColumn : _(msg`X-Axis Data`),
      },
      crosshair: chartConfig.x_axis_crosshair ?? true,
    },
    yAxis: {
      title: {
        text: yColumn ? yColumn : _(msg`Y-Axis Data`),
      },
      crosshair: chartConfig.y_axis_crosshair ?? true,
    },
    legend: {
      layout: chartConfig.legend_layout || "vertical",
      align: "right",
      verticalAlign: chartConfig.legend_vertical_align || "top",
      floating: chartConfig.legend_floating ?? true,
    },
    series,
    credits: {
      enabled: false,
    },
    tooltip: {
      headerFormat: "<b>{series.name}</b><br>",
      // eslint-disable-next-line lingui/no-unlocalized-strings
      pointFormat: `${xColumn}: {point.x}<br>${yColumn}: {point.y} `,
      enabled: chartConfig.tooltip ?? true,
    },
  };

  return (
    <div>
      <HighchartsReact highcharts={HighchartsWithAccessibility} options={options} />
      {totalRows > CHART_DATA_LIMIT && <ChartWarning />}
    </div>
  );
};

const MemoizedScatterChartH = memo(ScatterChartH, (prevProps, nextProps) => {
  return (
    prevProps.data === nextProps.data &&
    prevProps.height === nextProps.height &&
    prevProps.width === nextProps.width &&
    prevProps.chartConfig === nextProps.chartConfig &&
    prevProps.totalRows === nextProps.totalRows
  );
});

export default MemoizedScatterChartH;
