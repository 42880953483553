import { MutableRefObject, useEffect, useState } from "react";

import { useChart } from "../../../../../context/chart";
import { useChatData } from "../../../../../context/chat";
import { Doowii } from "../../../../../search/Doowii";
import { Analytics } from "../../../../../services/analytics/Analytics";
import { FullscreenModal } from "./FullscreenModal/FullscreenModal";
import { SQLTray } from "./SQLTray/SQLTray";
import { WhiteBoardHeader } from "./WhiteboardHeader/WhiteboardHeader";
import { DataVisualizationTabs } from "../../../DataVisualizationTabs/DataVisualizationTabs";

interface WhiteboardProps {
  loading: boolean;
  loadingText: string;
  openPinboardModal: boolean;
  setOpenPinboardModal: (open: boolean) => void;
  doowii: MutableRefObject<Doowii | null>;
}

export const Whiteboard = ({
  loading,
  loadingText,
  openPinboardModal,
  setOpenPinboardModal,
  doowii,
}: WhiteboardProps) => {
  const [chartType, setChartType] = useState(null);
  const { allResults, selectResult, updateTitle, currentResult: results } = useChatData();
  const { setSelectedColumns } = useChart();

  const [fullScreenOpen, setFullScreenOpen] = useState(false);
  const [sqlTrayOpen, setSqlTrayOpen] = useState(false);
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    if (fullScreenOpen) {
      Analytics.track("Result full screen");
    }
  }, [fullScreenOpen]);

  const handleNavigation = (direction) => {
    let index = allResults.findIndex((result) => result?.id === results?.id);
    if (direction === "left") {
      // @ts-ignore
      selectResult(allResults[index - 1]);
    } else {
      // @ts-ignore
      selectResult(allResults[index + 1]);
    }
  };

  return (
    <>
      <SQLTray results={results} sqlTrayOpen={sqlTrayOpen} setSqlTrayOpen={setSqlTrayOpen} />

      <div className="flex flex-1 flex-col ">
        <WhiteBoardHeader
          loading={loading}
          results={results}
          allResults={allResults}
          updateTitle={updateTitle}
          handleNavigation={handleNavigation}
          totalRows={totalRows}
          chartType={chartType}
          openPinboardModal={openPinboardModal}
          setOpenPinboardModal={setOpenPinboardModal}
          setSqlTrayOpen={setSqlTrayOpen}
          setFullScreenOpen={setFullScreenOpen}
        />

        
        <DataVisualizationTabs
          result={results}
          setTotalRows={setTotalRows}
          chartType={chartType}
          setChartType={setChartType}
          doowii={doowii}
          loadingText={loadingText}
          didCreateChart={(chartProps) => {
            setChartType(chartProps.chartType);
            setSelectedColumns(chartProps.selectedColumns);
          }}
        />
      </div>

      <FullscreenModal open={fullScreenOpen} setOpen={setFullScreenOpen} result={results} chartType={chartType} setChartType={setChartType} />
    </>
  );
};
