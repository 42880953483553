import * as Sentry from "@sentry/react";
import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import {
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  increment,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";

import { v4 as uuidv4 } from "uuid";
import { fetchTitleFromServer, getNewChartConfig } from "../webserver/openai";

import { t } from "@lingui/macro";
import toast from "react-hot-toast";
import { CANCELED, DOOWII_FEEDBACK } from "../../Constants/constants";
import { withSentry } from "../../helpers/wrapper";
import { db } from "./connection";

function getCollectionName() {
  return window.location.pathname.startsWith("/canvas-ui") ? "canvas_users" : "users";
}
/**
 * Creates a new document in the invitations collection.
 * @param {*} email The email of the invited user.
 */
export const createInvitation = withSentry(async function createInvitation(orgId, email) {
  try {
    const invitationRef = collection(db, "invitations");
    const docRef = await addDoc(invitationRef, {
      email: email,
      timeAdded: new Date(Date.now()).toLocaleDateString(),
      status: "pending",
      organization: orgId,
    });

    return docRef.id;
  } catch (e) {
    console.error("Error adding invitation: ", e);
    throw e;
  }
});

export const signInWithFirebase = withSentry(async function signInWithFirebase(email, password) {
  const auth = getAuth();
  const userCredential = await signInWithEmailAndPassword(auth, email, password);

  return userCredential;
});

export const updateLastSignIn = withSentry(async function updateLastSignIn(orgId, userId) {
  const userRef = doc(db, "organizations", orgId, "users", userId);
  try {
    await updateDoc(userRef, {
      LSO: new Date(Date.now()).toLocaleDateString(),
    });
  } catch (e) {
    console.error(e);
    throw e;
  }
});

export const signUpWithFirebase = withSentry(async function signUpWithFirebase(email, password) {
  const invitationsCollectionRef = collection(db, "invitations");
  const emailQuery = query(invitationsCollectionRef, where("email", "==", email));
  const emailSnapshot = await getDocs(emailQuery);

  if (emailSnapshot.empty) {
    Sentry.withScope((scope) => {
      scope.setTag("log-tag", "InvalidUserSignUp");
      scope.setTag("user_id", email);
      Sentry.captureMessage(`Unauthorised user tried to sign up, Email: ${email}`);
    });

    throw new Error(
      "No invitation found for this email. If you've already signed up, please sign in."
    );
  }
  const auth = getAuth();
  const userCredential = await createUserWithEmailAndPassword(auth, email, password);

  sendEmailVerification(auth.currentUser).then(() => {
    toast.success(t`Verification email sent. Please check your inbox and verify your email.`);
  });
  return userCredential;
});

export const sendPwdResetEmailWithFirebase = withSentry(
  async function sendPwdResetEmailWithFirebase(email) {
    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      console.error("Error while sending password reset email: ", error);
      throw error;
    }
  }
);

export const signOutFromFirebase = withSentry(async function signOutFromFirebase() {
  try {
    const auth = getAuth();
    return await signOut(auth);
  } catch (e) {
    throw e;
  }
});

/**
 * Registration function for firebase.
 * Creates a new document in the users collection with the user's information.
 * Creates a sub collection called chatHistory after the user document is created.
 * @param {*} firstName
 * @param {*} lastName
 * @param {*} dob
 * @param {*} district
 * @param {*} role
 * @param {*} user
 */
export const createNewDocumentInFirebase = withSentry(
  async function createNewDocumentInFirebase(firstName, lastName, role, user) {
    try {
      const invitationsCollectionRef = collection(db, "invitations");
      const emailQuery = query(invitationsCollectionRef, where("email", "==", user.email));
      const emailSnapshot = await getDocs(emailQuery);

      let organizationId;
      if (emailSnapshot.docs.length > 0) {
        organizationId = emailSnapshot.docs[0].data().organization;
      }
      await setDoc(doc(db, "organizations", organizationId, "users", user.uid), {
        firstName: firstName,
        lastName: lastName,
        email: user.email,
        organization: organizationId,
        role: role,
        pinboards: [],
        id: user.uid,
        registration: {
          status: "complete",
          date: new Date(Date.now()).toLocaleDateString(),
        },
        LSO: new Date(Date.now()).toLocaleDateString(),
      });

      await setDoc(doc(db, "user_orgs", user.uid), {
        id: user.uid,
        organization: organizationId,
      });

      if (!emailSnapshot.empty) {
        await deleteDoc(doc(db, "invitations", emailSnapshot.docs[0].id));
      }
    } catch (e) {
      throw e;
    }
  }
);

const threadExists = (threads, threadId) => {
  return threads.some((thread) => thread.id === threadId);
};

/**
 * Adds a chat record to the chat history of a given thread within an organization.
 * If the thread does not exist, it creates a new thread document with an initial title and user details.
 * Every time a new chat is added, a `chatCount` field in the thread document is incremented to keep track
 * of the number of associated chats.
 *
 * @param {Array} threads - An array of threads for verification.
 * @param {string} currentThread - The ID of the thread to which the chat needs to be added.
 * @param {string} organization - The ID of the organization under which the thread and chat exist.
 * @param {string} userId - The ID of the user initiating the chat.
 * @param {string} query - The user's query for the chat.
 * @param {string} answer - The system's answer to the user's query.
 * @param {Object} result - Additional result or data related to the chat.
 *
 * @returns {string} - Returns the document ID of the added chat record.
 *
 * @throws {Error} - Throws an error if there's an issue adding the thread or chat record to Firebase.
 */
export const addToChatHistory = withSentry(async function addToChatHistory(
  threads,
  currentThread,
  organization,
  userId,
  query,
  result,
  answer = ""
) {
  let threadDocRef;
  let count;
  if (!threadExists(threads, currentThread)) {
    count = 0;
    try {
      const threadsCollectionRef = collection(db, "organizations", organization, "threads");

      const newThreadDoc = {
        title: t`New Chat`,
        created_by: userId,
        created_at: new Date().toISOString(),
        id: currentThread,
        chatCount: 0,
      };
      threadDocRef = doc(threadsCollectionRef, currentThread);
      await setDoc(threadDocRef, newThreadDoc);
    } catch (e) {
      console.error("Error adding thread: ", e);
      throw e;
    }
  } else {
    count = threads.find((thread) => thread.id === currentThread).chatCount;
    const threadsCollectionRef = collection(db, "organizations", organization, "threads");
    threadDocRef = doc(threadsCollectionRef, currentThread);
  }

  try {
    const chatHistoryCollectionRef = collection(
      db,
      "organizations",
      organization,
      "threads",
      currentThread,
      "chats"
    );
    const chatThreadMappingCollectionRef = collection(
      db,
      "organizations",
      organization,
      "chat_thread_mapping"
    );
    const chatThreadMappingDocRef = doc(chatThreadMappingCollectionRef, result.id);
    const chatDocRef = doc(chatHistoryCollectionRef, result.id);

    const currentDate = new Date();
    const expirationDate = new Date(currentDate);
    expirationDate.setDate(currentDate.getDate() + 60);

    const isDemo = result.sql === "demo_question";

    if (isDemo) {
      await setDoc(chatDocRef, {
        query: query,
        answer: answer,
        sql: result.sql,
        result: JSON.stringify(result),
        error: result.error,
        title: result.title,
        satisfied: result.satisfied,
        visualisation: result.visualisation,
        visualisationArray: [],
        docId: result.id,
        timestamp: new Date(),
        expires_at: expirationDate.toISOString(),
        follow_up_prompts: result?.follow_up_prompts,
      });
    } else if (result.type !== "PREDICTION") {
      await setDoc(
        chatDocRef,
        {
          query: query,
          sql: result.sql,
          error: result.error,
          title: result.title,
          satisfied: result.satisfied,
          visualisation: result.visualisation,
          visualisationArray: [],
          docId: result.id,
          timestamp: new Date(),
          expires_at: expirationDate.toISOString(),
          latency: result.latency,
          follow_up_prompts: result?.follow_up_prompts,
          chart_config: result.chartConfig,
          answer: answer,
        },
        { merge: true }
      );
    }

    await setDoc(chatThreadMappingDocRef, {
      query: query,
      thread_id: currentThread,
      id: result.id,
      created_by: userId,
    });

    // Increment the chat count in the thread document.
    if (count % 5 === 0) {
      await updateTitleInThreadRoutine(organization, currentThread);
    }

    await updateDoc(threadDocRef, {
      chatCount: increment(1),
    });

    if (result.visualisation !== CANCELED) await incrementUsageMetrics(organization);
    return result.id;
  } catch (e) {
    console.error("Error adding chat record: ", e);
    throw e;
  }
});

export const updatedChatHistory = withSentry(async function updatedChatHistory(
  currentThread,
  organization,
  query,
  result,
  answer = ""
) {
  try {
    const chatHistoryCollectionRef = collection(
      db,
      "organizations",
      organization,
      "threads",
      currentThread,
      "chats"
    );

    const chatDocRef = doc(chatHistoryCollectionRef, result.id);
    await setDoc(
      chatDocRef,
      {
        query: query,
        sql: result.sql,
        error: result.error,
        title: result.title,
        satisfied: result.satisfied,
        visualisation: result.visualisation,
        visualisationArray: [],
        docId: result.id,
        latency: result.latency,
        follow_up_prompts: result?.follow_up_prompts,
        chart_config: result.chartConfig,
        answer: answer,
      },
      { merge: true }
    );
    await incrementUsageMetrics(organization);
    return result.id;
  } catch (e) {
    console.error("Error adding chat record: ", e);
    throw e;
  }
});

export const updateTitleInThreadRoutine = withSentry(
  async function updateTitleInThreadRoutine(organization, threadId) {
    const chatHistoryCollectionRef = collection(
      db,
      "organizations",
      organization,
      "threads",
      threadId,
      "chats"
    );

    const chatSnapshots = await getDocs(chatHistoryCollectionRef);
    const queries = chatSnapshots.docs.map((doc) => doc.data().query);

    const newTitle = (await fetchTitleFromServer({ questions: queries })) || t`New Chat`;
    await updateTitleInThread(organization, threadId, newTitle);
  }
);

export const updateTitleInThread = withSentry(
  async function updateTitleInThread(organization, threadId, title) {
    const threadDocRef = doc(db, "organizations", organization, "threads", threadId);
    await updateDoc(threadDocRef, { title: title });
  }
);

/**
 * Updates the title of the appropriate chat record in the Firebase database.
 * @param {*} userId
 * @param {*} result
 * @param {*} docId
 */
// TODO: Delete this function once canvas migration is finished

export const updateTitle = withSentry(async function updateTitle(userId, result, docId) {
  try {
    const chatHistoryCollectionRef = collection(db, getCollectionName(), userId, "chatHistory");

    const updatedData = {
      result: result,
    };

    const docToUpdate = doc(chatHistoryCollectionRef, docId);
    await updateDoc(docToUpdate, updatedData);
  } catch (e) {
    console.error("Error updating chat record: ", e);
    throw e;
  }
});

/**
 * Firebase function to update the boards field in the user document.
 * @param {*} userId
 * @param {*} boards
 */
// TODO: Delete this function once canvas migration is finished
export const updateBoards = withSentry(async function updateBoards(userId, boards) {
  try {
    const userDocRef = doc(db, getCollectionName(), userId);
    await updateDoc(userDocRef, {
      boards: Object.fromEntries(boards),
    });
  } catch (e) {
    console.error("Error updating boards: ", e);
    throw e;
  }
});

/**
 * Disables an account in the application.
 *
 * If the `userToDelete` has an `id` field, this function will disable the user account by updating the 'status' field
 * under 'registration' map to 'disabled' in the 'users' collection of the Firestore. It will then add a new document
 * to the 'deleted' collection, recording the action along with the details of the admin who performed the action and
 * the date of the action.
 *
 * If the `userToDelete` does not have an `id` field, this function assumes that the user is an invited user
 * who hasn't yet created their account. The function will delete the corresponding invitation document from
 * the 'invitations' collection in the Firestore.
 *
 * @param {Object} userToDelete - The user that is to be deleted/disabled. This object should contain fields 'id'
 *                                 (only for existing users) and 'email'.
 * @param {Object} admin - The admin performing the action. This object should contain the 'email' field.
 * @throws {Error} If an error occurs in Firestore operations.
 */
export const disableAccount = withSentry(async function disableAccount(userToDelete, admin) {
  if (userToDelete.id) {
    // If id field is present, update the 'users' collection
    const userRef = doc(db, "organizations", userToDelete.organization, "users", userToDelete.id);

    // Update status to 'disabled'
    await setDoc(userRef, { registration: { status: "disabled" } }, { merge: true });

    // Add document to the 'deleted' collection
    const deletedCollectionRef = collection(db, "deleted");
    await addDoc(deletedCollectionRef, {
      email: userToDelete.email,
      disabledBy: admin.email,
      id: userToDelete.id,
      day: new Date().toISOString(),
      organization: userToDelete.organization,
    });
  } else {
    // If id field is not present, delete from the 'invitations' collection
    const invitationsCollectionRef = collection(db, "invitations");
    const emailQuery = query(invitationsCollectionRef, where("email", "==", userToDelete.email));
    const emailSnapshot = await getDocs(emailQuery);

    // If document found, delete it
    if (!emailSnapshot.empty) {
      const invitationDocRef = doc(invitationsCollectionRef, emailSnapshot.docs[0].id);
      await deleteDoc(invitationDocRef);
    }
  }
});

export const updateValidationStatus = withSentry(
  async function updateValidationStatus(docId, newStatus) {
    const docRef = doc(db, "organization_questions", docId);

    await setDoc(docRef, { validation_status: newStatus }, { merge: true });
  }
);

/**
 * Creates a new document in the sources collection.
 * @param {*} organization_id The id of the organization.
 * @param {*} source The link to the Google Sheet.
 * @param {*} source_type The type of the source.
 * @param {*} user_id The id of the user.
 */
export const createSource = withSentry(
  async function createSource(handling, organization_id, name, source, source_type, user_id) {
    try {
      // Reference to the sources collection
      const sourcesRef = collection(db, "organizations", organization_id, "sources");

      if (handling === "demo_sources") {
        const demoQuery = query(
          sourcesRef,
          where("handling", "==", "demo_sources"),
          where("user_id", "==", user_id)
        );
        const demoSnapshot = await getDocs(demoQuery);
        if (!demoSnapshot.empty) {
          throw new Error("You already have a demo source!");
        }
      }
      // Query firestore to check if source already exists
      const srcQuery = query(sourcesRef, where("source", "==", source));

      const srcSnapshot = await getDocs(srcQuery);

      if (!srcSnapshot.empty) {
        throw new Error("Source already exists!");
      }

      // Get current date and time as Timestamp
      const now = Timestamp.now();

      // Create a new document
      const docRef = await addDoc(sourcesRef, {
        created_at: now,
        organization_id: organization_id,
        source: source,
        source_type: source_type,
        sync_status: "UPLOADED",
        synced_at: now,
        user_id: user_id,
        handling: handling,
        name: name,
      });

      return docRef.id;
    } catch (e) {
      console.error("Error adding source: ", e);
      throw e;
    }
  }
);

/**
 * Fetches a document from the invitations collection by organization ID.
 * @param {*} orgId The ID of the organization.
 * @returns {Promise<DocumentSnapshot>} A Promise that resolves with the DocumentSnapshot if it exists.
 */
export const fetchInvitationByOrgId = withSentry(async function fetchInvitationByOrgId(orgId) {
  try {
    const docRef = doc(db, "organizations", orgId);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      return docSnapshot.data();
    } else {
      return null;
    }
  } catch (e) {
    console.error("Error fetching organization: ", e);
    throw e;
  }
});

/**
 * Asynchronously sign in a user with Google OAuth.
 *
 * This function attempts to authenticate a user using Google's OAuth service.
 * If the authentication is successful, it checks if the user's ID is in the users collection,
 * or if the user's email is in the invitations collection in the Firestore database.
 *
 * If the user's ID is not in the users collection and the user's email is not in the invitations
 * collection, the function signs out the user and throws an error.
 *
 * @async
 * @returns {Promise} - A promise that resolves with the user's auth result if the sign-in
 * operation was successful and the user is either in the users collection or the invitations
 * collection. If the user is not in either collection, the promise is rejected with an error.
 *
 * @throws {Error} - Throws an error if the sign-in operation was not successful or if the user
 * is not in the users collection or the invitations collection.
 */
export const signInWithGoogle = withSentry(async () => {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  let result;

  try {
    result = await signInWithPopup(auth, provider);
  } catch (error) {
    console.error(error);
    throw error;
  }
  const usersCollection = collection(db, "user_orgs");
  const userDoc = doc(usersCollection, result.user.uid);
  const userDocSnapshot = await getDoc(userDoc);

  const invitationsCollection = collection(db, "invitations");
  const emailQuery = query(invitationsCollection, where("email", "==", result.user.email));
  const emailSnapshot = await getDocs(emailQuery);

  if (!userDocSnapshot.exists() && emailSnapshot.empty) {
    Sentry.withScope((scope) => {
      scope.setTag("log-tag", "InvalidUserSignUp");
      scope.setTag("user_id", result.user.email);
      Sentry.captureMessage(`Unauthorised user tried to sign up, Email: ${result.user.email}`);
    });

    await result.user.delete().catch((error) => {
      console.error("Error while deleting unauthorized user:", error);
      throw new Error("Error deleting unauthorized user.");
    });
    throw new Error("This user does not have an invitation and is not a current user.");
  }

  Sentry.withScope((scope) => {
    scope.setTag("log-tag", "UserSignInGoogle");
    scope.setTag("user_id", result.user.email);
    Sentry.captureMessage(`User Signed In via Google, Email: ${result.user.email}`);
  });

  return result;
});

/**
 * Asynchronously signs in a user with Google OAuth with a scope to allow for google spreadsheet editing on the user's google account
 * @returns OAuth access token associated with Google, allowing Google API calls related to the user's google account
 */
export const signInWithGoogleGetToken = withSentry(async (providerScope) => {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  provider.addScope("https://www.googleapis.com/auth/spreadsheets");
  return signInWithPopup(auth, provider)
    .then((result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      return token;
    })
    .catch((error) => {
      console.error("Error getting OAuth token", error);
      return error;
    });
});

/**
 * Fetches chat history for a specific user from Firebase.
 *
 * This function fetches the chat history for a user with a specific ID from the "chatHistory" collection in Firebase.
 * The chat history is then sorted in ascending order based on the timestamp. The function will construct an
 * object that contains the chat history, segmented into arrays of answers, queries, results, and chat history IDs.
 *
 * Note: The result is parsed as JSON.
 *
 * @async
 * @function
 * @param {Object} authUser - An object representing the authenticated user. It should at least contain the `uid` property.
 * @returns {Promise<Object>} A Promise that resolves to an object containing the user's chat history.
 * The object has this format:
 *   - answer: An array of answers in the chat history.
 *   - query: An array of queries in the chat history.
 *   - result: An array of results in the chat history.
 *   - chatHistoryIds: An array of IDs from the chat history.
 *
 * @throws Will throw an error if the fetch operation fails.
 */
export const fetchChatHistoryManual = withSentry(async function fetchChatHistoryManual(authUser) {
  try {
    const userChatHistoryRef = collection(db, getCollectionName(), authUser?.uid, "chatHistory");
    const chatHistoryQuery = query(userChatHistoryRef, orderBy("timestamp"));
    const chatHistorySnapshot = await getDocs(chatHistoryQuery);

    const updatedChatHistory = {
      answer: [],
      query: [],
      result: [],
      chatHistoryIds: [],
    };

    chatHistorySnapshot.forEach((doc) => {
      const data = doc.data();
      updatedChatHistory.answer.push(data.answer);
      updatedChatHistory.query.push(data.query);
      updatedChatHistory.result.push(JSON.parse(data.result));
      updatedChatHistory.chatHistoryIds.push(data.docId);
    });

    return updatedChatHistory;
  } catch (e) {
    throw e;
  }
});

/**
 * Fetches the timestamp field from a specific document in the chatHistory subcollection
 * of the user's document in Firebase Firestore.
 *
 * @param {Object} authUser - The authenticated user object containing identification data.
 * @param {string} docId - The document ID for which the timestamp is to be fetched.
 *
 * @returns {Object|null} - Returns the timestamp object if the document exists, otherwise returns null.
 *
 * @throws {Error} - Throws an error if unable to fetch the timestamp.
 */
export const fetchChatHistoryTimestamp = withSentry(
  async function fetchChatHistoryTimestamp(authUser, docId) {
    try {
      const specificChatHistoryDocRef = doc(
        collection(db, getCollectionName(), authUser?.uid, "chatHistory"),
        docId
      );

      const specificChatHistoryDoc = await getDoc(specificChatHistoryDocRef);

      if (specificChatHistoryDoc.exists()) {
        return specificChatHistoryDoc.data().timestamp;
      } else {
        return null;
      }
    } catch (e) {
      throw e;
    }
  }
);

export const fetchUserChatHistory = withSentry(async function fetchUserChatHistory(userId) {
  try {
    const userChatHistoryRef = collection(db, getCollectionName(), userId, "chatHistory");
    const chatHistoryQuery = query(userChatHistoryRef, orderBy("timestamp", "desc"));
    const chatHistorySnapshot = await getDocs(chatHistoryQuery);

    const updatedChatHistory = [];

    chatHistorySnapshot.forEach((doc) => {
      const data = doc.data();
      data.result = JSON.parse(data.result);
      if (data.result.data?.rows) {
        data.result.data.rows = data.result?.data?.rows?.slice(0, 10);
      }
      updatedChatHistory.push({
        docId: data.docId,
        query: data.query,
        answer: data.answer,
        result: data.result,
        timestamp: new Date(
          data.timestamp.seconds * 1000 + data.timestamp.nanoseconds / 1000000
        ).toLocaleString(),
      });
    });

    return updatedChatHistory;
  } catch (e) {
    throw e;
  }
});

export const fetchUserList = withSentry(async function fetchUserList(organization_id) {
  try {
    const usersCollection = collection(db, "organizations", organization_id, "users");
    const usersRef = await getDocs(usersCollection);

    const userList = [];
    usersRef.forEach((doc) => {
      const user_id = doc.data().id ? doc.data().id : uuidv4();
      userList.push({
        id: user_id,
        label: doc.data().email ? doc.data().email : user_id,
      });
    });
    return userList;
  } catch (e) {
    throw e;
  }
});

export const fetchGlobalUserIds = withSentry(async function fetchGlobalUserIds() {
  try {
    const globalUsersCollection = collection(db, "authorized_global_users");
    const globalUsersRef = await getDocs(globalUsersCollection);

    const userIdList = [];
    globalUsersRef.forEach((doc) => {
      const user_id = doc.data().user_id;
      if (user_id) {
        userIdList.push(user_id);
      }
    });
    return userIdList;
  } catch (e) {
    throw e;
  }
});

export const updateFeedback = withSentry(
  async (like, feedback, chat, org, type, answerable = "") => {
    try {
      const chatHistoryCollectionRef = collection(
        db,
        "organizations",
        org,
        "threads",
        chat.thread_id,
        "chats"
      );
      const newFeedback =
        type === DOOWII_FEEDBACK
          ? { like: like, feedback: feedback, answerable: answerable }
          : { like: like, feedback: feedback };
      const docToUpdate = doc(chatHistoryCollectionRef, chat.id);
      const updatedData = {
        [type]: newFeedback,
      };
      await updateDoc(docToUpdate, updatedData);
    } catch (e) {
      console.error("Error updating chat record: ", e);
      throw e;
    }
  }
);

export const fetchUserEmail = withSentry(async function fetchUserEmail(selectedOrgId, threadId) {
  try {
    const threadRef = doc(db, "organizations", selectedOrgId, "threads", threadId);
    const threadSnapshot = await getDoc(threadRef);
    if (threadSnapshot) {
      if (!threadSnapshot.data() || !threadSnapshot.data().created_by) {
        // eslint-disable-next-line lingui/no-unlocalized-strings
        return "No creator information";
      }

      const userId = threadSnapshot.data().created_by;

      const userRef = doc(db, "organizations", selectedOrgId, "users", userId);
      const userSnapshot = await getDoc(userRef);

      if (userSnapshot) {
        return {
          user_email: userSnapshot.data().email,
          user_id: userSnapshot.data().id,
        };
      }
    }
  } catch (e) {
    console.error("Error fetching user email: ", e);
    throw e;
  }
});

export const fetchChatInfo = withSentry(
  async function fetchChatInfo(selectedOrgId, threadId, chatId) {
    try {
      const chatRef = doc(db, "organizations", selectedOrgId, "threads", threadId, "chats", chatId);
      const docSnap = await getDoc(chatRef);

      if (docSnap.exists()) {
        const chatData = docSnap.data();
        let chatTimestamp = null;

        if (chatData.timestamp) {
          chatTimestamp =
            chatData.timestamp.seconds * 1000 + chatData.timestamp.nanoseconds / 1000000;
        }

        const feedback =
          chatData.satisfied && chatData.satisfied !== "UNKNOWN" ? chatData.satisfied : null;

        const chatLatency = chatData.latency ?? null;
        const chatCanvasFeedback = chatData.canvas_feedback ?? null;
        const chatDoowiiFeedback = chatData.doowii_feedback ?? null;

        return {
          chatTime: chatTimestamp,
          feedback,
          chatLatency,
          chatCanvasFeedback,
          chatDoowiiFeedback,
        };
      } else {
        return {
          chatTime: null,
          feedback: null,
          chatLatency: null,
          chatCanvasFeedback: null,
          chatDoowiiFeedback: null,
        };
      }
    } catch (e) {
      throw e;
    }
  }
);

export const fetchEvaluationInfo = withSentry(async function fetchEvaluationInfo(orgId, chatId) {
  try {
    const evalRef = doc(db, "organizations", orgId, "sequalizer_evaluator", chatId);
    const docSnap = await getDoc(evalRef);
    if (docSnap.exists()) {
      const evalData = docSnap.data();
      let evalGrade = evalData.eval_grade ?? null;
      let fetchedLatency = evalData.latency ?? null;
      let groupId = evalData.group_id ?? null;

      return {
        evalGrade,
        fetchedLatency,
        groupId,
      };
    } else {
      return { evalGrade: null, fetchedLatency: null, groupId: null };
    }
  } catch (e) {
    throw e;
  }
});

export const fetchChatData = withSentry(
  async function fetchChatData(selectedOrgId, threadId, chatId) {
    try {
      const chatRef = doc(db, "organizations", selectedOrgId, "threads", threadId, "chats", chatId);
      const chatData = await getDoc(chatRef).then((doc) => doc.data());
      return chatData;
    } catch (e) {
      throw e;
    }
  }
);

export const get_dataset_id = withSentry(async function get_dataset_id(org_id) {
  try {
    const orgRef = doc(db, "organizations", org_id);
    const orgData = await getDoc(orgRef).then((doc) => doc.data());
    return orgData.dataset ? orgData.dataset : org_id;
  } catch (e) {
    throw e;
  }
});

export const getSourceType = withSentry(async function getSourceType(org_id) {
  try {
    const sourcesCollectionRef = collection(db, "organizations", org_id, "sources");
    const querySnapshot = await getDocs(sourcesCollectionRef);

    if (querySnapshot.empty) {
      console.error("No sources found for the organization");
      return ["GOOGLE_SHEETS"];
    }
    const sourceTypes = querySnapshot.docs.map((doc) => {
      return doc.data().source_type;
    });
    return sourceTypes;
  } catch (error) {
    console.error("No sources found for the organization");
    throw error;
  }
});

/**
 * Increments the chargeable actions count and updates the monthly usage for an organization.
 *
 * @param {string} organizationId The ID of the organization.
 */
export const incrementUsageMetrics = withSentry(
  async function incrementUsageMetrics(organizationId) {
    const now = new Date();
    const monthYear = `${now.getUTCFullYear()}-${String(now.getUTCMonth() + 1).padStart(2, "0")}`;
    const orgDocRef = doc(db, "organizations", organizationId);
    const orgDoc = await getDoc(orgDocRef);
    if (!orgDoc.exists()) {
      console.error("Organization document does not exist.");
      return;
    }

    const orgData = orgDoc.data();
    const currentMonthlyUsage = orgData.monthly_usage || {};
    const newMonthlyCount = (currentMonthlyUsage[monthYear] || 0) + 1;

    const monthlyUsageUpdate = `monthly_usage.${monthYear}`;

    await updateDoc(orgDocRef, {
      chargeable_actions: increment(1),
      [monthlyUsageUpdate]: newMonthlyCount,
    });
  }
);

/**
 * Calls an API with the provided query and SQL, then updates the chart_config field in Firestore.
 *
 * @param {string} orgId - The organization ID.
 * @param {string} threadId - The thread ID.
 * @param {string} chatId - The chat ID.
 * @param {string} userQuery - The user's query.
 * @param {string} sql - The SQL string to be executed.
 * @param {string} apiUrl - The URL of the API to be called.
 */
export const fetchAndUpdateChartConfig = withSentry(
  async function fetchAndUpdateChartConfig(orgId, threadId, chatId, userQuery, sql) {
    try {
      const chatRef = doc(db, "organizations", orgId, "threads", threadId, "chats", chatId);
      const chartConfig = await getNewChartConfig(sql, userQuery);

      if (chartConfig.columns.length === 1) {
        chartConfig.suggestion = "TABLE";
      }
      await updateDoc(chatRef, {
        chart_config: chartConfig,
      });
      return chartConfig;
    } catch (error) {
      console.error("Failed to update chart config:", error);
      throw error;
    }
  }
);

export async function fetchDiagramUrl(orgId) {
  const docRef = doc(db, "organizations", orgId);
  let defaultUrl = "";

  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists() && docSnap.data().diagram_url) {
      return docSnap.data().diagram_url;
    }
  } catch (error) {
    console.error("Error fetching document:", error);
  }
  return defaultUrl;
}
