import { Text, TextInput, Tooltip } from "@instructure/ui";
import React, { ChangeEvent, useCallback } from "react";

interface ChartTitleProps {
  results: any;
  value: string;
  readOnly: boolean;
  setValue: (value: string) => void;
}

export const ChartTitle = ({ results, value, readOnly, setValue }: ChartTitleProps) => {
  const handleChange = useCallback((e: ChangeEvent, value: string) => {
    setValue(value);
  }, []);

  React.useEffect(() => {
    setValue(results?.title);
  }, [results]);

  if (!readOnly)
    return (
      <div className="my-2">
        <TextInput renderLabel={""} value={value} onChange={handleChange} />
      </div>
    );

  if (value.length < 30) return <Text>{value}</Text>;

  return (
    <Tooltip renderTip={value} placement="top">
      <div style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
        <Text>{value}</Text>
      </div>
    </Tooltip>
  );
};
