import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { solarizedlight } from "react-syntax-highlighter/dist/cjs/styles/prism";
import { format } from "sql-formatter";

export const formatSQL = (sql) => {
  const customStyle = {
    ...solarizedlight,
    hljs: {
      ...solarizedlight.hljs,
      background: "white",
      textAlign: "start",
    },
  };

  function isSQLFormatted(sql) {
    return sql.includes("\n");
  }

  sql = sql.replace(new RegExp("doowii-staging-376207\\.[^\\.]+\\.", "g"), "");
  sql = sql.replace(new RegExp("doowii-prod\\.[^\\.]+\\.", "g"), "");

  let formattedSql;
  try {
    formattedSql = isSQLFormatted(sql) ? sql : format(sql);
  } catch (error) {
    console.error("Error formatting SQL: ", error.message);
    formattedSql = sql;
  }

  return (
    <SyntaxHighlighter language="sql" wrapLongLines style={customStyle}>
      {formattedSql}
    </SyntaxHighlighter>
  );
};
