import { Alert } from "@instructure/ui";
import { FC, ReactNode, createContext, useContext, useState } from "react";
import toast from "react-hot-toast";

import { ALERT_TIMEOUT } from "../../Constants/constants";
import { useAuth } from "../auth";
import { AlertContextI, AlertState } from "./alert.i";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";

const initialState: AlertContextI = {
  alert: null,
  showAlert: (alert: AlertState) => {},
  closeAlert: () => {},
};

const AlertContext = createContext(initialState);

export const AlertProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { _ } = useLingui();
  const [alert, setAlert] = useState<AlertState | null>(null);
  const { isCanvasAccount } = useAuth();

  const showAlert = (alert: AlertState) => setAlert(alert);
  const closeAlert = () => setAlert(null);

  const renderToast = () => {
    alert.variant === "success" ? toast.success(alert.message) : toast.error(alert.message);
    closeAlert();
  };

  return (
    <>
      {alert &&
        (isCanvasAccount ? (
          <div
            style={{
              position: "fixed",
              zIndex: 9999999999,
              top: "16px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "90%",
              maxWidth: 400,
            }}
          >
            <Alert
              variant={alert.variant}
              renderCloseButtonLabel={_(msg`Close`)}
              onDismiss={closeAlert}
              margin="small 0"
              transition="fade"
              timeout={ALERT_TIMEOUT}
            >
              {alert.message}
            </Alert>
          </div>
        ) : (
          renderToast()
        ))}

      <AlertContext.Provider value={{ alert, showAlert, closeAlert }}>
        {children}
      </AlertContext.Provider>
    </>
  );
};

export const useAlert = () => useContext(AlertContext);
