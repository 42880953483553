import {
  IconAnalyticsLine,
  IconEmptyLine,
  IconPredictiveLine,
  IconTableTopHeaderLine,
  IconTargetLine,
  IconBlueprintLine,
} from "@instructure/ui-icons";

import {
  BAR_CHART,
  LINE_CHART,
  PIE_CHART,
  SCATTER_CHART,
  TABLE,
  SCHEMA,
} from "../../../Constants/constants";
import { ChartType } from "../../types";

export const renderChartIcon = (type: ChartType, chartType: string) => {
  switch (type) {
    case TABLE:
      return <IconTableTopHeaderLine color={chartType === TABLE ? "brand" : "primary"} />;
    case BAR_CHART:
      return <IconAnalyticsLine color={chartType === BAR_CHART ? "brand" : "primary"} />;
    case PIE_CHART:
      return <IconEmptyLine color={chartType === PIE_CHART ? "brand" : "primary"} />;
    case LINE_CHART:
      return <IconTargetLine color={chartType === LINE_CHART ? "brand" : "primary"} />;
    case SCATTER_CHART:
      return <IconPredictiveLine color={chartType === LINE_CHART ? "brand" : "primary"} />;
    case SCHEMA:
      return <IconBlueprintLine color={chartType === SCHEMA ? "brand" : "primary"} />;
  }
};
