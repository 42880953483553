import clsx from "clsx";

import { looksLikeDate } from "../../../helpers";
import { DataTableHeader } from "./DataTableHeader";

export const determineColumnType = (columnName, rows) => {
  for (const row of rows) {
    const value = row[columnName];
    if (value !== null && value !== "" && value !== undefined) {
      if (!isNaN(value) && !isNaN(parseFloat(value))) return "number";
      if (looksLikeDate(value)) return "timestamp";
      return "string";
    }
  }
  return "string";
};

export const determineColumns = (
  result,
  pageInfo,
  selectedColumns,
  handleColumnHeaderClick,
  baseURL
) => {
  return (
    pageInfo.headers?.map((header, index, array) => {
      const isLastColumn = index === array.length - 1;

      const isCourseHyperLink = pageInfo.hyperlinkColumns?.courses?.includes(header);
      const isGradeBookHyperLink = pageInfo.hyperlinkColumns?.gradebook?.includes(header);
      const firstCourseColumn = pageInfo.hyperlinkColumns?.courses?.[0];
      return {
        field: header,
        headerName: header,
        headerClassName: header in selectedColumns ? "column-header-selected" : "column-header",
        cellClassName: (params) => {
          return clsx("row", { selected: Object.keys(selectedColumns).includes(params.field) });
        },
        renderHeader: (params) => (
          <DataTableHeader column={params.colDef} handleColumnSelect={handleColumnHeaderClick} />
        ),
        renderCell: (params) => {
          if (isCourseHyperLink) {
            return (
              <span aria-label={`Column: ${header}`}>
                <a
                  href={`${baseURL}/courses/${params.value}`}
                  target="_blank"
                  style={{ color: "blue", textDecoration: "underline" }}
                  rel="noreferrer"
                  tabIndex={-1}
                >
                  {params.value}
                </a>
              </span>
            );
          } else if (isGradeBookHyperLink) {
            const courseValue = params.row[firstCourseColumn] || "default";
            return (
              <span aria-label={`Column: ${header}`}>
                <a
                  href={`${baseURL}/courses/${courseValue}/gradebook`}
                  target="_blank"
                  style={{ color: "blue", textDecoration: "underline" }}
                  rel="noreferrer"
                  tabIndex={-1}
                >
                  {params.value}
                </a>
              </span>
            );
          } else {
            return (
              <span aria-label={`Column: ${header} - Value: ${params.value}`}>{params.value}</span>
            );
          }
        },
        width: isLastColumn ? undefined : 150,
        flex: isLastColumn ? 1 : undefined,
        minWidth: isLastColumn ? 300 : 100,
      };
    }) || []
  );
};

export const handleColumnResize = (params, columnWidthsRef) => {
  const field = params?.colDef?.headerName;
  const width = params?.width;

  columnWidthsRef.current[field] = width;
};

export const handleColumnOrderChange = (params, columnOrderRef) => {
  columnOrderRef.current.push({
    field: params?.column?.field,
    targetIndex: params?.targetIndex,
    oldIndex: params?.oldIndex,
  });
};

export const applyColumnWidthsFromRef = (columns, columnWidthsRef) => {
  return columns.map((col) => {
    const storedWidth = columnWidthsRef.current[col.field];
    if (storedWidth) {
      return { ...col, width: storedWidth };
    }
    return col;
  });
};

function moveElementInArray(array, fromIndex, toIndex) {
  const element = array.splice(fromIndex, 1)[0];
  array.splice(toIndex, 0, element);
}

export const applyColumnOrderFromRef = (columns, columnOrderRef) => {
  columnOrderRef.current.forEach((change) => {
    const actualOldIndex = columns.findIndex((col) => col.field === change.field);
    if (actualOldIndex === -1) {
      return;
    }

    if (actualOldIndex !== change.targetIndex) {
      moveElementInArray(columns, actualOldIndex, change.targetIndex);
    }
  });
  return columns;
};

export const getEstimateReadingTime = (text: string) => {
  const wordsPerMinute = 120;
  const words = text.split(" ").length;
  return (words / wordsPerMinute) * 60000;
};
