import { MutableRefObject } from "react";

import { Doowii } from "../../../search/Doowii";
import { DataTable } from "./DataTable";
import { useResize } from "../../../hooks/useResize";
import { ChartType } from "../../types";

interface ChartContentProps {
  chartType: ChartType;
  result: any;
  isMini?: boolean;
  setTotalRows: any;
  selectedColumns?: any;
  setSelectedColumns?: any;
  width?: number;
  height?: number;
  loadingText?: string;
  doowii?: MutableRefObject<Doowii | null>;
}

export const DataTableWrapper = ({
  chartType,
  result,
  isMini = false,
  setTotalRows,
  loadingText = "",
  doowii,
}: ChartContentProps) => {

  const { componentRef, width, height } = useResize();

  return (
    <div className="flex flex-grow flex-col" ref={componentRef}>
      {height > 0 && width > 0 && (
      <DataTable
        result={result}
        setTotalRows={setTotalRows}
        isMini={isMini}
        width={width}
        height={height}
        loadingText={loadingText}
        chartType={chartType}
        doowii={doowii}
        />
      )}
    </div>
  );
};
