import { CHART_LIMIT } from "../Constants/constants";
import { DataMap, Pin } from "../context/pinboard/pinboard.i";

export class Chart {
  //TO be removed
  static buildFromPin(pin: Pin, dataMap: DataMap, totalRows: number) {
    const rows = dataMap[pin?.id]?.rows;
    const keys = Array.from({ length: Math.min(totalRows, CHART_LIMIT) }, (_, index) => index);

    if (!rows) {
      return [];
    }

    return this.processRows(
      keys
        .map((idx) => ({ id: idx, ...rows[idx] }))
        .filter((row) => {
          const { id, ...rest } = row;
          return Object.values(rest).some((value) => value !== undefined);
        })
    );
  }

  static roundNumericValues(obj: any) {
    for (const key in obj) {
      if (typeof obj[key] === "number") {
        obj[key] = Math.round(obj[key] * 100) / 100;
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        this.roundNumericValues(obj[key]);
      }
    }
    return obj;
  }

  static processRows(rows: any[]) {
    try {
      return rows.map((row) => this.roundNumericValues({ ...row }));
    } catch (error) {
      console.error("Error processing rows, using original data:", error);
      return rows;
    }
  }
}
