import styled from "styled-components";

export const SqlDisplay = styled.div<{ height?: number | string }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  width: 100%;

  ${({ height }) => height && `height: ${typeof height === "string" ? height : `${height}px`};`}
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    color: #013b48;
  }
`;

export const SqlArea = styled.div`
  flex: 1;
  padding: 16px;
  overflow-y: auto;
`;

export const ActionButtons = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;
