import { MutableRefObject, useEffect, useState } from "react";

import { useChart } from "../../../../../context/chart";
import { useChatData } from "../../../../../context/chat";
import { useResize } from "../../../../../hooks/useResize";
import { Doowii } from "../../../../../search/Doowii";
import { Analytics } from "../../../../../services/analytics/Analytics";
import { ChartContent } from "../../../ChartContent/ChartContent";
import { FullscreenModal } from "./FullscreenModal/FullscreenModal";
import { SQLTray } from "./SQLTray/SQLTray";
import { WhiteBoardHeader } from "./WhiteboardHeader/WhiteboardHeader";

interface WhiteboardProps {
  loading: boolean;
  loadingText: string;
  openPinboardModal: boolean;
  setOpenPinboardModal: (open: boolean) => void;
  doowii: MutableRefObject<Doowii | null>;
}

export const Whiteboard = ({
  loading,
  loadingText,
  openPinboardModal,
  setOpenPinboardModal,
  doowii,
}: WhiteboardProps) => {
  const { selectedColumns, setSelectedColumns, charts } = useChart();
  const [chartType, setChartType] = useState(null);
  const { allResults, selectResult, updateTitle, currentResult: results } = useChatData();

  const { componentRef, width, height } = useResize();

  const [fullScreenOpen, setFullScreenOpen] = useState(false);
  const [sqlTrayOpen, setSqlTrayOpen] = useState(false);
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    if (fullScreenOpen) {
      Analytics.track("Result full screen");
    }
  }, [fullScreenOpen]);

  const handleNavigation = (direction) => {
    let index = allResults.findIndex((result) => result?.id === results?.id);
    if (direction === "left") {
      // @ts-ignore
      selectResult(allResults[index - 1]);
    } else {
      // @ts-ignore
      selectResult(allResults[index + 1]);
    }
  };

  useEffect(() => {
    setChartType(results?.visualisation);
  }, [results?.id]);

  return (
    <>
      <SQLTray results={results} sqlTrayOpen={sqlTrayOpen} setSqlTrayOpen={setSqlTrayOpen} />

      <div className="flex flex-1 flex-col ">
        <WhiteBoardHeader
          loading={loading}
          results={results}
          allResults={allResults}
          updateTitle={updateTitle}
          handleNavigation={handleNavigation}
          totalRows={totalRows}
          charts={charts}
          chartType={chartType}
          openPinboardModal={openPinboardModal}
          setChartType={setChartType}
          setOpenPinboardModal={setOpenPinboardModal}
          setSqlTrayOpen={setSqlTrayOpen}
          setFullScreenOpen={setFullScreenOpen}
        />

        <div className="relative m-3 flex flex-1">
          <div className="absolute bottom-0 left-0 right-0 top-0 flex h-full flex-1 overflow-y-auto">
            <div ref={componentRef} className="flex max-w-full flex-1 flex-col">
              <ChartContent
                totalRows={totalRows}
                setTotalRows={setTotalRows}
                results={results}
                chartType={chartType}
                height={height}
                width={width - 10} // safe margin for rendering bug
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                loadingText={loadingText}
                doowii={doowii}
              />
            </div>
          </div>
        </div>
      </div>

      <FullscreenModal open={fullScreenOpen} setOpen={setFullScreenOpen} result={results} />
    </>
  );
};
