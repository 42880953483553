import { FC, useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import { View } from "@instructure/ui";
import { useWindowSize } from "@uidotdev/usehooks";
import { PINBOARD_VIEW } from "../../../Constants/constants";
import { useAuth } from "../../../context/auth";
import { useChatData } from "../../../context/chat";
import { useUI } from "../../../context/ui";
import { clickById, focusById, getById } from "../../../helpers/actionById";
import { Doowii } from "../../../search/Doowii";
import { ChatArea } from "./ChatArea/ChatArea";
import { selectNextChat, selectPreviousChat } from "./ChatTab.helper";
import { ResultArea } from "./ResultArea/ResultArea";
import { useLingui } from "@lingui/react";

export const ChatTab: FC = () => {
  const { userDocument, isCanvasAccount, featureFlags } = useAuth();
  const {
    searchHistory,
    allResults,
    currentThread,
    newChat,
    threads,
    setAnswer,
    setSearchHistory,
    setAllResults,
    setCurrentResult: setResults,
    selectThread,
    loading,
    setLoading,
    setStreamLoading,
    answer,
  } = useChatData();
  const { i18n } = useLingui();
  const { setView }: any = useUI();

  const [loadingText, setLoadingText] = useState("");
  const [openPinboardModal, setOpenPinboardModal] = useState(false);

  const [query, setQuery] = useState("");
  const { model } = useUI();

  const chatAreaRef = useRef(null);

  const canMakeShortcutAction = !loading && !openPinboardModal;

  useHotkeys("CTRL+SHIFT+2", () => setView(PINBOARD_VIEW), { enabled: canMakeShortcutAction });
  useHotkeys("CTRL+ALT+1", () => focusById("chat-input"), { enabled: canMakeShortcutAction });
  useHotkeys("CTRL+ALT+2", () => newChat(), { enabled: canMakeShortcutAction });
  useHotkeys("CTRL+ALT+3", () => selectPreviousChat(currentThread, threads, selectThread), {
    enabled: canMakeShortcutAction,
  });
  useHotkeys("CTRL+ALT+4", () => selectNextChat(currentThread, threads, selectThread), {
    enabled: canMakeShortcutAction,
  });
  useHotkeys("CTRL+ALT+5", () => clickById("add-to-pinboard-button"), {
    enabled: canMakeShortcutAction,
  });

  const doowiiRef = useRef(null);

  useEffect(() => {
    if (!doowiiRef.current) {
      doowiiRef.current = new Doowii(
        i18n,
        setSearchHistory,
        setResults,
        setLoading,
        setAllResults,
        setAnswer,
        setLoadingText,
        setStreamLoading,
        userDocument,
        currentThread,
        model,
        allResults,
        isCanvasAccount,
        threads,
        featureFlags
      );
    } else {
      doowiiRef.current.user = userDocument;
      doowiiRef.current.threadId = currentThread;
      doowiiRef.current.model = model;
      doowiiRef.current.allResults = allResults;
      doowiiRef.current.isCanvasAccount = isCanvasAccount;
      doowiiRef.current.threads = threads;
      doowiiRef.current.featureFlags = featureFlags;
      doowiiRef.current.i18n = i18n;
    }
  }, [userDocument, currentThread, model, allResults, isCanvasAccount, threads, featureFlags]);

  useEffect(() => {
    const selected = getById("selected-chat");
    if (selected) {
      setTimeout(() => {
        selected.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 100);
    }
  }, []);

  const [eachContainerHeight, setEachContainerHeight] = useState("auto");

  const { width, height } = useWindowSize();

  useEffect(() => {
    if (width >= 940) {
      setEachContainerHeight("auto");
      return;
    }

    const fullHeight = height - chatAreaRef.current?.getBoundingClientRect().top;
    setEachContainerHeight(`${fullHeight}px`);
  }, [width, height]);

  const { loadingThread } = useChatData();

  return (
    <View height="100%">
      <div className="flex h-full flex-1 flex-col overflow-x-hidden chat-desktop:flex-row chat-desktop:overflow-hidden">
        <div
          style={{ height: eachContainerHeight }}
          className="flex w-full shrink-0 flex-col border-r-0 border-[#c7cdd1] chat-desktop:w-1/2 chat-desktop:border-r lg:w-[41.5%]"
          ref={chatAreaRef}
        >
          <ChatArea
            loading={loading}
            doowii={doowiiRef}
            query={query}
            setQuery={setQuery}
            loadingThread={loadingThread}
          />
        </div>

        <div
          style={{ height: eachContainerHeight }}
          className=" hidden chat-desktop:flex chat-desktop:w-1/2 chat-desktop:shrink-0 chat-desktop:flex-col lg:w-[58.5%]"
        >
          <ResultArea
            loadingThread={loadingThread}
            loadingText={loadingText}
            loading={loading}
            searchHistory={searchHistory}
            openPinboardModal={openPinboardModal}
            setOpenPinboardModal={setOpenPinboardModal}
            doowii={doowiiRef}
          />
        </div>
      </div>
    </View>
  );
};
