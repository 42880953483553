import { useEffect, useState } from "react";

import {
  BAR_CHART,
  LINE_CHART,
  PIE_CHART,
  SAVANT_LINE,
  SCATTER_CHART,
  SQL_CHART,
  TABLE,
} from "../../../Constants/constants";
import { SqlDisplay } from "../../../components/Chart/SqlDisplay/SqlDisplay";
import { BarChart } from "../../../components/Charts/BarChart/BarChart";
import { LineChart } from "../../../components/Charts/LineChart/LineChart";
import { PieChart } from "../../../components/Charts/PieChart/PieChart";
import PredictionLineChart from "../../../components/Charts/PredictionLineChart/PredictionLineChart";
import { ScatterChart } from "../../../components/Charts/ScatterChart/ScatterChart";
import { Chart } from "../../../helpers/Chart";
import { DataTable } from "../DataTable/DataTable";
import { usePinboard } from "../../../context/pinboard";
import { ParentDocTypeEnum } from "../../../api/retriever.i";

interface PinboardChartContentProps {
  results: any;
  width: number;
  height: number;
}

export const PinboardChartContent = ({ results, width, height }: PinboardChartContentProps) => {
  const [chartType, setChartType] = useState(TABLE);
  const [selectedColumns, setSelectedColumns] = useState(results.selectedColumns || {});
  const [pageData, setPageData] = useState<any>([]);
  const [graphData, setGraphData] = useState<any>({});
  const [totalRows, setTotalRows] = useState(0);
  const { currBoardId } = usePinboard();
  const sortModel = JSON.parse(localStorage.getItem(`sortModel-${(results as any)?.id}`) || "[]");
  useEffect(() => {
    if (
      results?.type === "PREDICTION" ||
      results?.visualisation === TABLE ||
      !pageData?.rows?.length
    )
      return;

    const _graphData = Chart.buildFromPin(
      results,
      { [results.id]: { rows: pageData.rows, pageInfo: pageData.pageInfo } },
      pageData.pageInfo?.totalRowCount
    );

    setGraphData(_graphData);
    setChartType(results.visualisation);
  }, [pageData]);

  return (
    <>
      {chartType === SAVANT_LINE && (
        <PredictionLineChart result={results} height={height} width={width} />
      )}
      {chartType === TABLE && (
        <DataTable
          result={results}
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
          setTotalRows={setTotalRows}
          height={height}
          selectable={false}
          setPageData={setPageData}
        />
      )}
      {chartType === PIE_CHART && (
        <PieChart
          data={graphData}
          height={height - 6}
          width={width}
          selectedColumns={selectedColumns}
          docId={results?.id}
          parentDocId={currBoardId}
          parentDocType={ParentDocTypeEnum.PINBOARD}
          totalRows={totalRows}
        />
      )}
      {chartType === BAR_CHART && (
        <BarChart
          data={graphData}
          height={height}
          width={width}
          isDemo={!!results?.data?.isDemo}
          selectedColumns={selectedColumns}
          docId={results?.id}
          parentDocId={currBoardId}
          parentDocType={ParentDocTypeEnum.PINBOARD}
          totalRows={totalRows}
          sortModel={sortModel}
        />
      )}
      {chartType === LINE_CHART && (
        <LineChart
          data={graphData}
          height={height}
          width={width}
          isDemo={!!results?.data?.isDemo}
          selectedColumns={selectedColumns}
          docId={results?.id}
          parentDocId={currBoardId}
          parentDocType={ParentDocTypeEnum.PINBOARD}
          totalRows={totalRows}
          sortModel={sortModel}
        />
      )}
      {chartType === SQL_CHART && (
        <SqlDisplay sql={results?.sql} originalSql={(results as any)?.originalSql} height="300px" />
      )}
      {chartType === SCATTER_CHART && (
        <ScatterChart
          data={graphData}
          height={height}
          width={width}
          isDemo={!!results?.data?.isDemo}
          selectedColumns={selectedColumns}
          docId={results?.id}
          parentDocId={currBoardId}
          parentDocType={ParentDocTypeEnum.PINBOARD}
          totalRows={totalRows}
        />
      )}
    </>
  );
};
