import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RevertIcon from "@mui/icons-material/History";
import SaveIcon from "@mui/icons-material/Save";
import WarningIcon from "@mui/icons-material/Warning";
import { Button, IconButton, Tooltip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { FC, useState } from "react";
import { toast } from "react-hot-toast";

import { retrieverPost } from "../../../api/base";
import { useAuth } from "../../../context";
import { useChatData } from "../../../context/chat";
import { formatSQL } from "../../../helpers/formatMessage";
import { SQLEditor } from "../../SQLEditor/SQLEditor";
import * as s from "./SqlDisplay.s";
import { formatSqlFn } from "./helpers";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

interface SqlDisplayProps {
  sql: string;
  originalSql: string;
  height?: number | string;
  canEdit?: boolean;
}

export const SqlDisplay: FC<SqlDisplayProps> = ({ canEdit = false, sql, originalSql, height }) => {
  const { _ } = useLingui();
  const { userDocument } = useAuth();
  const { updateSqlInFirestore } = useChatData();
  const [editorSQL, setEditorSQL] = useState(formatSqlFn(sql));
  const [performingUpdate, setPerformingUpdate] = useState(false);
  const [performingOriginalUpdate, setPerformingOriginalUpdate] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [validationMessage, setValidationMessage] = useState("");

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(editorSQL);
      toast.success(_(msg`Copied to clipboard`));
    } catch (err) {
      toast.error(_(msg`Failed to copy to clipboard`));
    }
  };

  const updateSQL = async (sql, original) => {
    try {
      original ? setPerformingOriginalUpdate(true) : setPerformingUpdate(true);
      const response = await retrieverPost("retrieve/validate_sql", {
        proposed_sql: sql,
      });
      // @ts-ignore
      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      // @ts-ignore
      const sqlValidation = await response.data;
      setIsValid(sqlValidation.isValid);
      setValidationMessage(sqlValidation.message);

      if (sqlValidation.isValid) {
        await updateSqlInFirestore(sql);
      } else {
        toast.error(`SQL error: ${sqlValidation.message}`);
      }
      original ? setPerformingOriginalUpdate(false) : setPerformingUpdate(false);
    } catch (error) {
      console.error("Error posting data: ", error);
      original ? setPerformingOriginalUpdate(false) : setPerformingUpdate(false);
    }
  };

  const hasPreviousSQL = originalSql && sql !== originalSql;
  const updating = performingUpdate || performingOriginalUpdate;

  return (
    <s.SqlDisplay height={height}>
      <s.Heading>
        <div>
          {canEdit ? (
            <s.ActionButtons>
              <Tooltip title={<Trans>Save SQL</Trans>}>
                <div>
                  <Button
                    size="small"
                    variant="contained"
                    startIcon={performingUpdate ? <CircularProgress size="14px" /> : <SaveIcon />}
                    sx={{ lineHeight: 1.5 }}
                    onClick={() => updateSQL(editorSQL, false)}
                    disabled={
                      updating || editorSQL?.replace(/\s+/g, "") === sql?.replace(/\s+/g, "")
                    }
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Tooltip>
              {hasPreviousSQL && (
                <Tooltip title={<Trans>Revert to original SQL</Trans>}>
                  <div>
                    <Button
                      size="small"
                      variant="outlined"
                      startIcon={
                        performingOriginalUpdate ? <CircularProgress size="14px" /> : <RevertIcon />
                      }
                      sx={{ lineHeight: 1.5 }}
                      onClick={() => updateSQL(originalSql, true)}
                      disabled={updating}
                    >
                      <Trans>Revert</Trans>
                    </Button>
                  </div>
                </Tooltip>
              )}
              {validationMessage && !isValid && (
                <Tooltip title={validationMessage}>
                  <WarningIcon color="error" />
                </Tooltip>
              )}
            </s.ActionButtons>
          ) : (
            <h3>SQL:</h3>
          )}
        </div>
        <div>
          <Tooltip title={<Trans>Copy SQL</Trans>}>
            <IconButton size="medium" onClick={handleCopyClick}>
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </div>
      </s.Heading>
      {canEdit ? (
        <SQLEditor
          editorSQL={editorSQL}
          setEditorSQL={setEditorSQL}
          isValid={isValid}
          setIsValid={setIsValid}
          performingUpdate={updating}
          validationMessage={validationMessage}
          setValidationMessage={setValidationMessage}
        />
      ) : (
        <s.SqlArea>{sql ? formatSQL(sql) : "null"}</s.SqlArea>
      )}
    </s.SqlDisplay>
  );
};
