import { LicenseInfo } from "@mui/x-license-pro";
import * as Sentry from "@sentry/react";
import { Toaster } from "react-hot-toast";
import { RouterProvider } from "react-router-dom";

import { ConditionalAuthProvider } from "./context/conditionalAuth";
import { env } from "./env";
import { router } from "./routes";

import "intro.js/introjs.css";
import { LocaleProvider } from "./context/locale";

LicenseInfo.setLicenseKey(env.REACT_APP_MUI_LICENSE);

const tracePropagationTargets = [
  "localhost",
  "https://doowii-staging-376207.web.app/",
  "https://prod-sequalizer-a3peu64y.uc.gateway.dev/retrieve/chart_bar",
  "https://prod-sequalizer-a3peu64y.uc.gateway.dev/retrieve/chart_scatter",
  "https://prod-sequalizer-a3peu64y.uc.gateway.dev/retrieve/chart_line",
  "https://prod-sequalizer-a3peu64y.uc.gateway.dev/retrieve/chart_pie",
  "https://prod-sequalizer-a3peu64y.uc.gateway.dev/retrieve",
  "https://prod-sequalizer-a3peu64y.uc.gateway.dev/retrieve/sort",
  "https://prod-sequalizer-a3peu64y.uc.gateway.dev/retrieve/download",
  "https://prod-sequalizer-a3peu64y.uc.gateway.dev/process",
  "https://app.doowii.io/get_user_data",
  "https://api.openai.com/v1/chat/completions",
  "https://ltiservice-dot-doowii-prod.wl.r.appspot.com/get_user_data",
];

Sentry.init({
  dsn: "https://1ae17d4537dc3472355b1a97df470987@o4505761205649408.ingest.sentry.io/4505761219018752",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: tracePropagationTargets,
    }),
    new Sentry.Replay({ networkDetailAllowUrls: tracePropagationTargets }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled:
    env.REACT_APP_ENV === "canvas-prod" ||
    env.REACT_APP_ENV === "canvas-prod-uk" ||
    env.REACT_APP_ENV === "canvas-uat" ||
    env.REACT_APP_ENV === "core-prod" ||
    env.REACT_APP_ENV === "staging",
  environment: env.REACT_APP_ENV,
});

export function App() {
  return (
    <LocaleProvider>
      <ConditionalAuthProvider>
        <RouterProvider router={router} />
        <Toaster
          containerStyle={{
            zIndex: 999999,
          }}
        />
      </ConditionalAuthProvider>
    </LocaleProvider>
  );
}

export default App;
