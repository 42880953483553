/* eslint-disable lingui/no-unlocalized-strings */
import { BAR_CHART, LINE_CHART, TABLE } from "../Constants/constants";

export const questions = {
  showtrendofaveragepsatbyschoolforlast5years: {
    data: {
      isDemo: true,
      rows: [
        { time: 2018, school: "Hamilton", score: 950 },
        { time: 2019, school: "Hamilton", score: 970 },
        { time: 2020, school: "Hamilton", score: 960 },
        { time: 2021, school: "Hamilton", score: 975 },
        { time: 2022, school: "Hamilton", score: 980 },

        { time: 2018, school: "Cherry Creek", score: 930 },
        { time: 2019, school: "Cherry Creek", score: 920 },
        { time: 2020, school: "Cherry Creek", score: 930 },
        { time: 2021, school: "Cherry Creek", score: 950 },
        { time: 2022, school: "Cherry Creek", score: 940 },

        { time: 2018, school: "Ballard", score: 920 },
        { time: 2019, school: "Ballard", score: 930 },
        { time: 2020, school: "Ballard", score: 915 },
        { time: 2021, school: "Ballard", score: 920 },
        { time: 2022, school: "Ballard", score: 930 },

        { time: 2018, school: "Wallingford", score: 980 },
        { time: 2019, school: "Wallingford", score: 970 },
        { time: 2020, school: "Wallingford", score: 980 },
        { time: 2021, school: "Wallingford", score: 975 },
        { time: 2022, school: "Wallingford", score: 990 },

        { time: 2018, school: "Fremont Hill", score: 900 },
        { time: 2019, school: "Fremont Hill", score: 910 },
        { time: 2020, school: "Fremont Hill", score: 905 },
        { time: 2021, school: "Fremont Hill", score: 900 },
        { time: 2022, school: "Fremont Hill", score: 910 },

        { time: 2018, school: "West Woodland", score: 920 },
        { time: 2019, school: "West Woodland", score: 930 },
        { time: 2020, school: "West Woodland", score: 925 },
        { time: 2021, school: "West Woodland", score: 935 },
        { time: 2022, school: "West Woodland", score: 930 },

        { time: 2018, school: "Blue Ridge", score: 940 },
        { time: 2019, school: "Blue Ridge", score: 930 },
        { time: 2020, school: "Blue Ridge", score: 940 },
        { time: 2021, school: "Blue Ridge", score: 935 },
        { time: 2022, school: "Blue Ridge", score: 950 },

        { time: 2018, school: "Kenmore Lake", score: 950 },
        { time: 2019, school: "Kenmore Lake", score: 960 },
        { time: 2020, school: "Kenmore Lake", score: 955 },
        { time: 2021, school: "Kenmore Lake", score: 965 },
        { time: 2022, school: "Kenmore Lake", score: 960 },

        { time: 2018, school: "East Renton", score: 960 },
        { time: 2019, school: "East Renton", score: 970 },
        { time: 2020, school: "East Renton", score: 965 },
        { time: 2021, school: "East Renton", score: 975 },
        { time: 2022, school: "East Renton", score: 970 },
      ],
    },
    secondAnswer:
      "The returned results show the average PSAT for sophomores in different schools. The data is grouped by school, and for each school, the name and ID are shown along with the average PSAT of all sophomores in that school. The average grade is calculated by adding up all the scores of sophomores in a school and dividing by the total number of sophomores in that school. This information can be used to compare the academic performance of different schools and identify schools where sophomores are doing particularly well or struggling.",
    id: 0,
    isFavorite: [],
    title: "Show trend of average PSAT by school for last 5 years",
    visualisation: LINE_CHART,
    embedding: [
      -0.021451417, -0.00085550296, 0.015417782, -0.022949612, -0.006585245, -0.0039838348,
      -0.03380471, -0.01569018, -0.035248425, -0.004773792, 0.019285847, 0.014069406, 0.007143663,
      0.028492931, 0.0071981424, 0.026994737, 0.025265004, -0.0054343594, 0.0036092862, 0.008274118,
      -0.016180499, 0.01829159, -0.02376681, 0.0032585727, 0.0056250384, 0.004647807, -0.0030610834,
      -0.026885778, -0.009874462, 0.0008852966, 0.018523129, -0.014682304, -0.027716594,
      -0.01848227, -0.023916628, -0.034676388, -0.008553327, -0.009390954, 0.02343993,
      -0.0014803181, -0.010310301, -0.022609115, -0.0078042303, 0.008628237, -0.015785519,
      -0.022105176, -0.02873809, 0.00032389938, -0.04257596, -0.00017141982, 0.019027067,
      0.0014718056, -0.043556593, -0.02868361, 0.020034943, -0.01305472, 0.0071709026, 0.026054962,
      -0.02917393, -0.03426779, 0.01588086, 0.009084505, -0.024461428, 0.0026167326, -0.00045797075,
      -0.0080425795, -0.024352467, 0.015948959, 0.011563336, 0.018931728, 0.038217574, 0.03677386,
      -0.031189682, -0.0123601025, 0.020389061, 0.015022803, -0.0035922613, 0.019040687,
      0.006258366, 0.009363715, 0.03715522, -0.0026048152, -0.016534617, -0.0043209284, 0.019095168,
      -0.009894893, -0.0017961307, 0.029555287, -0.02658614, 0.0005043637, 0.019408425, 0.000309641,
      0.009854033, 0.017365433, -0.024924506, 0.03377747, -0.020021323, 0.02620478, 0.025292244,
      -0.019721685, 0.022976853, -0.027008357, 0.011188787, -0.014014927, -0.011461186,
      -0.012135373, -0.026463559, 0.007620361, 0.011638246, -0.0060063973, -0.018999828,
      0.028411211, 0.015267962, -0.02873809, -0.013919587, 0.013701668, -0.018441409, 0.016670816,
      -0.030917283, -0.017419914, 0.04143188, 0.014096647, 0.040342286, -0.03451295, 0.029446328,
      0.015240722, -0.015485881, -0.027485056, -0.011107068, 0.005018951, 0.0045797075, 0.015322441,
      -0.006711229, 0.00664994, 0.0053492347, 0.013538228, -0.008165159, -0.000121409066,
      -0.034758106, -0.035575304, 0.012264763, 0.014872983, -0.006040447, -0.020893, -0.012761892,
      0.009418194, 0.017719552, 0.037645537, 0.0007635683, -0.0066805845, 0.016943216, -0.029609768,
      -0.0071504726, 0.022840653, -0.010637179, -0.012210283, 0.0056556836, 0.0112228375,
      -0.020293722, -0.03364127, -0.026735958, 0.025224144, 0.012428203, -0.012612072, 0.0013347549,
      0.029528048, -0.01036478, -0.0031138607, 0.00026133275, -0.024842786, 0.0053185895,
      0.014968323, -0.048541494, 0.009452244, 0.0148185035, 0.02128798, -0.011161547, 0.006241341,
      -0.011427136, -0.019054307, -0.017978331, 0.00015897033, 0.031053482, 0.009642923,
      -0.0048623215, 0.013912777, 0.024134548, 0.013762957, -0.0053594494, -0.0057169735,
      0.033314392, -0.0006277945, 0.006040447, -0.00264227, -0.6328373, -0.03146208, -0.014055787,
      0.007368392, 0.048241857, 0.01052141, 0.032851316, 0.0094045745, -0.044210352, 0.007620361,
      -0.0048418916, 0.0095203435, -0.022500154, -0.011209217, 0.016139638, -0.013667618,
      -0.01799195, -0.012176233, 0.020429922, 0.011760825, -0.0077429404, 0.028029853, -0.019095168,
      0.0012479278, 0.025414823, 0.010943628, 0.003112158, -0.019285847, -0.0071300426, 0.006054067,
      -0.017474394, 0.0053969044, 0.003374342, 0.02363061, 0.0419222, -0.012543973, -0.0046205674,
      0.03881685, 0.025850661, 0.041786, -0.017692313, -0.017365433, -0.010099191, 0.019163268,
      0.0035888564, 0.032197557, -0.01050779, -0.009928942, 0.0095203435, -0.019885125, 0.025101565,
      -0.0045218226, -0.011386276, -0.001428392, 0.008968736, 0.0001455632, -0.0005001075,
      -0.027852794, 0.017093034, 0.0109572485, -0.0026235424, 0.0042936886, 0.015336062,
      -0.01569018, -0.0072185723, 0.016384797, 0.008580567, 0.008703147, 0.019476525, -0.017052175,
      0.01064399, 0.03148932, -0.012421393, 0.027171796, 0.04145912, 0.0008376268, 0.01581276,
      -0.01315006, -0.030590404, 0.024311608, 0.006023422, -0.015962578, -0.017161135,
      -0.00085082115, -0.013000241, 0.011508856, -0.025523784, 0.012537162, -0.009533964,
      0.02105644, 0.006605675, 0.01055546, 0.008887016, -0.0127006015, 0.0045797075, 0.008485228,
      0.0020157523, -0.003922545, 0.016194118, -0.020620601, -0.014409905, 0.0057442132,
      0.017174754, -0.009111745, 0.02320839, 0.010705279, -0.0035411865, -0.01053503, 0.021982597,
      -0.025319483, -0.0024515907, -0.032224797, -0.018700188, -0.0072662425, 0.007327532,
      -0.025659982, 0.01856399, -0.009697403, -0.004480963, 0.017692313, 0.010984488, -0.009316045,
      0.019612726, 0.0018199655, -0.01313644, 0.022581873, 0.009942562, -0.00527773, -0.004532038,
      0.006200481, -0.016984075, -0.0033726397, 0.028492931, -0.0067588994, 0.020538881,
      -0.014668684, -0.005972347, -0.013640378, -0.0068814787, -0.027784694, -0.005321995,
      0.0046818573, -0.009690593, -0.003922545, -0.033314392, -0.025224144, -0.029936645,
      0.012938951, 0.014546105, 0.014069406, -0.00523687, -0.0052130353, 0.013422458, -0.009704213,
      -0.0077701802, -0.016357558, -0.012768702, -0.0042902837, -0.009724643, -0.0036876008,
      -0.004978091, 0.02372595, -0.010351161, 0.015118143, 0.007082373, -0.010793809, -0.024475047,
      0.042684916, -0.0053390195, -0.025278624, 0.01573104, -0.01320454, 0.030100085, 0.015526741,
      -0.008389888, 0.012911711, 0.0057067582, 0.0041302494, 0.008900636, -0.0013390111,
      0.014777644, 0.027539534, -0.009813173, -0.002763147, 0.029582527, -0.007708891, 0.0050257607,
      0.0013934909, -0.013388409, 0.0056693032, -0.025305863, 0.025619123, -0.024788305,
      -0.008505657, 0.0069802236, 0.005727188, 0.005509269, 0.0053730696, 0.010773379, 0.01838693,
      0.012128564, 0.0099357525, 0.018672949, -0.0021655718, 0.008355838, -0.04224908,
      0.00038476352, -0.045735788, 0.010242201, 0.011719965, 0.0061800512, -0.043747272,
      -0.027975373, 0.0033232674, -0.012067274, 0.03366851, 0.00791319, 0.009751882, -0.010289871,
      -0.030644882, -0.02612306, -0.0043209284, 0.011788066, 0.013749338, 0.026885778,
      -0.0003004901, -0.0023511436, 0.00015779986, 0.004235804, -0.008968736, -0.012366913,
      0.0051279105, 0.027021976, 0.0067282543, 0.030726602, 0.003314755, -0.0020344798, -0.01799195,
      0.019013448, 0.01826435, 0.013749338, 0.005267515, 0.009561203, -0.0060847118, 0.0007576096,
      -0.0023068788, 0.026967498, 0.027185416, -0.0021536543, 0.02363061, -0.009057266, 0.022377575,
      0.013960447, 0.017556112, 0.01849589, -0.01871381, 0.007354772, 0.010902769, 0.010977678,
      0.023181152, 0.0029606363, 0.009738263, 0.027294377, -0.0019153052, 0.0008120894,
      -0.007382012, -0.00131092, 0.003233035, -0.020103043, -0.011788066, -0.008069819,
      -0.027866414, 0.026885778, -0.017011315, 0.013810627, -0.035575304, 0.01582638, 0.0050904555,
      -0.017133895, -0.0062038866, -0.015431401, -0.025591882, 0.009472674, 0.0137970075,
      -0.009949372, -0.019966844, 0.0102013415, -0.015295202, -0.033232674, 0.002087257,
      0.0043141185, 0.012782321, 0.0050087357, -0.00052904984, -0.012761892, -0.020034943,
      -0.0008533749, 0.011100258, 0.008778056, 0.002305176, -0.00005131422, 0.007654411,
      -0.015567601, -0.007082373, 0.025469303, -0.0036092862, -0.023004092, -0.024284368,
      -0.01301386, 0.00037433577, -0.0027614445, -0.02346717, -0.013504178, 0.022472914,
      0.0086486675, 0.0031649354, 0.008852966, -0.0035820464, 0.029800447, 0.024706585, 0.010303491,
      -0.01799195, -0.034921546, 0.024080068, 0.08738559, 0.020116663, 0.016548237, -0.003721651,
      0.011624626, -0.0036365262, -0.028247772, 0.0056182286, 0.017705932, 0.0017033448,
      0.005894033, 0.005226655, 0.005584179, -0.018850008, 0.02356251, 0.0046239723, -0.0017808082,
      -0.00010502257, -0.005771453, 0.00524368, -0.02890153, -0.0093296645, 0.012305623,
      0.0106167495, 0.02358975, 0.016180499, 0.02379405, 0.022200515, 0.0033862595, -0.0137970075,
      -0.025564643, 0.03631078, 0.0042868787, 0.012911711, -0.009302424, 0.006878074, 0.011631436,
      -0.022418434, -0.0075590713, -0.0008542261, 0.025006225, 0.0059689423, 0.018958967,
      -0.0040859845, -0.0010291573, -0.010426071, 0.009751882, 0.004242614, 0.009479484,
      -0.016166879, 0.013715288, -0.008791677, 0.0019238177, 0.004467343, -0.00064865005,
      -0.012993431, 0.008927876, 0.007613551, -0.019067928, -0.037808977, -0.024597626, -0.03696454,
      0.030944522, -0.023004092, -0.018822769, 0.0043481686, -0.024624866, -0.03742762,
      -0.014900223, -0.005281135, -0.0030100085, -0.031407602, -0.026776819, -0.0017978331,
      0.010092381, 0.009683783, 0.013490559, 0.017256474, -0.011624626, 0.016575476, -0.025591882,
      -0.012394153, 0.011038968, -0.014437145, 0.012237524, -0.0024515907, -0.009098126,
      -0.016180499, -0.012503113, 0.012918521, 0.01308196, -0.02104282, -0.009751882, -0.014941083,
      -0.03443123, -0.01840055, 0.011515666, 0.03165276, 0.0049304212, -0.020157523, 0.040669166,
      -0.013585898, -0.009983422, -0.002100877, 0.02586428, 0.033287153, -0.027580395, -0.009411384,
      -0.035602544, -0.006483095, 0.048595976, -0.0006809974, 0.0028755115, 0.01312963, 0.011202407,
      0.025605502, 0.0024464831, 0.024161788, 0.007586311, 0.002456698, -0.0058804126, -0.010780189,
      0.024148168, 0.006874669, 0.00779061, 0.0025775752, -0.007061943, -0.007388822, -0.021710197,
      -0.0084239375, -0.022854272, 0.0072253826, -0.007055133, -0.037645537, -0.023426311,
      -0.005754428, -0.024488667, 0.011406707, 0.021083679, 0.0043924334, -0.038680654,
      -0.015785519, 0.0009542476, -0.021165399, 0.030045606, -0.01569018, -0.013572278,
      -0.022309475, 0.0017093035, 0.000753779, -0.005035976, 0.0015875752, -0.01045331, -0.01838693,
      -0.0099357525, -0.021873636, -0.04126844, -0.00020632094, 0.016126018, 0.008709957,
      0.028656371, -0.001483723, -0.00041668527, 0.00088189164, -0.005522889, 0.01871381,
      -0.002500963, -0.015485881, -0.018019192, 0.012666551, 0.028329492, -0.020266483,
      0.0077701802, 0.0046239723, 0.0040723644, 0.025333103, -0.005519484, -0.013415649,
      -0.0014437145, -0.04720674, 0.00792, 0.005281135, -0.016303077, 0.017637832, -0.029963886,
      0.00785871, -0.011910644, 0.014709544, 0.009043645, -0.0012334565, 0.01041245, -0.02884705,
      0.03402263, 0.0012257954, 0.011781255, -0.0063741356, -0.027171796, -0.01028306, -0.017705932,
      0.005512674, -0.0004962769, 0.023153912, -0.012114944, 0.019367566, -0.016221358,
      -0.009738263, -0.024965364, 0.0045831124, -0.0015458642, -0.001203663, 0.009125365,
      0.0017390972, -0.01853675, -0.010936818, 0.022731693, -0.0155403605, -0.046661943,
      0.014355426, -0.03647422, -0.014287326, 0.038435493, -0.012918521, 0.023167532, 0.01304791,
      0.025537403, 0.019285847, -0.0046035424, -0.01833245, 0.009636113, 0.0069087185, -0.017474394,
      0.018618468, 0.029119449, 0.005502459, -0.017937472, -0.0039021152, 0.01806005, -0.005281135,
      -0.03617458, 0.03347783, -0.017923852, -0.016561856, -0.016997695, 0.024284368, -0.04750638,
      0.02643632, -0.0021860017, -0.008410318, 0.009956182, -0.0156357, -0.012346483, 0.020620601,
      0.01036478, 0.010834669, -0.019340327, -0.017215615, 0.0037591057, -0.01049417, 0.022813413,
      0.008471608, -0.016875116, 0.040914323, -0.016262218, 0.018795528, 0.017624212, -0.0072185723,
      0.0014139209, 0.009002786, -0.0361201, 0.029800447, -0.021097299, -0.021233499, 0.016575476,
      0.017651454, 0.0033709372, 0.02625926, -0.012128564, -0.023317352, -0.028247772,
      -0.0126256915, 0.014491625, 0.009418194, -0.012108134, 0.013912777, -0.008437558, 0.009009596,
      -0.0054786243, -0.00522325, 0.007974479, -0.025700843, 0.002477128, 0.0012938951,
      -0.004246019, 0.015513121, -0.0006282201, -0.0026950473, 0.002087257, 0.022772552,
      -0.061344247, -0.0017373946, 0.0015118142, 0.0029657437, -0.037046257, 0.016112398,
      -0.027294377, -0.012646122, 0.008750817, -0.005778263, -0.0021434394, -0.014709544,
      0.0019119002, 0.024951745, 0.018768288, -0.0072662425, -0.008178779, 0.027689354,
      -0.015104522, -0.023344591, -0.008825727, 0.008532898, 0.009118555, -0.0027784694,
      0.0074160616, -0.008485228, 0.040151607, -0.028411211, 0.0028244366, 0.0006333276,
      -0.027743835, -0.017093034, -0.021424178, 0.020075804, -0.014478005, -0.00016322656,
      -0.014981943, -0.011188787, -0.011372657, 0.02329011, -0.0007661221, -0.00791319, 0.01325221,
      0.0012002579, -0.003677386, 0.011508856, -0.020675082, -0.010773379, -0.0026882372,
      -0.005836148, -0.0059859673, -0.0032040928, -0.0064558554, 0.021573998, 0.030372484,
      -0.026640618, 0.0077225105, 0.00075633277, -0.022935992, -0.00087252795, 0.010010662,
      0.035466343, 0.021519518, 0.0036331213, -0.017215615, 0.0053866897, -0.0070074634,
      -0.0048793466, -0.039525088, -0.0000055430796, -0.0077565606, -0.010725709, -0.008280928,
      0.01820987, -0.013361169, -0.0026763198, 0.029664246, -0.008022149, -0.014695924, 0.03892581,
      -0.013490559, 0.0031308855, 0.0053662597, -0.011958314, 0.0045728977, 0.0013849785,
      0.01056908, -0.038871333, 0.011134308, -0.01049417, -0.011195597, 0.0062106964, -0.033178195,
      0.035166707, -0.012353293, -0.0054547894, -0.017637832, -0.003273895, -0.001956165,
      -0.0005026612, 0.03658318, 0.0055807736, -0.026626999, 0.022064315, -0.005185795,
      -0.008812106, 0.011665486, 0.017038556, -0.020416303, -0.024829166, 0.004440103, -0.012543973,
      -0.0028618916, 0.012271573, -0.0020106449, -0.015267962, 0.0039463798, -0.004099604,
      -0.02610944, 0.0102013415, 0.020893, 0.011467996, -0.01056908, 0.008805296, 0.008219639,
      -0.017147515, 0.009581634, 0.007382012, 0.02925565, -0.05172856, -0.020511642, -0.010160482,
      -0.014232846, -0.0150091825, 0.009425004, 0.017460773, -0.011454376, 0.009438624, 0.18741049,
      -0.00015939596, 0.003173448, 0.062270403, -0.009772313, 0.0062038866, -0.010289871,
      0.005526294, -0.016616337, 0.01585362, -0.0148185035, 0.0076612206, 0.004246019, 0.0021757868,
      0.01325902, 0.012101324, -0.00097127253, -0.0106508, -0.02873809, 0.0012683576, 0.026804058,
      0.0026678073, -0.006523955, -0.018100912, -0.005267515, 0.03917097, -0.010977678,
      -0.0022813412, 0.03898029, 0.0019187102, 0.0066907997, -0.011931075, 0.0053696646,
      -0.008580567, -0.022840653, 0.00391233, 0.0033964745, -0.019108787, 0.01826435, 0.017474394,
      0.011924265, 0.010058331, 0.026722338, -0.029119449, -0.026953878, -0.0072526224,
      -0.011229647, -0.013640378, 0.016248599, 0.009309234, -0.03745486, -0.01056908, 0.015445021,
      -0.007341152, -0.01872743, 0.015417782, 0.0119787445, 0.0095203435, -0.0051142904,
      -0.019830644, 0.018686568, 0.04186772, -0.004726122, 0.023998348, 0.011617816, 0.028166052,
      -0.010786999, 0.0010291573, 0.010330731, -0.002521393, 0.0033436972, -0.014123886,
      0.009179845, 0.016275838, -0.020511642, -0.0260822, 0.026831297, 0.041704282, 0.01045331,
      0.033586793, -0.00790638, -0.01856399, -0.016847875, 0.008907446, -0.01329988, -0.031571038,
      -0.006220911, 0.0019170077, -0.01871381, -0.004937231, 0.0064252107, 0.00799491,
      -0.00019323302, -0.004501393, 0.009942562, 0.008600997, 0.00040859845, 0.05281816,
      -0.011944694, 0.017855752, -0.009772313, 0.040478487, 0.03214308, 0.0054343594, 0.022677213,
      -0.016752535, -0.009888083, 0.0141375065, -0.0064286157, -0.013653998, 0.006833809,
      -0.016970456, -0.009281995, -0.013940017, 0.002100877, 0.016112398, 0.024733827,
      -0.0018369905, 0.010827859, -0.011754015, -0.017011315, -0.032987513, 0.019789785,
      0.0040076696, -0.0035105415, 0.00795405, -0.01582638, 0.010010662, -0.0057067582,
      -0.0028823216, 0.0094045745, -0.029119449, -0.013333929, -0.014151126, -0.042848356,
      0.0026303525, -0.02651804, -0.007041513, -0.03908925, 0.013483749, 0.012904901, -0.005505864,
      0.032279275, -0.010821049, -0.017896611, -0.013585898, 0.00066907995, 0.0074773515,
      -0.001662485, -0.031407602, -0.019803405, -0.0034867066, -0.02602772, -0.007116423,
      0.02881981, -0.0067248493, -0.031734478, -0.032388236, -0.009220704, 0.007041513, -0.03168,
      0.0027750644, 0.041050524, -0.0003749742, -0.017624212, 0.0134565085, -0.1744443, 0.025837041,
      0.013708478, -0.035221186, -0.006874669, -0.009901702, 0.051755805, 0.0009031728,
      -0.018604849, -0.015186242, 0.002987876, 0.023099432, -0.0366649, 0.007361582, -0.011570146,
      -0.0048691314, 0.006343491, 0.029337369, 0.013483749, -0.011141118, 0.02616392, -0.016875116,
      0.014491625, 0.00653417, -0.0015807652, 0.009261564, -0.010031092, 0.019558245, 0.030644882,
      -0.013395219, -0.009452244, 0.0102013415, 0.0066873943, -0.01558122, 0.0014505244,
      -0.00025196903, -0.01567656, -0.0568769, -0.022854272, 0.011038968, 0.047833256, -0.01297981,
      0.0022711263, 0.0020480996, 0.0046205674, -0.0035105415, 0.012292003, -0.011965125,
      0.019612726, -0.0024856406, -0.01040564, -0.003267085, 0.026844917, -0.015336062,
      -0.016248599, 0.0059859673, 0.010249011, 0.0124350125, 0.019653585, -0.0025605503,
      -0.032633394, -0.0027256922, 0.0056761135, 0.017556112, -0.02386215, -0.01584, -0.03151656,
      0.0019748926, -0.018781908, 0.012918521, -0.010065142, -0.012516732, 0.014014927,
      0.0029708513, 0.0006086414, -0.013524609, -0.008600997, 0.027362475, -0.0050972654,
      0.011944694, -0.022363955, 0.054507032, -0.014709544, 0.024720205, -0.007388822, 0.005819123,
      0.014682304, -0.023739569, 0.0048350818, 0.0058667925, 0.0141375065, -0.0137970075,
      -0.0065954598, 0.011862975, 0.019299466, 0.00033709372, -0.016207738, 0.0031376956,
      -0.0035003268, -0.007279862, 0.009030025, -0.02375319, -0.0019953223, 0.007668031, 0.00514153,
      0.006796354, 0.0010155374, 0.012598452, 0.0420584, -0.0060268273, -0.025823422, -0.01575828,
      0.021587618, -0.020606982, 0.0049099913, 0.0047976268, 0.0047022873, 0.0069461735,
      0.013545038, 0.003684196, 0.049140774, -0.00012768702, 0.0038850901, 0.015199862, 0.013361169,
      -0.008369458, -0.08368096, -0.0041268445, 0.03944337, 0.035575304, -0.0034798968, 0.013912777,
      -0.009356905, 0.021437798, -0.025891522, 0.04696158, 0.015227102, -0.03957957, -0.008805296,
      -0.009173035, 0.017406294, 0.0063128457, 0.027757455, -0.02121988, -0.0064967154, 0.0318162,
      -0.0066771796, -0.0319524, 0.014001307, -0.009806363, -0.014845744, -0.019776165,
      -0.029773207, 0.010071952, 0.014954703, 0.012864041, 0.036092862, 0.002904454, 0.010827859,
      -0.023957489, 0.00262184, -0.025986861, -0.042521477, -0.0057408083, 0.011045778, -0.03230652,
      -0.00067972054, -0.006023422, 0.010725709, -0.03900753, -0.021846397, -0.04243976,
      -0.014423525, 0.00783147, -0.0056488733, -0.026667858, -0.013851487, -0.013974067,
      -0.012666551, -0.035874944, 0.012312433, -0.020361822, 0.009874462, 0.020348202, 0.014110266,
      -0.0022081342, -0.026463559, -0.0062072915, -0.0051245056, 0.0014011521, 0.029582527,
      0.004140464, -0.022595493, -0.016766157, 0.012080894, -0.012755081, -0.026899397, 0.025196904,
      -0.03955233, 0.031026242, -0.00393276, 0.023276491, -0.022295855, -0.009363715,
      -0.00012864466, -0.030917283, 0.00047286757, -0.014014927, 0.002752932, -0.0019136027,
      0.01842779, -0.024393328, -0.021478659, 0.0022966638, -0.0019714874, -0.0066873943,
      0.012251143, 0.038135856, 0.006071092, 0.008934686, 0.0026031125, 0.010228581, 0.0019204126,
      0.0034066895, 0.0035411865, 0.0077633704, -0.02394387, 0.0011040671, -0.061017368,
      0.022541014, 0.029582527, 0.003374342, 0.0048725363, -0.0022898538, 0.014750403, -0.012414583,
      -0.002928289, -0.015077283, -0.037645537, 0.0032653825, -0.02364423, -0.02363061,
      -0.012823181, -0.013865107, 0.015472261, 0.005509269, 0.014314566, 0.03151656, -0.006275391,
      0.0017033448, 0.03881685, -0.0010155374, -0.012537162, 0.012346483, -0.013892347, 0.03151656,
      0.005873603, -0.009956182, 0.024638487, -0.019762544, -0.004508203, 0.013279449, 0.011433947,
      -0.021900877, -0.003153018, -0.0002745271, 0.043311436, 0.022554634, 0.016275838,
      -0.022037076, 0.024325227, -0.04456447, -0.0043822182, 0.011120687, -0.021478659, 0.008607808,
      -0.0059519173, 0.010984488, 0.025850661, -0.0019221152, 0.026640618, -0.003018521,
      -0.00787914, -0.0008231556, -0.0005069174, 0.021751057, 0.010800619, -0.024842786,
      0.0071504726, 0.01833245, 0.020007703, 0.021437798, 0.0043379534, 0.015104522, -0.01598982,
      0.014559724, 0.008621427, -0.0028108167, 0.0045967326, 0.0069631985, 0.016371178, 0.048514254,
      0.0024124333, -0.006646535, -0.0086486675, 0.0061596218, -0.0128504215, 0.0074501117,
      -0.0106916595, 0.017855752, -0.017842133, 0.02884705, 0.024189027, 0.0056386585, -0.020498022,
      -0.0081583485, -0.011216028, 0.010630369, -0.00051032245, -0.010269441, -0.009213895,
      0.009819983, -0.009826792, 0.010276251, -0.0013194325, -0.023167532, 0.0003368809,
      0.029800447, -0.0086486675, -0.021642098, -0.019204127, -0.022854272, -0.014586965,
      0.02876533, -0.024679346, -0.017447153, 0.013619948, -0.00014311586, 0.021805538,
      -0.023998348, -0.01872743, 0.019244988, -0.017678693, 0.027267136, -0.017419914, -0.024243508,
      -0.017610593, 0.028329492, -0.008199208, 0.014968323, 0.010235391, 0.020498022, 0.041050524,
      -0.006241341, 0.008314978, -0.014287326, -0.0011117283, -0.008206019, -0.010078762,
      -0.0029623387, -0.013940017, -0.009064076, 0.014832123, -0.013810627, -0.01064399,
      0.0122443335, -0.009901702, 0.087440066, -0.024475047, -0.0041064145, 0.01799195,
      -0.0031036458, 0.028492931, -0.0042970935, 0.011781255, 0.010678039, -0.016275838,
      0.019503765, -0.030263525, 0.00014428633, -0.020007703, 0.01831883, -0.0013645486,
      -0.025809802, 0.0028857265, 0.007143663, 0.009268375, 0.001704196, -0.016520998, 0.030318005,
      0.0020191574, 0.006094927, 0.00073845655, 0.017855752, -0.011931075, 0.0025792776,
      -0.030590404, -0.012366913, 0.0006286457, -0.025224144, -0.015308822, 0.027444195,
      -0.028547412, -0.032469958, -0.021465039, 0.020293722, -0.0070278933, -0.01582638,
      -0.0005218143, -0.012789131, 0.0026678073, 0.00041647247, -0.025673602, -0.0008997678,
      0.009942562, -0.03745486,
    ],
  },
  showintableformat: {
    data: {
      isDemo: true,

      headers: ["School", "2018", "2019", "2020", "2021", "2022"],
      rows: [
        {
          School: "Hamilton",
          2018: 900,
          2019: 905,
          2020: 910,
          2021: 920,
          2022: 925,
        },
        {
          School: "Cherry Creek",
          2018: 910,
          2019: 920,
          2020: 915,
          2021: 930,
          2022: 940,
        },
        {
          School: "Ballard",
          2018: 905,
          2019: 915,
          2020: 910,
          2021: 915,
          2022: 920,
        },
        {
          School: "Wallingford",
          2018: 915,
          2019: 925,
          2020: 920,
          2021: 930,
          2022: 935,
        },
        {
          School: "Fremont Hill",
          2018: 920,
          2019: 930,
          2020: 925,
          2021: 935,
          2022: 940,
        },
        {
          School: "West Woodland",
          2018: 925,
          2019: 935,
          2020: 930,
          2021: 940,
          2022: 945,
        },
        {
          School: "Blue Ridge",
          2018: 930,
          2019: 940,
          2020: 935,
          2021: 945,
          2022: 950,
        },
        {
          School: "Kenmore Lake",
          2018: 935,
          2019: 945,
          2020: 955,
          2021: 965,
          2022: 960,
        },
        {
          School: "East Renton",
          2018: 960,
          2019: 970,
          2020: 965,
          2021: 975,
          2022: 970,
        },
      ],
    },
    id: 1,
    isFavorite: [],
    title: "Trend of average PSAT by school for last 5 years in table format",
    visualisation: TABLE,
    secondAnswer:
      "The returned results show the average grades for sophomores in different schools. The data is grouped by school, and for each school, the name and ID are shown along with the average grade of all sophomores in that school. The average grade is calculated by adding up all the grades of sophomores in a school and dividing by the total number of sophomores in that school. This information can be used to compare the academic performance of different schools and identify schools where sophomores are doing particularly well or struggling.",
    embedding: [
      -0.030984795, 0.01403731, 0.0056313295, -0.008088572, -0.007867456, -0.00086663273,
      -0.020114439, -0.019287037, -0.027503999, 0.0042868005, 0.038688198, 0.019158646,
      -0.015221352, 0.020613734, -0.004179809, 0.023081675, 0.015948895, 0.0061163586, 0.023466846,
      -0.009557925, -0.030899202, 0.006633485, 0.00061208167, -0.013894655, -0.0071006822,
      0.014237029, 0.001284792, -0.024551028, -0.014893244, -0.005203363, 0.014465277, -0.013431024,
      -0.005852446, -0.041398652, -0.026719393, -0.033524066, -0.008616398, -0.0052996553,
      0.0032364987, 0.0017029512, 0.0032739458, -0.010799029, 0.003366672, 0.012040132,
      -0.009122825, 0.025806397, 0.014458144, 0.0005224761, -0.00006218892, 0.005606365,
      0.024451168, 0.013566547, -0.032496944, -0.032468412, 0.010071485, -0.0053067883,
      -0.0012277297, 0.0050821058, -0.005809649, -0.01603449, 0.012018734, -0.004967981,
      -0.017118672, 0.019130114, -0.013466688, -0.018559493, -0.039173227, 0.008488008,
      -0.0058132154, 0.017004548, 0.019800596, 0.054722685, -0.007703402, 0.0041227466, 0.035321526,
      -0.007182709, -0.021911899, 0.014878978, 0.0110558085, -0.018816274, 0.0115051735,
      0.0005724056, -0.029900612, 0.012532294, 0.030242985, 0.016162878, -0.006369572, 0.043567017,
      -0.0035271596, -0.0068653002, 0.020157235, 0.007610676, 0.02550682, 0.033552594, 0.0065371925,
      0.035121806, -0.008067174, 0.022311335, 0.022939019, -0.004443722, -0.010342531, 0.01950102,
      -0.011077207, -0.014122903, -0.02830287, 0.0031794365, 0.040143285, 0.016376862,
      -0.00078728056, 0.0038481345, -0.009729112, 0.04179809, 0.018702148, -0.0092298165,
      0.004586377, 0.008081439, -0.029757956, -0.008637796, -0.008951639, -0.013580812,
      -0.003794639, 0.038830854, 0.037946388, -0.032240164, -0.015948895, 0.008195564, -0.03061389,
      -0.017774887, 0.0013837593, -0.013245572, 0.07155605, -0.002542836, 0.017118672, 0.019344099,
      -0.009265481, 0.0377752, -0.033295814, 0.029929144, -0.045221824, -0.02196896, 0.011654962,
      0.016890422, 0.0068118046, -0.03321022, 0.015506663, -0.019372629, 0.030014737, 0.016762033,
      -0.005110637, -0.010442389, 0.02550682, -0.012090062, -0.012510896, -0.006045031, 0.005691958,
      0.024622355, -0.01696175, 0.010663506, -0.014179966, -0.009607854, 0.012674949, -0.0037019127,
      0.026548207, 0.017974604, 0.022824895, 0.027061766, -0.0060129333, -0.025435492, -0.004893087,
      -0.00061341905, 0.0028334968, 0.0064266347, -0.025292836, -0.001747531, 0.028145948,
      0.016562315, 0.009914564, 0.0064266347, -0.01336683, -0.0041120476, -0.032668132, 0.028416995,
      0.0105921775, 0.021954697, -0.018844804, 0.0034611814, 0.018559493, -0.0056206305,
      0.0046077757, -0.007731933, 0.019829128, 0.022368398, -0.014108638, -0.0071684434,
      -0.64822704, -0.03800345, 0.00803151, -0.013773398, 0.025606679, 0.023652298, 0.015192821,
      -0.001993612, -0.051213358, -0.026976174, -0.0011795835, 0.029757956, 0.01218992, -0.01696175,
      -0.007817526, -0.010841825, 0.005938039, -0.019429691, -0.014950306, 0.013245572,
      0.0010485186, 0.0069865575, -0.0033185259, 0.00704362, 0.020028844, 0.011426713, -0.006294678,
      -0.01439395, -0.017532373, -0.0062982445, -0.04716194, 0.012232717, -0.0014113988,
      -0.0036109698, 0.060771283, -0.014836182, -0.014115771, 0.018231384, 0.018488165, 0.039116163,
      0.00021989022, -0.037518423, 0.0042868005, 0.0015228485, 0.021227153, -0.017874746,
      0.011255526, -0.011348252, -0.012025867, -0.01991472, 0.013659273, -0.0018955362,
      0.0027015402, 0.006950894, 0.02512165, 0.004689803, 0.005114203, -0.02673366, 0.01750384,
      0.007582145, -0.021540994, 0.004582811, -0.009265481, -0.02643408, -0.013152846,
      -0.0018723547, -0.013673538, -0.027161624, -0.0016895772, -0.004718334, -0.015520928,
      -0.0036591159, -0.007189842, 0.011013011, 0.0010271203, 0.028317135, 0.0028655943,
      -0.01542107, -0.0005403081, 0.011747688, 0.0020381918, -0.012510896, -0.028117418,
      -0.00084300543, 0.027104562, -0.013944584, -0.026648065, 0.0023948308, 0.01000729,
      -0.0018135093, 0.020314157, 0.013031589, 0.021412605, 0.0038873649, -0.008687725, 0.017275592,
      0.007453755, 0.009515128, -0.020670796, -0.024707949, -0.018274182, -0.00037692284,
      0.00057508034, 0.028730836, 0.019486753, -0.004864556, -0.018645085, 0.015891833, 0.021227153,
      -0.03360966, -0.009857502, 0.0022914056, -0.016562315, 0.0028317135, -0.026163036,
      -0.041227467, 0.046191882, 0.018174322, -0.00060584047, 0.040656846, 0.0050464417,
      0.011219863, 0.021384073, -0.034579717, -0.001069917, 0.028459791, 0.031098919, -0.010249805,
      -0.0133026345, 0.00003513451, 0.013944584, -0.021483932, 0.041855153, -0.02345258,
      0.024080263, -0.006943761, -0.0049430165, 0.00541378, 0.010107148, -0.0053531514,
      0.0087875845, 0.00532462, -0.000839439, -0.02817448, -0.007482286, -0.012632153, -0.03164101,
      0.009814705, 0.00035663898, 0.027717981, -0.008652062, -0.023409784, -0.016762033,
      0.015663585, 0.008452344, -0.023252862, -0.0016360814, -0.032097507, 0.0016860109,
      -0.023523908, -0.014736323, 0.030014737, -0.029672364, -0.016376862, -0.0007801478,
      -0.010585045, -0.013787664, 0.018488165, 0.0050179106, -0.02206882, 0.0038944976,
      -0.0014212064, -0.00656929, 0.022824895, -0.022796365, 0.041940745, 0.0047076344,
      -0.026648065, 0.013723468, 0.0069936905, 0.020057376, 0.008701991, -0.027446937, -0.011198464,
      0.014750589, -0.009814705, -0.026491145, -0.024208654, -0.010891754, 0.014308356, 0.014315489,
      -0.020613734, 0.006836769, 0.007867456, 0.022568114, 0.02853112, -0.024579559, -0.012246983,
      0.01167636, 0.017717825, 0.056291897, 0.017903278, 0.014793385, -0.030271517, 0.017917542,
      -0.019986048, 0.00064729975, -0.021084497, 0.023038879, 0.013616476, 0.011241261,
      -0.036091864, -0.014179966, 0.00013173353, 0.022125883, 0.0050892383, -0.019386895,
      0.008680593, -0.007988714, 0.011690626, 0.0049715475, -0.020699326, -0.008530805,
      -0.014294091, 0.005003645, 0.01824565, 0.0030403472, 0.018673617, -0.011640697, -0.008388149,
      -0.024850605, -0.018331245, 0.01134112, 0.0044044913, 0.011141402, 0.015834771, 0.0011037977,
      -0.0048181927, 0.038916446, -0.0027193723, -0.009529394, 0.026377019, 0.026804985,
      -0.017817684, 0.008480875, 0.02804609, 0.023552438, 0.024793543, -0.0034914957, 0.0034094688,
      -0.01395885, 0.016505253, -0.02907321, 0.02004311, 0.016904688, -0.0039230287, -0.004604209,
      0.0017724958, 0.021911899, 0.017232796, -0.014436746, -0.0036840807, 0.03600627, 0.018388307,
      0.004144145, -0.022725036, 0.009657783, -0.014764854, -0.036776613, 0.0098789, 0.002908391,
      -0.011526572, -0.005242593, 0.02396614, 0.005206929, -0.006130624, 0.0023484677, 0.025920521,
      -0.00019525984, -0.00020161248, -0.010542248, -0.032839317, 0.018003136, 0.0036840807,
      -0.0046291742, -0.016490987, -0.012175655, -0.0146507295, -0.02981502, 0.0027693016,
      0.0029797186, 0.0060485974, 0.014429613, -0.0022557415, -0.0067440434, -0.0062982445,
      0.01365214, -0.0061341906, -0.002542836, -0.0031509055, -0.008951639, -0.012525161,
      -0.03569243, -0.017689293, 0.021212887, 0.019201443, -0.0057775513, -0.009579323,
      -0.015378273, -0.026348488, -0.010499451, -0.023381252, -0.0075964103, 0.0015183905,
      0.0040193214, 0.008644929, 0.0037375765, 0.03640571, 0.025792131, -0.0048788213, -0.031270105,
      -0.031983383, -0.013559414, 0.013423892, -0.008052908, 0.038574073, 0.0106135765,
      -0.005313921, -0.019201443, -0.0009450933, -0.011319721, -0.028288605, 0.0077961283,
      -0.02691911, 0.009329676, -0.002744337, 0.01837404, 0.012232717, 0.0071363463, 0.0007404717,
      0.020314157, 0.005884543, 0.022796365, 0.0006718187, -0.04613482, 0.004775396, 0.006633485,
      0.015335476, 0.024237186, -0.008102838, 0.022896223, 0.0039907903, 0.023595234, -0.0326396,
      0.0032436317, 0.015221352, 0.029058944, -0.010756232, -0.00764634, 0.017746355, 0.0038481345,
      -0.0070578856, 0.013167111, 0.001189391, 0.0012945995, 0.023523908, 0.021712182,
      -0.0036983462, -0.0105993105, -0.007582145, -0.006972292, 0.020314157, -0.030785076,
      -0.008880311, 0.012304045, -0.008844647, 0.003202618, 0.0006579989, -0.008951639, 0.01529268,
      -0.046505723, -0.018202854, -0.02602038, -0.011269792, -0.033495534, -0.008430946,
      0.026476879, 0.011440978, -0.0015620787, -0.0036502, -0.016847625, 0.005577834, -0.0115194395,
      0.013987381, 0.03697633, -0.024165858, -0.026976174, 0.00092815293, -0.0004480277,
      0.0013962416, -0.018345509, -0.018502431, 0.00854507, 0.012075796, 0.008373884, -0.02643408,
      -0.007007956, -0.040114753, 0.00875192, -0.009771909, -0.016305534, -0.0028513286,
      0.0032703795, 0.04467973, 0.02117009, -0.0124181695, 0.00980044, -0.019058788, -0.0064801304,
      -0.012995925, 0.016391128, -0.0032400652, -0.0035164603, 0.0059059416, 0.0008153659,
      -0.019871924, -0.007981581, -0.020613734, 0.004251137, 0.0142013645, -0.00497868,
      0.0041691097, -0.0128247375, -0.009771909, 0.014693527, -0.0028317135, 0.010178477,
      -0.0046505723, 0.016747767, -0.0021469668, -0.008438079, 0.008637796, 0.007236205,
      0.0092583485, 0.00049795717, -0.018830538, 0.0071399123, 0.0055742674, -0.005920207,
      0.017689293, 0.025235774, -0.042454306, 0.0046791034, -0.008038643, -0.002788917, 0.014750589,
      -0.0101570785, 0.011191332, -0.018944662, -0.027646653, 0.010000157, 0.024807807,
      -0.010856091, -0.010492319, -0.016391128, -0.022011759, -0.003933728, 0.020428281,
      -0.025563883, -0.01988619, -0.005231894, -0.0110558085, 0.020028844, 0.005313921, 0.015991691,
      0.01077763, -0.010863223, -0.0067547425, -0.0067262114, -0.029387053, -0.0318122,
      -0.007218373, 0.018759212, 0.0034914957, 0.0062518814, 0.0075536137, 0.0021665818,
      -0.008894577, -0.0073396303, 0.022653708, 0.00057508034, -0.008459477, -0.018445369,
      0.0078959875, -0.007575012, 0.0059130746, 0.02258238, 0.005827481, 0.0061805537,
      0.00017508745, 0.0033078266, 0.008488008, -0.018473899, -0.02653394, 0.016334066,
      -0.010677772, -0.004564979, -0.0047254665, -0.0283314, -0.010970215, 0.04796081, 0.0048181927,
      0.020271359, 0.020756388, 0.00905863, 0.0011358951, 0.013373962, -0.009051497, 0.015991691,
      0.012853269, 0.002817448, -0.014379684, -0.0023021046, 0.027247218, 0.00898017, 0.012760543,
      0.00867346, 0.011020144, -0.011769086, 0.015577991, 0.009351074, 0.0025588847, -0.0016663957,
      -0.010428124, -0.030071799, -0.013159978, 0.013174244, -0.010271203, 0.011754821,
      -0.021241417, -0.025692273, 0.014329755, 0.00044267814, -0.009479464, 0.020200033,
      -0.015592257, 0.035549775, 0.018659351, 0.012446701, 0.027004704, -0.01249663, -0.029929144,
      -0.0026551771, -0.0062518814, -0.016077286, 0.014422481, 0.02206882, 0.01796034, -0.03032858,
      0.016633643, -0.0038624003, -0.029087476, -0.019715004, 0.0067083794, -0.015078696,
      0.0040549855, -0.032525476, 0.005510072, -0.016533783, 0.014807651, 0.007674871, -0.00369478,
      0.009379606, -0.007229072, -0.009764776, 0.015749177, -0.016947484, 0.032753725, 0.007731933,
      0.005588533, -0.032554008, -0.04670544, 0.00002001915, -0.013566547, 0.0030153827, 0.02255385,
      -0.019315567, 0.036120396, 0.020841982, 0.0054672756, -0.013131447, -0.0279177, -0.011712024,
      0.022240007, -0.023566704, 0.0092440825, 0.014993103, 0.034807965, -0.0008621747,
      -0.011433846, -0.0005884543, -0.013188509, 0.005645595, 0.012353974, 0.015207087, 0.010292601,
      0.0024376274, 0.0044865184, 0.010692037, -0.010991613, 0.0010547598, 0.0038017717,
      -0.01619141, 0.0016209242, -0.034865025, -0.019344099, 0.0019811296, 0.016662173, 0.020428281,
      0.004482952, 0.043966454, 0.032011915, -0.031270105, -0.010934551, -0.004433023, -0.01542107,
      -0.024950463, 0.010870356, -0.0069544604, -0.008623531, 0.020884778, -0.014693527,
      -0.011783352, 0.003769674, 0.0053281863, 0.0022914056, 0.014607932, -0.01960088,
      -0.0060022343, 0.017004548, -0.001783195, -0.009764776, -0.008566468, 0.020414015,
      -0.014793385, -0.023024613, -0.0044294563, -0.017675027, 0.011997336, -0.006540759,
      -0.010585045, 0.006686981, -0.026562471, 0.010955949, -0.0031633878, 0.005167699,
      -0.037118986, -0.014529472, -0.013502352, -0.010378194, -0.009422402, 0.0065371925,
      -0.016847625, 0.014936041, 0.015492397, 0.016704971, -0.029158803, 0.006505095,
      -0.00014711358, -0.013274103, 0.004044286, -0.0124039035, -0.027874904, -0.013680671,
      -0.017075874, 0.025078854, 0.014451012, -0.03241135, -0.040114753, -0.017646497, -0.04773256,
      -0.007532215, -0.017261326, 0.01039246, 0.018502431, 0.0036983462, -0.0013347215, 0.025934787,
      -0.0031027591, -0.010171344, -0.026063178, 0.0062340493, 0.0028852094, -0.007311099,
      0.014315489, -0.004693369, -0.012453834, -0.00057686353, 0.03061389, -0.010556514,
      0.021369807, 0.0005661644, 0.002259308, -0.022996081, -0.01490751, 0.017717825, 0.018045932,
      -0.0054565766, 0.010856091, -0.024294248, 0.011198464, -0.0011528356, 0.0057418877,
      -0.013851858, 0.010856091, -0.009943095, -0.010684904, -0.014072974, -0.012689215,
      -0.021284215, 0.010934551, -0.016162878, 0.027974762, -0.0021630155, -0.009037232,
      0.027832106, 0.00609496, -0.019486753, 0.009201285, 0.023309924, -0.025678007, -0.0009317194,
      0.008530805, -0.02345258, -0.024822073, -0.0013026239, 0.009208418, 0.0023163701,
      0.0011519439, -0.012139991, 0.005406647, 0.008224095, -0.0019918287, 0.01680483, -0.033552594,
      0.0015531628, -0.0075536137, 0.004022888, 0.026548207, -0.014365418, 0.005442311, -0.02691911,
      -0.030756546, 0.0069473274, 0.004996512, 0.007810394, -0.004693369, -0.008381017, 0.009486597,
      0.02865951, 0.21364102, -0.030670952, 0.0028959087, -0.01039246, -0.0119616715, 0.039915036,
      0.02258238, -0.0007859432, -0.0034790132, 0.017461045, -0.016105816, -0.0012928164,
      0.0052461596, -0.0043046325, 0.021483932, -0.0025660174, -0.02550682, -0.019543817,
      -0.036862206, -0.013716335, 0.0050785393, -0.019800596, -0.013159978, -0.007282568,
      0.018488165, -0.01185468, -0.0012776592, 0.030813608, 0.017047344, 0.007311099, -0.00905863,
      0.0049644145, 0.0000123987775, -0.012489498, -0.00523546, -0.0047825286, -0.0002254627,
      0.011455244, 0.016262738, 0.043196116, 0.017817684, -0.009550792, 0.0015585123, -0.043224644,
      -0.013516618, 0.008095705, 0.006301811, 0.003741143, -0.0043795267, -0.0065550245,
      -0.0066120867, 0.013309767, 0.011997336, -0.0046291742, 0.027689451, -0.016562315,
      0.012988792, -0.014301224, -0.022011759, -0.0052211946, 0.0016663957, 0.020513874, -0.005157,
      0.009037232, -0.021911899, 0.019287037, -0.0077461987, 0.0059523047, -0.009636385,
      0.0020792054, -0.009415269, -0.01911585, -0.017275592, -0.013117182, -0.02612024,
      -0.021883368, -0.001984696, 0.018459633, 0.033552594, 0.027104562, -0.01529268, -0.034807965,
      -0.030185923, 0.013202775, 0.002635562, -0.04188368, 0.008430946, -0.007974448, -0.0017858697,
      0.014565136, 0.020328423, -0.004893087, -0.019358365, -0.016976016, 0.0064088027,
      0.0037197445, 0.02001458, 0.02689058, -0.02348111, 0.011819016, -0.02643408, 0.014936041,
      0.011797617, -0.030471236, 0.00898017, 0.0007739066, 0.011483775, 0.0038303027, 0.025963318,
      -0.021141559, -0.007860323, -0.027561061, -0.0024643755, -0.019044522, 0.0034932788,
      -0.009022966, 0.021540994, -0.002974369, 0.031041857, -0.02450823, -0.0029226565, -0.0210417,
      0.024978995, 0.002817448, -0.017917542, 0.004226172, -0.0030403472, 0.0119545385,
      -0.018873336, -0.0339235, -0.0008434512, -0.005716923, 0.0051819645, -0.002626646,
      0.0015888266, 0.021284215, -0.018873336, -0.0018001352, -0.016719235, -0.000908092,
      -0.0064979624, -0.0038624003, -0.008652062, -0.016876157, 0.019486753, -0.018930398,
      0.022596646, -0.013559414, 0.008873178, 0.002607031, 0.008602132, -0.0013552281, 0.007353896,
      -0.02499326, 0.039943565, -0.009743377, -0.0059879688, -0.031155981, 0.005884543, 0.021669384,
      -0.026491145, 0.000019280795, 0.0101499455, -0.0032115341, -0.008602132, -0.0021523163,
      -0.18659352, 0.016990282, -0.016291268, -0.021612322, 0.019829128, -0.00076543645, 0.01293173,
      0.03238282, 0.0041298796, 0.012660684, 0.0016209242, 0.012739144, -0.030556828, -0.011027277,
      0.00828829, -0.01254656, 0.0012232717, 0.021141559, 0.036291584, 0.016533783, 0.020471077,
      0.013887522, 0.018473899, -0.0092298165, -0.003065312, 0.004586377, -0.000706591, 0.026976174,
      -0.005338886, -0.0033327914, -0.010791896, -0.013687804, -0.021626588, -0.0064230682,
      -0.00028754017, -0.0041726762, -0.012475232, -0.003833869, 0.0017912193, 0.035121806,
      0.005788251, 0.003355973, -0.03058536, -0.012147124, 0.0034290839, 0.023552438, 0.0078888545,
      -0.009629252, 0.022339866, 0.006387404, 0.031555418, -0.048788212, 0.011362518, 0.014964572,
      0.009565057, 0.028830696, -0.019786332, -0.0006579989, -0.009429535, -0.005331753,
      -0.019772066, -0.04536448, -0.0057525868, 0.0128461365, -0.014572269, 0.0053816824,
      -0.0283314, 0.00075473724, 0.0048360247, 0.02245399, -0.0065443255, -0.027404139,
      -0.0046149082, -0.011990203, 0.020756388, 0.0015219569, -0.018416837, 0.010107148,
      -0.0017083008, -0.0064979624, -0.031013327, 0.04000063, -0.010506584, 0.0070507526,
      -0.009764776, 0.017132936, 0.011712024, -0.009721979, 0.0022521752, 0.0119688045, 0.024779277,
      -0.0057561533, 0.00949373, -0.00141764, 0.035778023, 0.014443879, 0.0059130746, 0.0074680205,
      -0.006483697, -0.04174103, 0.028616711, 0.005959437, -0.00468267, 0.015321211, -0.016861891,
      0.01567785, 0.00093706895, 0.0022147282, 0.054152064, -0.00086306635, -0.012532294,
      0.015035899, 0.022482522, 0.006494396, 0.017118672, 0.016177144, 0.0031580382, -0.0018812707,
      -0.0017662545, -0.019201443, 0.054209124, -0.012888933, -0.01644819, 0.016533783,
      -0.0070222216, -0.03549271, -0.067789935, -0.03343847, 0.011241261, 0.025663741, -0.012225584,
      0.008537937, -0.000036304733, 0.00091076683, -0.00549224, 0.001984696, -0.020242829,
      -0.023224331, -0.037575483, 0.013794796, 0.024365576, 0.02766092, -0.013252704, -0.008666327,
      -0.00468267, 0.04333877, -0.02599185, -0.010756232, 0.010235539, -0.017361186, -0.0051177694,
      -0.009764776, -0.0137306005, 0.018359775, 0.02078492, 0.020770654, 0.0062590144, -0.018060198,
      0.016205676, -0.004664838, -0.01018561, -0.024237186, -0.011883211, -0.0025125216,
      -0.00291909, -0.029387053, 0.0069116633, -0.015178556, -0.007229072, -0.024707949,
      0.017589435, -0.0075536137, -0.020214297, 0.0377752, 0.020870514, -0.0019597313, -0.01223985,
      0.027247218, -0.005342452, -0.040371533, 0.007739066, -0.02245399, 0.00098789, 0.009001568,
      0.0021576658, -0.0010832909, -0.0023021046, -0.024551028, -0.018873336, 0.028616711,
      0.008858913, -0.012182788, -0.00016940352, -0.01121273, 0.00790312, -0.007382427,
      -0.016005957, 0.025849193, -0.037889324, 0.022639442, -0.0059416057, 0.019101584,
      -0.031184513, -0.032839317, 0.013609343, -0.014030178, -0.015178556, -0.0029618868,
      0.00790312, -0.00074537547, 0.015406804, 0.011020144, 0.005699091, -0.026819251, 0.0007337847,
      -0.010363929, -0.0044223233, 0.02509312, 0.01683336, -0.0010948817, -0.024251452, -0.01873068,
      0.005902375, -0.0011287624, 0.0056134975, 0.011433846, -0.033352878, -0.015278414,
      -0.06191253, 0.0128604015, -0.022368398, -0.00828829, -0.018716414, 0.0037839396,
      -0.015535194, -0.008887444, 0.029144539, -0.0028834261, -0.01506443, -0.0029511875,
      -0.030357111, -0.01408724, -0.014486675, -0.011248394, 0.005727622, -0.0042689685,
      0.011876078, -0.0077176676, 0.0031723038, -0.02396614, -0.00082740246, 0.01408724,
      0.0053567174, 0.02004311, -0.017746355, 0.017232796, -0.0034914957, 0.010627842, 0.004611342,
      -0.033352878, -0.009450933, 0.0075536137, -0.020984638, -0.033352878, 0.012118593,
      0.029444115, 0.015863301, 0.0021523163, -0.014336887, 0.0031170247, 0.018416837, -0.01516429,
      0.012453834, 0.0035075445, -0.007988714, 0.0009165622, 0.013359697, -0.052411664, 0.004864556,
      0.013017323, 0.01439395, 0.0029708028, -0.024051733, -0.020870514, -0.009429535, 0.010492319,
      0.023381252, -0.0066370517, 0.024522496, 0.014593667, 0.016334066, 0.0053816824,
      -0.0006629027, -0.00708285, -0.0033524064, 0.002002528, 0.019943252, -0.01808873,
      -0.0101499455, 0.004436589, -0.004967981, 0.0119545385, 0.020628, -0.011127137, -0.015820505,
      -0.002141617, -0.000781931, 0.025777865, 0.024693683, -0.003093843, -0.026262894, 0.022311335,
      0.03720458, 0.027846372, -0.010870356, 0.009087161, -0.013694937, -0.010527982, -0.024422638,
      0.010385327, -0.012710613, 0.005442311, 0.025806397, 0.0036555496, 0.011169933, 0.014607932,
      -0.0006695897, 0.03269666, -0.0076820035, 0.0038124707, -0.0073966924, 0.010763365,
      0.0012589357, 0.035521243, -0.024322778, -0.01580624, 0.02206882, 0.012261248, -0.006009367,
      -0.021227153, 0.012732012, 0.019287037, -0.015577991, 0.0094438, -0.0076820035, -0.0022379097,
      -0.01490751, 0.006522927, 0.0039123297, 0.0045899437, 0.012610755, -0.018488165, 0.02142687,
      -0.01039246, 0.012639286, -0.0283314, 0.010242672, 0.0022771398, 0.008045776, 0.009650651,
      -0.013794796, -0.025763601, -0.0058025164, 0.006797539, -0.025335634, 0.022011759,
      0.010991613, 0.068303496, -0.02907321, -0.0040407195, 0.010499451, -0.0042404374, 0.010841825,
      0.02614877, 0.007532215, -0.026448347, 0.0024197956, 0.015264149, -0.018473899, -0.032354288,
      -0.024836339, -0.013238439, -0.012539427, 0.000004760991, -0.008445211, -0.0092583485,
      0.025192978, 0.02499326, 0.022154413, 0.027118828, 0.0188876, -0.013559414, 0.0009825404,
      0.014065841, -0.0024768577, 0.000101920734, -0.018160056, 0.0026480444, -0.014358286,
      -0.0035824387, -0.012610755, 0.019772066, -0.0021113027, -0.04002916, -0.018488165,
      0.014722058, 0.017047344, 0.0029101742, 0.033409942, -0.029615302, -0.00841668, 0.016690705,
      0.0053923815, 0.013766265, 0.0014203148, -0.015520928,
    ],
  },
  addpredictionfornextyearpsat: {
    id: 2,
    data: {
      isDemo: "PREDICTION",

      headers: ["School", "2018", "2019", "2020", "2021", "2022", "2023"],
      rows: [
        {
          School: "Hamilton",
          2018: 900,
          2019: 905,
          2020: 910,
          2021: 920,
          2022: 925,
          2023: 918,
        },
        {
          School: "Cherry Creek",
          2018: 910,
          2019: 920,
          2020: 915,
          2021: 930,
          2022: 940,
          2023: 944,
        },
        {
          School: "Ballard",
          2018: 905,
          2019: 915,
          2020: 910,
          2021: 915,
          2022: 920,
          2023: 913,
        },
        {
          School: "Wallingford",
          2018: 915,
          2019: 925,
          2020: 920,
          2021: 930,
          2022: 935,
          2023: 934,
        },
        {
          School: "Fremont Hill",
          2018: 920,
          2019: 930,
          2020: 925,
          2021: 935,
          2022: 940,
          2023: 947,
        },
        {
          School: "West Woodland",
          2018: 925,
          2019: 935,
          2020: 930,
          2021: 940,
          2022: 945,
          2023: 949,
        },
        {
          School: "Blue Ridge",
          2018: 930,
          2019: 940,
          2020: 935,
          2021: 945,
          2022: 950,
          2023: 951,
        },
        {
          School: "Kenmore Lake",
          2018: 935,
          2019: 945,
          2020: 955,
          2021: 965,
          2022: 960,
          2023: 958,
        },
        {
          School: "East Renton",
          2018: 960,
          2019: 970,
          2020: 965,
          2021: 975,
          2022: 970,
          2023: 973,
        },
      ],
    },
    isFavorite: [],
    title: "Prediction for next year PSAT",
    secondAnswer:
      "The returned results show the predicted average PSAT scores for sophomores in different schools. The prediction is made by considering factors like Attendance, Class Sizes, Teacher Turnover, Retake Rate, Tutoring Rate and Demographic Shift.",
    visualisation: TABLE,
    embedding: [
      -0.022774743, -0.0007780566, -0.011725132, -0.008664604, -0.017480856, 0.0030312329,
      -0.022843674, -0.02149263, -0.024870241, 0.010332731, 0.041965086, 0.022057863, -0.017122416,
      0.01520614, 0.0015811007, 0.014682265, 0.026786517, -0.008595673, 0.019521208, -0.008326843,
      0.015109636, 0.017990945, -0.014323825, -0.01308307, -0.004918214, -0.00028390883,
      0.008285485, -0.02073439, -0.0039290567, 0.0098433215, -0.0142686805, -0.02692438,
      -0.009898466, -0.022333587, -0.023339976, -0.034051824, -0.012421334, -0.015219925,
      0.03220448, -0.0024677233, 0.005473107, -0.0029140506, 0.0006108994, -0.029778115,
      0.0020213963, 0.013861989, -0.009746818, -0.021616705, -0.030164128, 0.014420328, 0.016488252,
      0.00540073, -0.027076026, -0.023629487, -0.00084138685, 0.0068379375, -0.0075754975,
      0.015978165, -0.01592302, -0.0340794, 0.019989938, -0.019093838, -0.023160756, 0.022374945,
      -0.01433761, -0.00064407237, -0.012841812, 0.016088454, -0.010477485, 0.010229334,
      0.043178268, 0.016419321, -0.016226316, -0.017673863, 0.014516831, -0.00522151, -0.01635039,
      -0.017342994, 0.00096847804, 0.022071648, 0.03788438, -0.024566945, -0.02656594, 0.021795927,
      0.03479628, -0.009415951, -0.017618718, 0.028537361, -0.007120554, 0.0026469436, 0.034382693,
      0.027420681, 0.014709838, 0.0098433215, -0.011387371, 0.0126143405, -0.010987573, 0.03220448,
      0.033831246, -0.022995323, 0.010615347, 0.019397134, -0.012131824, -0.007713359, -0.010780781,
      -0.003057082, 0.010587774, 0.00997429, 0.003012277, 0.014282466, -0.009133334, 0.054124482,
      0.0050526294, -0.0065449816, -0.003701585, -0.01836317, 0.0045390944, 0.008857611,
      -0.024346367, -0.011759598, 0.026510794, 0.015371573, 0.025752556, -0.030522568, 0.009939824,
      -0.0042978367, -0.010587774, -0.038656406, -0.026552152, -0.0005635095, 0.007006818,
      0.002433258, 0.008071799, 0.016543398, -0.014875271, 0.034768708, -0.03388639, -0.02433258,
      -0.045191046, -0.034162115, 0.0009951887, 0.018501032, -0.0039083776, 0.0064002266,
      0.007844328, 0.016364178, 0.030688, 0.011228831, -0.017425712, 0.0021265158, 0.0030036606,
      -0.013469082, -0.0009934654, 0.018087447, -0.011532126, -0.011669988, 0.013227825,
      -0.005062969, -0.000996912, -0.013965384, -0.024249863, 0.0024435977, 0.008278592,
      -0.008974793, 0.027034668, 0.03493414, -0.005228403, -0.0050043776, -0.0014208365,
      -0.022292228, 0.02249902, 0.010684277, -0.01746707, 0.022002717, 0.01364141, 0.014571976,
      -0.012607447, -0.00017943555, -0.030136555, -0.00604868, -0.007196378, 0.0050974344,
      0.020389738, 0.02875794, -0.008795572, -0.022292228, 0.006534642, -0.013407045, -0.01869404,
      -0.00009181801, 0.021409914, -0.0011003083, 0.0170397, -0.009222944, -0.6573243, -0.04190994,
      0.027737763, -0.013558692, 0.028592505, 0.01800473, 0.025132177, -0.022802316, -0.042433813,
      0.028592505, 0.0012519561, 0.0025952454, -0.01670883, -0.01858375, 0.009657208, -0.016377963,
      -0.015757585, -0.017880656, 0.0040875976, 0.011153007, -0.0026951951, 0.034134544,
      -0.02440151, 0.008588781, 0.013627623, 0.002414302, 0.022154367, -0.0017611824,
      0.000010097288, -0.0005152579, -0.020293234, 0.0170397, -0.011787171, -0.012442013,
      0.049051173, 0.005683346, -0.0046218117, 0.035072003, 0.023243474, 0.04560463, -0.017784152,
      -0.005814315, -0.0031173965, 0.010822139, -0.0127935605, 0.024759952, -0.022181937,
      -0.008512956, 0.004649384, -0.04036589, 0.011814743, 0.009298768, -0.024084428, 0.016653687,
      0.009898466, 0.001270912, 0.020830894, -0.016433109, 0.0043150694, -0.0003099733,
      -0.0050664153, 0.0070654093, -0.00013958491, 0.0015914403, -0.016846692, 0.0054558744,
      0.010601561, -0.008830038, 0.00022380977, -0.02440151, -0.00022682549, 0.017494643,
      0.0097537115, 0.0011408051, 0.024511801, -0.025380328, -0.003267321, -0.011463196,
      -0.017963372, 0.00796151, 0.0026055851, -0.005090541, -0.029805686, 0.016088454, 0.00851985,
      -0.0003931211, -0.008816252, 0.013579371, 0.003618868, 0.007865007, 0.022250868, -0.005407623,
      0.00079528935, -0.0060176607, 0.0065174093, -0.018073661, -0.021644277, 0.0072032707,
      0.00658634, -0.024318794, -0.012193862, 0.004356428, 0.006248579, 0.02034838, 0.04428116,
      0.0058384407, -0.016667472, -0.012683271, 0.018928403, -0.040310744, 0.01800473, -0.01739814,
      -0.0027779122, 0.003172541, 0.008561208, -0.032948934, 0.010567095, -0.019273058, -0.01218697,
      0.014172177, 0.0038635726, 0.012386869, -0.0027916983, -0.01876297, -0.0003489623,
      0.015798945, 0.01800473, -0.013868881, 0.0036464406, 0.002541824, -0.018983549, -0.009119548,
      0.029447246, -0.022692027, 0.021340983, -0.019631498, 0.011104755, 0.0026917486, 0.017067272,
      0.005062969, -0.03708478, 0.0063416357, 0.011842315, 0.0032811072, -0.015068278, -0.02806863,
      -0.029557535, 0.019921007, 0.0020231197, -0.0005505849, -0.01216629, -0.009905359,
      0.011814743, 0.0037360506, 0.0059280507, -0.021644277, 0.027903197, -0.0074514216,
      -0.028537361, -0.029033663, 0.010911749, 0.022374945, -0.0097537115, -0.019603925,
      0.014957989, -0.00032892925, -0.0105602015, 0.034878995, 0.014668479, -0.02178214,
      0.014778768, -0.01411014, 0.014640907, 0.013269183, -0.029750543, 0.01411014, -0.0034293083,
      -0.009891573, -0.0018594089, -0.0061520757, -0.006872403, 0.022857461, -0.011146114,
      -0.0027848051, 0.012386869, 0.0041186167, -0.0013303648, 0.021368554, -0.025711196,
      0.0074583148, -0.011035824, 0.02254038, -0.0023660504, 0.004880302, 0.0017163774, 0.008271699,
      0.007141233, -0.0066725034, -0.005976302, 0.026703801, 0.029199095, 0.0073342393, 0.029419675,
      0.0027934215, -0.0012605723, -0.034630846, 0.00090816355, -0.026758945, -0.009477988,
      0.005955623, 0.0028347801, -0.06314063, -0.025021888, 0.013186466, -0.009588277, 0.008850718,
      -0.007354919, 0.008471598, -0.029888405, -0.0035085788, -0.017784152, -0.009581384,
      0.029888405, 0.007954617, 0.0061038244, 0.0037360506, 0.0005548931, 0.029640254, 0.023477837,
      -0.0155645795, -0.022002717, 0.02001751, 0.013930919, 0.01080146, 0.006434692, -0.01811502,
      0.013324328, -0.0012304152, 0.025669837, 0.0012054277, -0.012269686, -0.011077183,
      0.018638894, -0.0018749184, -0.00061650004, -0.021837285, 0.025132177, 0.0384634,
      -0.021864858, 0.020582743, -0.014957989, 0.029585108, -0.02368463, -0.004866516,
      -0.0025280379, -0.039731726, -0.006999925, -0.012586768, 0.027393108, 0.022650668,
      0.016970769, 0.012338617, 0.006693183, -0.0018111573, 0.020982543, -0.018225309,
      -0.0008461258, -0.028620077, -0.007285988, -0.009905359, -0.004738994, -0.011559699,
      0.008561208, -0.022181937, -0.0009882957, -0.0078787925, 0.0153853595, 0.006369208,
      0.015192353, 0.0010236227, 0.0055075726, -0.015716227, 0.023877637, 0.019714216, -0.005566164,
      -0.018625109, -0.008085585, -0.020472454, -0.002362604, 0.003515472, 0.000101726815,
      0.025766341, 0.0010615346, -0.0014423775, -0.00026796857, -0.017797938, 0.032948934,
      0.019893436, 0.0039256103, 0.015150995, -0.004490843, 0.027848052, -0.010884177, -0.02419472,
      0.024360152, -0.0056936857, 0.004287497, -0.0105602015, -0.01254541, -0.004604579,
      0.011573485, -0.0107601015, -0.017563574, 0.0030639751, -0.0073411325, -0.0035327047,
      0.011559699, -0.0028675222, 0.027007096, 0.037139926, -0.016612329, 0.00025482863,
      -0.016667472, 0.020803321, 0.055668533, 0.021589134, 0.009409057, 0.017563574, -0.010677384,
      0.016364178, -0.019989938, -0.020899825, 0.009340126, -0.0000019790684, -0.013379472,
      0.031432454, 0.014696051, -0.014061888, 0.01455819, -0.0065311952, 0.011166793, -0.021547776,
      -0.012538517, -0.008795572, -0.015426718, 0.0034051826, 0.012421334, 0.005159472, 0.016364178,
      -0.00021637816, 0.007830542, 0.026014492, -0.008768001, -0.0033017863, -0.008802466,
      0.015895447, 0.0030932706, 0.027475825, -0.0029536858, 0.0074307425, 0.0029467926,
      0.0014501321, 0.011442516, -0.0007862422, -0.015468077, 0.0038739122, 0.012828026,
      -0.019217914, 0.0021764906, 0.0016629561, 0.013496655, -0.006093485, 0.020582743, -0.030688,
      0.018597536, 0.006458818, -0.02817892, 0.02149263, -0.010911749, 0.0015974718, -0.027007096,
      -0.015371573, -0.005355925, -0.020362165, -0.050154068, -0.034548126, 0.008044227,
      -0.0047562267, -0.010125938, 0.013386366, -0.015247498, -0.000018040488, -0.017990945,
      -0.011993963, 0.013696554, -0.024098216, -0.026304001, 0.0030553588, 0.0029640254,
      0.0051939376, 0.008147623, -0.00014292375, -0.0046459376, 0.0072032707, -0.0024728933,
      -0.019093838, 0.009278089, -0.007561711, -0.00814073, 0.0039979876, -0.00090385537,
      -0.012552302, -0.027241461, 0.008740428, 0.022071648, -0.0053421385, 0.013220931,
      0.0038911449, -0.005548931, -0.017370567, 0.015757585, 0.006689736, -0.014048101,
      -0.015647296, 0.019796932, -0.029860832, -0.008209661, -0.0132622905, 0.034272406, 0.03849097,
      0.011283975, -0.014751196, -0.0022643774, -0.011153007, 0.028454643, -0.0034224153,
      0.010463699, 0.0058694594, 0.016543398, 0.0071825916, -0.00055360066, 0.0071756984,
      -0.014889058, 0.002331585, 0.0025245913, -0.019397134, 0.013393259, 0.008616352, 0.010511951,
      -0.005104327, 0.010236227, 0.00016209514, -0.03220448, 0.0037050317, -0.020169158, 0.0371675,
      0.008892076, -0.026800303, -0.032866217, -0.0011614844, -0.0022023397, -0.00531112,
      0.009650315, -0.0063278493, 0.00029446385, -0.0345757, -0.010277585, 0.0023712202, 0.04662481,
      -0.01847346, 0.0014957988, -0.0036292078, -0.006872403, 0.014820127, 0.0024935724,
      0.009264302, -0.019686643, 0.000009195264, -0.003663673, -0.020541385, -0.015688656,
      -0.0073618116, 0.022057863, 0.035844028, 0.026428077, -0.015619724, -0.025283825,
      -0.00078753463, -0.0052146167, 0.0014001573, -0.02925424, -0.007006818, -0.028482215,
      0.023022894, -0.010098365, -0.013765485, 0.013806843, -0.011607951, 0.0002643066,
      -0.00090213213, -0.018156378, -0.03151517, 0.0006156384, -0.027723977, -0.011332227,
      0.021933787, -0.005224956, 0.01462712, -0.02149263, 0.0046976353, -0.0067414343, 0.010346516,
      0.0072032707, -0.0026159247, 0.012517837, -0.027792906, 0.021892428, 0.0076168557,
      0.0018749184, -0.005638541, -0.038766693, -0.011883673, 0.005676453, 0.014048101,
      0.0037257108, 0.02936453, -0.0048010317, 0.03410697, -0.026359146, 0.01610224, -0.02875794,
      -0.0026348806, 0.008147623, -0.01721892, -0.006320956, -0.008271699, -0.011311548,
      -0.022485234, 0.036864202, 0.005369711, -0.027737763, 0.022347372, -0.0050664153, -0.01947985,
      -0.00037438053, -0.007354919, 0.016336605, 0.009340126, 0.016240101, 0.035816453, 0.005038843,
      -0.026827876, 0.016391749, 0.004242692, 0.0057350444, -0.0020886038, 0.02260931, 0.01364141,
      -0.025780126, 0.009546919, 0.027379323, -0.025187323, -0.045632202, 0.027337965, 0.002031736,
      0.013606944, -0.00411517, -0.00068672333, -0.020761963, 0.017687649, -0.000008757715,
      -0.022002717, -0.0059383903, -0.023670845, -0.009581384, 0.017301636, -0.013158894,
      0.0004053994, -0.0074238493, -0.012021535, -0.0031449688, -0.014778768, 0.018997334,
      -0.008209661, -0.009105762, 0.026648656, 0.004370214, 0.012152504, 0.015730014, -0.011015145,
      0.00439434, 0.0166399, -0.0022850567, 0.027682617, -0.020045083, -0.0078719, -0.005159472,
      0.014640907, 0.00054843083, 0.009429736, 0.00088834594, -0.0003812736, -0.020610316,
      -0.011235724, 0.034713563, 0.010057007, -0.04880302, 0.0021471952, -0.008719749, -0.009677887,
      -0.0030932706, 0.007885686, -0.012262793, -0.0053352457, -0.022747172, 0.016626114,
      0.003089824, 0.014696051, 0.02030702, -0.0033672706, 0.0155645795, 0.023698416, -0.015895447,
      0.020817108, 0.0033862267, -0.008188982, -0.0100225415, 0.048637588, -0.030577712,
      -0.0077340384, 0.0045459876, -0.016088454, -0.0065070693, 0.026703801, 0.016612329,
      0.019273058, 0.027186316, 0.01610224, 0.021561561, 0.03126702, 0.0018335598, -0.014930416,
      -0.01480634, 0.016943196, 0.020169158, -0.008754214, -0.01272463, -0.020389738, 0.024098216,
      -0.027241461, -0.004442591, 0.030632857, -0.029998694, -0.037663803, 0.010456806, 0.028371926,
      -0.014296252, -0.016653687, 0.0035120253, -0.008471598, -0.0058832457, 0.013648302,
      -0.0013958492, -0.0035775097, 0.006165862, 0.00869907, -0.014103246, 0.009395271,
      -0.024249863, -0.02260931, 0.0018749184, -0.029585108, 0.004259925, 0.006000428, -0.036809057,
      0.025228681, 0.04356428, -0.04428116, -0.016198743, -0.0314876, -0.025407901, 0.004122063,
      -0.012035321, 0.005662667, 0.03374853, 0.005610969, -0.017742794, 0.039869588, 0.01399985,
      0.011566591, -0.021285838, -0.01391024, -0.016970769, -0.0009598617, -0.0074583148,
      0.024249863, -0.046790242, 0.004790692, 0.034382693, -0.011187472, 0.02404307, 0.025352756,
      -0.02947482, -0.035209864, -0.0003668412, 0.009567598, -0.010132831, -0.0130623905,
      0.008126944, -0.031349737, 0.01373102, -0.009002365, 0.0022144027, 0.0031484154, -0.013586265,
      0.021699423, -0.0046459376, -0.005686793, -0.012490265, -0.013586265, -0.009305661,
      -0.0144479005, 0.016736403, -0.005362818, -0.0060865916, 0.016777761, 0.018308027,
      0.0016164278, -0.011215045, 0.014130819, -0.018997334, -0.031184305, 0.017315423,
      -0.008574994, 0.016322818, 0.00017932784, 0.0017749687, -0.013234718, 0.0059452835,
      -0.019783147, -0.020072656, -0.0072515225, 0.026800303, 0.0020179497, -0.024718592,
      -0.004466717, 0.0012597108, -0.004514969, -0.0061176103, -0.013786164, 0.012200755,
      -0.030825863, -0.023918996, 0.0051422394, -0.009140227, -0.0089679, -0.004508076, 0.012952101,
      0.023657057, 0.03515472, 0.19179311, -0.010567095, 0.00585912, 0.023574341, 0.000055306213,
      0.008064906, 0.008106264, 0.016226316, -0.005724705, -0.00027249215, -0.01610224, 0.011483875,
      0.0073411325, -0.008361309, 0.005266315, 0.001918, -0.021340983, 0.00658634, -0.039759297,
      -0.002133409, 0.0060417866, -0.0017396417, 0.008264805, -0.010987573, 0.011511447,
      -0.006389887, -0.007375598, 0.0013493209, 0.021216907, 0.013958491, -0.021175548, 0.018018518,
      0.005721258, 0.015605938, -0.012828026, -0.00814073, 0.0092849815, 0.0020524152, 0.032121763,
      0.016405536, 0.022843674, 0.010236227, 0.010284479, -0.027723977, -0.020568958, 0.007210164,
      0.013117535, -0.02641429, 0.01887326, 0.009726139, -0.034658417, -0.02059653, 0.031542744,
      0.003980755, -0.012393761, 0.006007321, 0.019728001, 0.017411925, -0.025325185, -0.0073618116,
      -0.0091678, 0.014530617, 0.004146189, 0.028564932, -0.005128453, 0.009353912, -0.000102373044,
      -0.011290869, 0.021947574, -0.003446541, -0.007079195, -0.026524581, 0.019989938, 0.010084579,
      -0.03460327, -0.031818468, 0.021933787, 0.01462712, 0.015330215, 0.03496171, -0.012504051,
      0.007141233, -0.008085585, -0.048527297, -0.029061234, -0.038160104, 0.013834416,
      -0.008209661, -0.03008141, -0.014351397, 0.013179573, 0.0075479248, -0.03303165, -0.00448395,
      0.007906365, 0.0073618116, 0.0030932706, 0.020058868, -0.011635522, 0.01739814, -0.025559548,
      0.04157907, 0.01959014, 0.01851482, 0.0035430442, -0.010966893, -0.004108277, 0.024635876,
      0.0067759, -0.011525233, -0.00022316353, -0.025724983, -0.002795145, -0.015785158,
      0.0061003775, -0.0030432958, 0.016943196, -0.0037498367, -0.0011881951, 0.00021411637,
      -0.005407623, -0.017025912, -0.00017437343, 0.018501032, -0.0071619125, -0.006203774,
      -0.03030199, 0.008030441, -0.0079890825, -0.02634536, 0.0071136607, -0.03540287,
      0.000006196369, -0.021892428, -0.019934794, 0.009726139, -0.0041772076, -0.006593233,
      -0.02260931, 0.030384706, 0.011463196, 0.016364178, 0.00585912, 0.01947985, -0.012214541,
      -0.0028106542, 0.027503397, 0.0036912456, -0.009126441, -0.017922014, -0.022623096,
      -0.009381485, -0.0007306667, 0.0065656607, 0.025145963, 0.0003198821, -0.022457661,
      -0.049712908, 0.0035240883, -0.02645565, -0.03201147, -0.018928403, -0.009691673, -0.00705507,
      -0.0049457867, 0.01263502, -0.17899956, 0.0021764906, 0.00042327834, -0.036312755,
      -0.0076788934, -0.005459321, 0.036285184, 0.0035499374, -0.011814743, -0.038215246,
      0.017880656, 0.0227196, -0.034382693, -0.0007233428, 0.014696051, 0.010360302, -0.0042082267,
      0.051560253, 0.01337258, -0.0004734686, 0.02077575, -0.022223298, 0.009767497, 0.010436126,
      0.00013969262, 0.0067621134, -0.020169158, 0.025352756, -0.008678391, -0.017480856,
      -0.02386385, 0.0022505913, 0.0076788934, 0.01281424, -0.008547422, -0.013861989,
      -0.0047734594, -0.01887326, -0.008678391, 0.025256254, 0.020748178, -0.008933434,
      -0.010408554, -0.0000019218505, -0.008244126, 0.014930416, 0.0070171575, -0.012821133,
      0.015288856, -0.011676881, 0.010387875, -0.006868956, 0.029998694, -0.0101121515, 0.02034838,
      -0.003836, 0.0037429435, -0.021533988, 0.02073439, 0.008444025, -0.038353108, -0.015592152,
      0.0075754975, 0.00219717, -0.02663487, -0.014820127, -0.0062623653, 0.010057007, -0.025407901,
      0.013565586, -0.0005010409, 0.014434114, 0.003434478, -0.0069068684, 0.029171525,
      0.0038670192, -0.027627474, 0.029309385, 0.0048010317, -0.006600126, -0.017604932, 0.05801218,
      -0.015192353, 0.021120403, 0.017880656, -0.0061279503, 0.022926392, 0.011373586, 0.006420906,
      -0.0010994466, -0.00038515095, 0.010374089, 0.0055316985, -0.009546919, 0.007079195,
      0.016047096, -0.02875794, 0.008264805, 0.0011261574, -0.012228328, -0.008843824, -0.02238873,
      -0.019934794, -0.0008724057, 0.021120403, -0.00036124059, -0.017866869, 0.010746315,
      0.020817108, -0.011869888, -0.021644277, -0.009877787, 0.034492984, 0.009960503, 0.014889058,
      0.012841812, 0.0015345725, -0.012111145, -0.003160478, 0.011428731, 0.04248896, -0.012283472,
      0.022044078, 0.03372096, -0.012958994, -0.014241108, -0.083213285, -0.053600606, 0.020858467,
      0.020637888, 0.002312629, 0.0075754975, 0.016488252, 0.014875271, -0.03744322, 0.030963724,
      0.018845687, -0.026276428, 0.00869907, -0.010160403, 0.016116027, -0.018955976, 0.010925535,
      -0.021092832, 0.0028451197, 0.02509082, 0.014420328, -0.010746315, 0.0029192204, -0.018955976,
      -0.017563574, -0.0023591574, -0.025077034, 0.01668126, 0.01118058, 0.0068310443, 0.015619724,
      -0.013793058, 0.020403523, -0.0020817108, -0.021644277, -0.0064863903, -0.043840002,
      -0.00914712, 0.007541032, -0.021037687, 0.007375598, -0.018997334, -0.0033965663,
      0.0072722016, -0.017604932, -0.039842013, -0.02220951, 0.036560908, 0.02113419, -0.05089852,
      -0.018197738, -0.020279448, -0.023091825, -0.01965907, 0.017742794, -0.029143952, 0.019989938,
      0.031680606, -0.019728001, -0.00160178, -0.0279997, 0.01599195, -0.013124429, -0.004108277,
      0.0058935853, 0.01686048, -0.009119548, -0.030715574, 0.017370567, 0.014434114, -0.0027606795,
      0.032287195, -0.011304655, 0.020100228, -0.0192317, 0.009395271, -0.006158969, -0.020651674,
      0.02509082, -0.03005384, -0.025394114, -0.022195725, -0.0036292078, -0.015619724, 0.027696405,
      -0.03032956, 0.006320956, -0.022057863, 0.004528755, 0.014006743, 0.0010012202, 0.024070643,
      0.014061888, -0.0033017863, -0.01858375, 0.012124931, 0.006365761, 0.0052077235, 0.01189746,
      0.03281107, -0.021795927, -0.021685636, -0.064243525, 0.014254894, 0.036505762, -0.005772956,
      -0.0068620634, 0.007699573, 0.00522151, -0.009415951, -0.0044805035, 0.01836317, -0.024360152,
      0.0023074592, -0.012462692, -0.012421334, 0.0014087737, 0.0007625472, 0.03231477, -0.02455316,
      0.023836277, -0.0026831322, -0.009788177, 0.00613829, 0.022802316, 0.009733032, -0.021051472,
      -0.0053903903, 0.003560277, 0.01710863, 0.0034741133, -0.01650204, 0.0008237233, -0.011111649,
      -0.017797938, 0.0102638, -0.014516831, -0.029392103, 0.003089824, -0.018266669, 0.020458668,
      0.006203774, 0.006624252, -0.014516831, 0.0049836985, -0.013903347, 0.0016664026,
      0.0019783145, -0.009105762, -0.013717233, -0.0009107485, 0.0040875976, 0.010698063,
      0.011215045, 0.004497736, -0.015702441, -0.016515825, -0.020844681, 0.004790692,
      -0.00024298114, 0.0007082642, -0.017411925, 0.013324328, 0.031404883, 0.0013079623,
      -0.0007298051, 0.0014303145, 0.01337258, -0.0013071008, -0.0016870819, -0.009746818,
      0.004904428, -0.018928403, 0.0024108554, 0.01983829, 0.02515975, 0.025256254, -0.0034310317,
      -0.010553309, -0.015509435, -0.0015182013, 0.022760957, 0.03030199, -0.0017361951, -0.0201278,
      0.031790894, 0.0069068684, 0.013744806, -0.022071648, -0.007844328, -0.023918996, 0.015964378,
      -0.0028175474, 0.0023936229, -0.007027497, 0.018831901, 0.009739925, 0.008568102, 0.01408946,
      -0.010587774, 0.0018387297, 0.028109988, 0.023725988, -0.02001751, -0.0136207305,
      -0.016309032, -0.03008141, 0.011890567, -0.029640254, -0.013675875, 0.015261284, 0.0034155222,
      0.005745384, -0.004511522, 0.0050078244, 0.029833259, -0.011063397, 0.037250217,
      -0.0127935605, -0.008388881, -0.018859472, -0.0032449185, 0.04050375, 0.009443522,
      0.023877637, -0.009043723, 0.03385882, 0.010305158, 0.016777761, -0.019783147, 0.026662441,
      -0.0047562267, -0.015192353, -0.0060417866, -0.0069585666, -0.015426718, -0.024649661,
      0.0016784655, 0.0042943903, 0.009560705, 0.00466317, 0.0665596, -0.0014932139, -0.024387725,
      0.0016612328, 0.016970769, -0.0017990945, 0.0011778554, 0.011015145, -0.005059522,
      -0.009160906, 0.008292378, -0.009498667, 0.021175548, -0.021037687, 0.00402556, -0.008223447,
      0.0021954468, 0.0170397, -0.023891423, -0.0014044655, 0.024236077, 0.0026796856, 0.025463045,
      0.023271045, -0.014833913, -0.007285988, 0.011373586, -0.01610224, 0.011166793, -0.043398846,
      -0.020182945, 0.0016905284, 0.009808856, -0.0019128303, 0.01592302, 0.0012192139,
      -0.009567598, -0.014096353, 0.022374945, -0.013379472, 0.0015595598, 0.014172177,
      -0.0047321008, -0.001809434, 0.011773384, 0.0037739624, 0.015826516, 0.009505561,
      -0.008202768,
    ],
  },
  whyarescorespredictedtodecreaseforballard: {
    id: 3,
    data: {
      isDemo: true,

      rows: [
        { type: "Attendance", value: 20 },
        { type: "Class Sizes", value: 25 },
        { type: "Teacher Turnover", value: 15 },
        { type: "Retake rate", value: 10 },
        { type: "Tutoring Rate", value: 20 },
        { type: "Demographic shift", value: 8 },
      ],
    },
    isFavorite: [],
    title: "Why are scores predicted to decrease for Ballard?",
    secondAnswer:
      "The scores are predicted to decrease for ballard because given their current attendance, class sizes, teacher turnover, retake rate, tutoring rate and demographic shift, the predicted average PSAT score for sophomores in Ballard is 913. The weightage of each of these factors in the prediction for Ballard's PSAT scores can be seen in the bar chart.",
    visualisation: BAR_CHART,
    embedding: [
      -0.032921843, -0.002570162, 0.0024085985, -0.0074059297, -0.012167415, 0.0008927785,
      -0.027291259, -0.030203119, -0.02957915, -0.010258364, 0.015747814, 0.022076653, -0.019788763,
      0.028093506, -0.0041449433, 0.011662296, 0.024097126, -0.013222221, 0.029772282, -0.034764044,
      -0.0074133575, 0.025731334, -0.018481396, -0.029044317, -0.032089885, 0.0015311406,
      0.03871585, -0.052888885, -0.03422921, 0.0053557423, 0.015108988, 0.011528588, -0.022953182,
      -0.010035518, -0.030871658, -0.019402495, 0.016921472, -0.0013723626, 0.008535018,
      -0.031555053, -0.0075767785, -0.00881729, -0.022804618, -0.016683768, -0.009240698,
      0.004932334, 0.0063696937, -0.0044829273, -0.028019223, 0.009916666, 0.030633954,
      0.0058831456, -0.047748562, -0.013133083, 0.009329837, 0.0016815619, 0.011714294, 0.008200749,
      -0.0047392007, -0.01048121, 0.033842944, 0.0008115324, -0.006741104, 0.022938326,
      -0.0067522465, -0.007896191, 0.0032758424, 0.020130461, 0.012724531, 0.00013335966,
      0.009381834, 0.029207738, 0.0021411828, -0.02017503, 0.008549875, 0.005890574, -0.008928713,
      -0.009753245, 0.01992247, 0.00081431796, 0.03506117, -0.0042786514, -0.02632559, 0.005378027,
      0.010347502, 0.006172846, -0.005931429, 0.016327215, -0.04130087, 0.026221596, 0.041241445,
      0.02158639, 0.020620722, 0.018897377, -0.0059425714, -0.0018022704, -0.01468558, 0.042608235,
      0.015101559, -0.005656585, -0.011491448, -0.017694006, -0.015673531, -0.03090137,
      -0.023131458, -0.013073657, -0.001267439, 0.0028152931, 0.0068153865, -0.03161448,
      -0.013370786, 0.016609486, -0.011016042, -0.026489012, -0.04266766, 0.015465542, -0.023681147,
      -0.011796004, -0.017901996, -0.014395879, 0.031852182, -0.00018721422, 0.02054644, -0.0157181,
      0.0213784, 0.022611484, -0.029757425, 0.001981476, 0.009626966, -0.0045980643, 0.010681772,
      0.017471159, -0.013341073, -0.015391259, -0.014752434, 0.032595005, -0.031020222,
      -0.00091599167, -0.009990948, -0.012546253, -0.018332833, 0.018481396, -0.023651434,
      -0.009426404, 0.00093781203, 0.025003368, 0.008869288, 0.05428539, -0.022611484, -0.0101098,
      -0.009470973, -0.006443976, -0.0039332393, -0.014210174, -0.0027948655, -0.0065368284,
      -0.011684581, 0.03755705, 0.0015803525, -0.026518725, -0.03298127, -0.017991135, 0.0064662606,
      -0.0038886701, 0.007807053, 0.02724669, 0.016743194, 0.011654868, -0.008601872, -0.01643121,
      0.013526778, 0.015361547, -0.036368538, 0.034882892, 0.013846192, 0.020368163, -0.017233457,
      -0.006521972, -0.017070036, -0.008616729, -0.032713857, 0.0041486574, 0.03880499, 0.025924467,
      0.00881729, -0.008037328, 0.07125143, -0.04679775, -0.0024215977, -0.0015459971, 0.0020260455,
      0.011907428, -0.00024489893, -0.009084706, -0.59663415, -0.035833705, 0.038448438,
      0.021497251, 0.025612483, 0.017901996, 0.016490635, 0.008386454, -0.029594006, 0.033724092,
      0.009218413, 0.006046566, -0.018986516, -0.013133083, 0.008393882, -0.017174032, 0.016980898,
      -0.004776342, 0.01360106, -0.003123564, -0.012122845, 0.02533021, 0.014009612, -0.018005991,
      0.018763669, -0.028182644, -0.0053891693, -0.02512222, -0.014135892, -0.008007615,
      -0.005103183, 0.039547812, 0.002843149, 0.0010167368, 0.044569284, -0.003884956, -0.028479774,
      -0.008995567, 0.032000747, 0.031347062, -0.028598625, -0.021556677, 0.016356928, -0.008408738,
      -0.012635392, 0.026815852, 0.009248126, -0.005173751, 0.023384018, -0.040260922, 0.022448063,
      0.011662296, -0.009337265, 0.002676014, -0.0009619537, 0.0056974404, 0.027008986,
      -0.010028089, -0.016000373, -0.0063585513, -0.011402309, -0.006380836, -0.014180461,
      -0.00457578, -0.0139056165, -0.0006030781, -0.042192258, 0.0024773094, 0.019075654,
      -0.014492446, -0.0036936796, 0.0036992508, -0.013303932, 0.020041322, 0.026310734,
      0.0040669474, 0.010533208, -0.013823907, -0.011966853, 0.01763458, -0.004263795,
      -0.00032544864, -0.036219973, 0.00507347, -0.0041560857, 0.01559925, -0.005682584,
      0.0021764666, 0.008163608, -0.0125165405, 0.0015162842, 0.011283457, -0.006206273,
      -0.01438845, 0.024810236, 0.008089325, -0.01726317, -0.0042117974, 0.036992505, -0.013029087,
      -0.020085892, -0.022180647, 0.021348687, -0.0008783863, -0.0011495161, 0.002976857,
      -0.0063696937, -0.012962233, 0.016208364, -0.015316977, 0.017961422, -0.016995754,
      -0.025211358, -0.005716011, -0.0035841134, -0.02657815, 0.028227214, -0.012531397,
      0.006804244, 0.011669724, 0.0341995, 0.017500872, 0.007992758, -0.028182644, -0.030173406,
      0.04005293, 0.021928089, -0.0069565224, 0.016386641, -0.028360922, -0.009092134, 0.00935955,
      0.040528335, -0.010666915, 0.03693308, 0.009946379, 0.0240377, -0.0030938513, -0.021556677,
      -0.023057176, -0.017827714, -0.0016732053, 0.0026425873, -0.03131735, -0.029594006,
      -0.042251684, -0.023814855, 0.0062248437, -0.0112908855, -0.015108988, -0.004924906,
      0.0050883265, 0.0056343004, -0.0012432974, -0.006042852, -0.016282646, 0.0082527455,
      -0.021452682, -0.016089512, -0.023072032, 0.0048060548, 0.017857427, -0.009136704,
      0.010436641, 0.017872283, -0.010087515, -0.006651966, -0.0147152925, 0.0066779647, 0.01198171,
      -0.017768288, -0.01996704, 0.02059101, 0.010258364, -0.028479774, 0.008631585, -0.03660624,
      0.018704243, -0.0085127335, -0.0048431954, 0.024483394, -0.015071847, -0.03714107,
      -0.004052091, 0.036665667, 0.020323593, 0.013875904, 0.03182247, -0.010748626, 0.0019517633,
      -0.008884144, 0.01534669, -0.022433206, 0.0016592774, 0.014447876, -0.021155555, 0.009248126,
      0.009077278, -0.013170224, 0.012345692, 0.0374382, 0.012003994, 0.008431023, 0.0052443193,
      -0.006131991, -0.04222197, 0.00350426, -0.0097383885, -0.0011485876, 0.0013630773, 0.0202196,
      -0.044598997, -0.0023696003, 0.0102880765, -0.028346065, 0.025345067, 0.0077179144,
      0.019684767, 0.008913857, 0.009530399, -0.01788714, -0.01996704, 0.026281022, -0.008052184,
      -0.0018691244, -0.0041857986, 0.012085704, 0.007283364, 0.024364542, -0.011276029,
      -0.00019893686, 0.015391259, 0.02574619, 0.01505699, 0.018020848, -0.0019703337, 0.015643818,
      0.018600248, 0.04245967, -0.0010650202, 0.017084893, -0.017188888, 0.0010965901, -0.00944126,
      0.0104514975, -0.012219412, 0.031228213, 0.029504867, 0.0042823656, 0.011833145, -0.03981523,
      0.005964856, -0.006930524, 0.003537687, 0.020160174, -0.046857174, 0.009545255, -0.014819287,
      0.0285392, 0.03610112, 0.02001161, -0.00547088, -0.006681679, 0.01655006, -0.020709861,
      -0.017188888, 0.0029861422, 0.006035424, -0.008928713, -0.0049360483, -0.029772282,
      0.01655006, 0.013645629, -0.037170783, -0.017664293, 0.021601247, -0.008861859, 0.0015738528,
      -0.000049153885, 0.004709488, -0.013029087, -0.028910609, 0.015628962, 0.0023064604,
      -0.020056179, -0.031109361, 0.0041040885, 0.007896191, -0.0030065696, 0.040558048,
      0.036517102, 0.012798813, -0.011951997, 0.0026685859, 0.0053631705, 0.01401704, 0.020902995,
      -0.016609486, 0.0011290886, -0.027083268, 0.0044012167, 0.010830336, -0.010926903,
      -0.015153557, 0.035922844, 0.001595209, 0.009872097, -0.0341995, -0.012939949, -0.03140649,
      0.0142696, -0.00906985, 0.008141323, 0.025478775, -0.019907614, -0.008282458, -0.019699624,
      -0.01888252, 0.0014949281, 0.02928202, -0.013036516, 0.0059165726, -0.018184269, 0.007851622,
      0.12158501, 0.044361293, 0.0068116724, 0.019031085, 0.011120036, 0.031347062, -0.0098052425,
      -0.041538574, 0.011944569, 0.012806241, -0.004612921, -0.0018059845, -0.0024364542,
      -0.01992247, 0.020115605, 0.010971473, 0.000445925, -0.048402242, -0.0035562576, -0.004746629,
      -0.038686138, -0.0016193506, 0.017575154, 0.039488386, -0.010585205, 0.01464101, 0.012739387,
      0.02375543, -0.00964925, 0.0056454428, -0.020932708, 0.022180647, -0.011640011, 0.030277401,
      -0.029088886, 0.021987515, 0.022507489, -0.014574156, 0.018481396, 0.011684581, 0.0033872656,
      -0.0065405425, 0.006625967, 0.0006216487, -0.0075173527, -0.013578775, -0.005961142,
      0.02770724, 0.015584393, -0.030812232, -0.00050558284, -0.013043944, -0.022522345,
      -0.0026444444, -0.010993757, -0.0019684767, 0.0070345188, -0.0057382956, 0.0071719405,
      -0.005129182, -0.018659674, -0.0050808983, 0.022953182, 0.014410735, -0.027603244,
      -0.012999374, -0.023592008, 0.0072647934, -0.02715755, 0.00948583, 0.014537015, -0.02641473,
      -0.006102278, -0.006625967, -0.0005510806, 0.019952184, 0.01414332, -0.00025325568,
      -0.0048803366, 0.022581771, -0.008312171, 0.005340886, 0.0105480645, -0.0027298687,
      -0.0016360641, -0.019521346, -0.023636578, -0.0009777386, -0.032773282, -0.0022043225,
      0.0034076932, -0.017025467, 0.008245317, -0.010912047, 0.017486015, -0.016446067, 0.030782519,
      0.032268163, -0.0122268405, -0.007896191, 0.0038886701, -0.0181397, -0.031287637,
      -0.009686391, 0.006198845, 0.0059165726, -0.008475592, 0.0022303213, 0.020264167,
      -0.011342883, 0.0017864855, -0.01680262, 0.01118689, 0.008133895, -0.0021597533, -0.018451683,
      0.021452682, 0.004898907, -0.007346504, 0.0098052425, -0.005385455, -0.0011625155,
      0.018941946, -0.00060539943, 0.0022414636, -0.0003312519, -0.00034378705, 0.0010408785,
      -0.026890134, -0.017798001, -0.020858426, 0.028717475, 0.0228789, -0.026043318, -0.040290635,
      -0.028925465, 0.021987515, 0.00492862, -0.013110798, -0.019833332, -0.007316791, 0.0017567726,
      0.017114606, -0.053483143, 0.017604867, -0.015777526, -0.03214931, 0.008237889, 0.01464101,
      -0.0038663854, -0.00061189913, 0.0014392165, -0.031881895, -0.019402495, 0.010354931,
      0.004750343, 0.037497625, -0.016104368, 0.020412732, -0.00049397623, 0.040320348, 0.006941666,
      -0.017604867, 0.017159175, 0.020204743, -0.0062731267, 0.0048023406, 0.0021968943,
      -0.030084267, 0.02158639, 0.0023176027, 0.0053223153, -0.005229463, -0.007628776, 0.011342883,
      -0.012122845, 0.0228789, -0.0014039325, -0.027974654, -0.041122593, -0.010540636,
      -0.004193227, -0.0070270905, -0.004653776, -0.039547812, -0.010176653, 0.03440749,
      0.0015784955, 0.01705518, -0.029133456, 0.03194132, -0.0071756546, 0.022254929, -0.009894381,
      -0.0062582702, -0.005478308, -0.03610112, -0.012739387, -0.014091322, 0.019031085,
      0.001685276, 0.024854805, -0.011617727, 0.00030873515, 0.014351309, 0.015465542, -0.041657425,
      0.0029192881, -0.001910908, -0.03473433, 0.005964856, -0.05137353, -0.03827016, -0.029623719,
      -0.0018078416, -0.0034076932, -0.009173844, 0.018644817, -0.015747814, -0.024839949,
      0.0113874525, 0.0041598, -0.0017112748, 0.009619538, 0.021571534, 0.0028190073, -0.0031365633,
      -0.018852808, 0.015465542, 0.014173033, -0.013883332, 0.01626779, -0.00037094645,
      -0.015896378, -0.02595418, -0.006443976, 0.015331834, -0.016208364, -0.030307114, 0.050422717,
      -0.00068293145, 0.0096121095, -0.0041560857, -0.0054560234, -0.041568287, 0.008988139,
      -0.013942758, -0.021110985, 0.013274219, -0.021096129, 0.019699624, 0.0122268405,
      -0.015539824, -0.0032034174, 0.011758863, 0.012070848, -0.009322409, -0.012702246,
      0.001753987, -0.009366978, 0.007376217, 0.043202493, -0.029608862, 0.011134893, 0.006247128,
      0.0026815853, -0.01380905, 0.019001372, -0.01742659, 0.03360524, -0.010659487, 0.008780149,
      -0.0035525435, -0.020085892, -0.014997564, -0.002219179, -0.018912233, -0.000015552823,
      -0.010718913, -0.0058794315, 0.036546815, -0.0006717891, -0.028435204, 0.016891759,
      -0.017619723, -0.009872097, -0.019521346, -0.023621721, 0.010726341, -0.020680148,
      -0.0088990005, 0.0106892, 0.0013630773, 0.031584766, 0.013155367, 0.00037117858, 0.009560112,
      0.03470462, -0.017188888, 0.014886141, -0.018778525, 0.012583395, -0.023770286, 0.0045497813,
      -0.021853806, -0.01385362, 0.009508114, -0.008691011, -0.023725716, -0.017694006, -0.00977553,
      0.0011996566, 0.018243695, 0.0020947563, 0.016713481, 0.040201496, -0.004055805, -0.0240377,
      -0.028256927, 0.026087888, 0.0050586136, -0.0056343004, 0.009173844, 0.0127765285,
      -0.002741011, -0.013281647, 0.01675805, -0.0028988605, -0.028568912, 0.0067113913,
      0.005508021, 0.017991135, -0.0035581146, -0.015406116, -0.0075359233, -0.014633582,
      -0.007881335, 0.012694818, -0.006287983, -0.013095941, 0.006904525, -0.033545814,
      -0.0024810235, -0.005860861, -0.008743008, -0.03173333, -0.0051254677, -0.00513661,
      -0.026563294, 0.010421785, -0.009671534, 0.012011422, 0.025939323, -0.02154182, -0.0187191,
      0.0032684142, -0.028048936, -0.011008614, -0.0037902463, 0.020145318, 0.022997752,
      -0.0002702013, -0.01451473, 0.039785516, 0.0011903713, 0.010221222, -0.016936328, -0.00499176,
      0.0050623277, -0.011513732, 0.012360549, 0.022314355, -0.0067448183, 0.012234269, 0.02770724,
      0.00030037842, 0.0030585672, 0.041479148, 0.008542446, -0.031168787, -0.018986516,
      0.030054554, -0.006109706, -0.007940761, -0.013831335, -0.02849463, 0.0025441633, 0.004412359,
      0.010830336, 0.00040390916, -0.0006569327, -0.019372782, -0.005797721, -0.03194132,
      -0.04959076, -0.0036193973, 0.015628962, -0.014106179, 0.036903366, 0.03140649, 0.011372596,
      0.025879897, 0.021066416, -0.019892758, -0.009203557, -0.013608488, -0.028227214, 0.000918313,
      -0.010065231, -0.011573157, -0.022433206, 0.0050326153, 0.000823139, 0.0007502496,
      -0.0066779647, -0.01372734, -0.009693819, -0.0141656045, 0.03764619, -0.05095755,
      -0.019655054, 0.005990855, -0.022240072, -0.019001372, -0.011120036, -0.023042321,
      -0.0072053676, -0.02824207, -0.026073031, 0.005110611, -0.021408113, -0.017827714,
      -0.0007168227, -0.0038998125, 0.0059797126, 0.021942945, 0.1707895, 0.00451264, -0.023874281,
      0.030723093, 0.018199125, -0.001905337, 0.027038699, 0.0061542755, -0.018407114,
      -0.00077996246, 0.027944941, 0.0065479707, -0.010057802, 0.00041621213, 0.011862858,
      -0.0049063354, -0.008364169, 0.011796004, -0.0016880616, -0.0005631515, 0.0047057737,
      0.005619444, 0.010473782, -0.0050920406, 0.042786513, -0.0020650434, -0.012107989,
      0.0011058754, -0.0048469095, 0.02724669, -0.013875904, 0.004887765, -0.019447064,
      0.0061542755, 0.005652871, -0.02154182, 0.0052963165, 0.011595442, 0.0056937262, 0.009961235,
      0.021214979, -0.01281367, 0.0083493125, -0.009745817, -0.044153307, 0.017025467, 0.0068079582,
      0.010057802, 0.009114419, 0.0011977996, -0.037289634, 0.019402495, 0.020650435, -0.0036992508,
      -0.00923327, 0.0023417445, 0.0075767785, 0.0053297435, -0.011476591, -0.0064328336,
      0.0067188195, 0.02262634, -0.0011170177, 0.021214979, -0.003298127, 0.03734906, 0.0056937262,
      0.03714107, 0.0134227825, 0.0005872932, 0.020992134, 0.009582397, -0.009500686, 0.012501684,
      -0.042281397, -0.034466915, 0.022388637, 0.041568287, 0.012783957, 0.013021659, -0.0072685075,
      0.018912233, -0.016475778, -0.00050651137, -0.03746791, -0.02849463, 0.004263795, -0.01730774,
      -0.014150748, 0.0070308046, 0.019773906, 0.011959425, -0.019699624, -0.0044866414,
      0.019016229, -0.001059449, 0.010005805, 0.038567286, -0.026503868, -0.0054671657,
      -0.022032084, 0.03981523, 0.006793102, 0.024988512, -0.0008913857, -0.011773719, 0.005099469,
      0.025493631, 0.022953182, -0.010510923, -0.00727965, -0.03298127, -0.00031894894,
      -0.0014930711, 0.009753245, 0.003714107, 0.033456676, 0.0010761626, -0.0033111265,
      -0.0013825763, 0.024156552, 0.0042489385, 0.009693819, 0.009686391, -0.00022098937,
      -0.014863856, -0.024810236, -0.0028877181, -0.0025590197, -0.03892384, 0.0144553045,
      -0.016862046, -0.008104182, -0.0007655703, -0.015272409, 0.025018224, 0.014425592,
      0.0076956297, 0.008854431, 0.0014085751, -0.0036249685, -0.0050549, 0.013549062, -0.024097126,
      -0.000025548994, 0.00060632796, 0.040647186, 0.013972471, -0.044391006, -0.0020260455,
      0.0022377495, -0.006250842, -0.008096754, -0.002676014, 0.009463545, 0.004646348,
      -0.014871284, -0.036427964, -0.016787764, 0.0039778086, -0.024453681, 0.03440749, 0.001438288,
      -0.0036918225, 0.0051923217, -0.017783144, -0.19337127, 0.005686298, -0.00046495977,
      -0.04751086, 0.04180599, 0.014826715, 0.0056083016, 0.017248314, -0.02033845, -0.0077179144,
      0.0043677897, 0.060287386, -0.009404119, -0.009381834, 0.00094802584, 0.0032647,
      -0.0023491727, 0.029504867, 0.007992758, 0.0019981896, 0.043202493, -0.020308737,
      0.0061208485, 0.020160174, -0.005619444, -0.025671909, -0.02558277, 0.027677527, 0.021214979,
      -0.036130834, -0.027751807, -0.0048023406, 0.04584694, 0.014990136, 0.007067946, -0.015985517,
      -0.011922284, -0.023814855, -0.0057754363, 0.01626779, 0.05066042, 0.0086093005, 0.0009860954,
      0.023651434, -0.00492862, 0.008356741, 0.028301496, 0.005437453, 0.0016945613, -0.0091887005,
      0.013029087, -0.002354744, 0.020457301, -0.0029025746, 0.0026815853, 0.006243414,
      -0.004263795, 0.0037883893, -0.0038478149, -0.004924906, -0.027380398, -0.0034485485,
      -0.00067132484, -0.004434644, 0.007346504, -0.009433832, -0.023220597, 0.0031012795,
      -0.0055414476, 0.005901716, 0.009404119, -0.01726317, 0.007435642, 0.0025738762, 0.031495627,
      0.00935955, -0.0118405735, 0.022403494, 0.0026871564, -0.013675342, -0.004690917, 0.028687762,
      -0.02886604, 0.023681147, 0.035417724, 0.007814481, 0.008371597, 0.011788576, 0.0036676808,
      -0.012152558, 0.009270411, -0.010852621, -0.009745817, -0.0116771525, -0.019610485,
      0.013303932, 0.006143133, 0.01805056, 0.010250935, 0.00785905, -0.020145318, -0.010577777,
      -0.020308737, 0.004167228, 0.008364169, 0.02745468, -0.013823907, 0.02507765, 0.013630773,
      -0.02246292, -0.0024067415, 0.0065405425, 0.02745468, 0.01518327, -0.0065368284, 0.0068488135,
      0.030663667, -0.0014317883, -0.0013435782, -0.011030898, 0.04058776, -0.028940322,
      -0.007431928, -0.014381022, 0.00273544, -0.021229835, -0.09353607, -0.017738575, -0.021036703,
      0.018213982, 0.011172034, 0.013036516, 0.008104182, 0.022982895, -0.02957915, 0.034823466,
      -0.0068822405, -0.031465914, -0.0024550247, 0.000037547303, 0.030054554, -0.017382022,
      -0.0019350498, -0.03131735, 0.009300124, 0.03232759, 0.008735579, -0.013296504, 0.024141695,
      -0.0047689136, 0.009552684, -0.009106991, -0.025775904, 0.029920846, -0.0005951857,
      0.004226654, 0.022017227, 0.0166392, 0.015688388, -0.0022804618, -0.017901996, -0.018704243,
      -0.030663667, -0.016252933, 0.01310337, -0.037794754, 0.00451264, -0.0023120316, -0.008683583,
      0.0017372735, 0.01172915, -0.037200496, -0.0155546805, 0.011238888, 0.029935703, -0.024676528,
      -0.00071867974, -0.030515105, -0.016475778, -0.016416354, 0.036368538, 0.011959425,
      0.016698625, 0.02096242, -0.020917851, 0.0067893877, -0.034882892, 0.0024364542, -0.019417351,
      0.03339725, 0.014834143, -0.011016042, -0.012657677, 0.013081085, -0.00063325523,
      -0.035001744, -0.024364542, 0.026682146, -0.023517726, -0.0040409486, -0.03173333,
      0.0013212936, -0.017798001, -0.0014327168, 0.039666664, -0.010577777, -0.021972658,
      -0.019224219, 0.008728151, -0.01297709, 0.03283271, 0.027469536, 0.009560112, 0.0074802116,
      0.012821098, -0.005864575, 0.009716104, 0.040766038, 0.0011578728, -0.023799999,
      -0.0074802116, 0.032476153, -0.02292347, -0.0064551183, 0.024721097, 0.0058720033,
      -0.020234454, -0.0107411975, -0.05618701, 0.015614106, 0.0075173527, 0.010763482,
      -0.027469536, 0.008193321, 0.0012200841, -0.02267091, -0.024171408, 0.00291186, -0.0066408236,
      0.028702619, -0.0056937262, -0.031971034, -0.031852182, -0.010035518, 0.025894754,
      -0.0028208643, 0.042162545, 0.02216579, -0.021393256, 0.0034856894, -0.001422503,
      0.0072016534, -0.010993757, -0.000055624558, 0.0001750273, 0.05241348, -0.013883332,
      -0.018005991, 0.0029712857, -0.01805056, 0.006525686, 0.00020381162, -0.006585112,
      -0.027959798, -0.016728338, 0.016312359, 0.049917597, -0.005021473, -0.028598625,
      -0.028063793, -0.013274219, -0.040558048, -0.009092134, 0.005827434, -0.022433206,
      -0.00175213, 0.0074245, 0.012412546, -0.0017976278, -0.0007089302, 0.011521161, -0.025196502,
      0.0030102837, -0.03006941, 0.0062991255, 0.031287637, 0.0029712857, 0.013638201, 0.008341884,
      0.019105367, 0.0050808983, -0.005340886, 0.0089658545, 0.02267091, -0.0037401058, 0.032387014,
      0.008208177, -0.014418163, -0.029356303, -0.007532209, 0.020100748, 0.029995129,
      -0.0070865164, 0.011588014, 0.020160174, -0.007703058, -0.008772721, 0.004196941,
      -0.003814388, -0.01897166, -0.01917965, 0.005953714, -0.0054931645, 0.0030622813, 0.012932521,
      0.013214793, -0.015146129, 0.022106364, -0.001073377, 0.014841571, -0.0063251243, 0.028138075,
      0.00077810546, 0.018511109, -0.0021968943, -0.0021838949, 0.021942945, 0.0054300246,
      0.034377776, 0.008163608, -0.03794332, -0.013222221, -0.01601523, 0.023889137, -0.004271223,
      -0.029594006, 0.008497877, -0.0046686325, 0.008639013, -0.0033556956, -0.0058460045,
      0.01464101, 0.017188888, 0.021928089, -0.02188352, 0.00499176, -0.026756428, 0.015985517,
      0.019060798, -0.020130461, -0.0040298062, 0.03041111, 0.040112358, 0.00977553, 0.011810861,
      -0.042637948, 0.025047937, -0.02699413, 0.028598625, -0.021675529, -0.032565292, -0.013385642,
      -0.000088616274, 0.011714294, 0.0075693503, 0.011796004, -0.012828526, 0.07208339,
      -0.0005924001, -0.011662296, 0.009812671, -0.0076882015, 0.019491633, -0.003086423,
      0.0058757174, -0.012635392, -0.029772282, -0.0024903088, -0.0012878666, 0.0006044709,
      -0.019640198, -0.028643195, -0.0051551806, 0.022254929, 0.024052557, 0.00035632215,
      0.00865387, 0.0026388732, 0.001020451, 0.029014604, 0.020308737, -0.033842944, -0.01048121,
      0.018035704, -0.0149084255, 0.0006411477, -0.033218972, -0.006068851, 0.0028877181,
      -0.013140511, -0.0022024654, 0.0026444444, -0.023057176, 0.015792383, -0.038210735,
      -0.017619723, -0.010496067, -0.010005805, 0.012204556, 0.0008082826, -0.006169132,
      0.003160705, -0.0005631515, 0.003325983, -0.0059091444, 0.007532209,
    ],
  },
  "predictnextyearpsatbyethnicityandhighlighttop/bottom": {
    id: 4,
    data: {
      isDemo: "HIGHLIGHT",
      ethnicityScores: {
        White: [925, 930, 935, 935, 940, 940, 940, 945, 945],
        Black: [910, 910, 915, 915, 915, 920, 920, 920, 930],
        Hispanic: [915, 915, 925, 925, 928, 930, 930, 935, 938],
        Asian: [930, 930, 940, 940, 945, 945, 945, 950, 950],
        "Pacific Islander": [910, 915, 915, 920, 920, 920, 925, 925, 930],
        "American Indian/Alaska Native": [915, 920, 920, 925, 925, 925, 925, 930, 935],
        "Two or More Races": [920, 925, 930, 930, 935, 935, 940, 940, 945],
        Average: [922, 925, 929, 931, 932, 934, 935, 937, 940],
      },

      headers: [
        "School",
        "Average",
        "White",
        "Black",
        "Hispanic",
        "Asian",
        "Pacific Islander",
        "American Indian/Alaska Native",
        "Two or More Races",
      ],
      rows: [
        {
          School: "Hamilton",
          Average: 931,
          White: 935,
          Black: 915,
          Hispanic: 928,
          Asian: 940,
          "Pacific Islander": 920,
          "American Indian/Alaska Native": 925,
          "Two or More Races": 930,
        },
        {
          School: "Cherry Creek",
          Average: 932,
          White: 940,
          Black: 920,
          Hispanic: 925,
          Asian: 945,
          "Pacific Islander": 920,
          "American Indian/Alaska Native": 920,
          "Two or More Races": 935,
        },
        {
          School: "Ballard",
          Average: 922,
          White: 925,
          Black: 910,
          Hispanic: 915,
          Asian: 930,
          "Pacific Islander": 910,
          "American Indian/Alaska Native": 915,
          "Two or More Races": 920,
        },
        {
          School: "Wallingford",
          Average: 940,
          White: 945,
          Black: 930,
          Hispanic: 938,
          Asian: 950,
          "Pacific Islander": 930,
          "American Indian/Alaska Native": 935,
          "Two or More Races": 945,
        },
        {
          School: "Fremont Hill",
          Average: 935,
          White: 940,
          Black: 920,
          Hispanic: 930,
          Asian: 945,
          "Pacific Islander": 925,
          "American Indian/Alaska Native": 925,
          "Two or More Races": 940,
        },
        {
          School: "West Woodland",
          Average: 925,
          White: 930,
          Black: 910,
          Hispanic: 915,
          Asian: 930,
          "Pacific Islander": 915,
          "American Indian/Alaska Native": 920,
          "Two or More Races": 925,
        },
        {
          School: "Blue Ridge",
          Average: 937,
          White: 945,
          Black: 920,
          Hispanic: 935,
          Asian: 950,
          "Pacific Islander": 925,
          "American Indian/Alaska Native": 930,
          "Two or More Races": 940,
        },
        {
          School: "Kenmore Lake",
          Average: 929,
          White: 935,
          Black: 915,
          Hispanic: 925,
          Asian: 940,
          "Pacific Islander": 915,
          "American Indian/Alaska Native": 925,
          "Two or More Races": 930,
        },
        {
          School: "East Renton",
          Average: 934,
          White: 940,
          Black: 915,
          Hispanic: 930,
          Asian: 945,
          "Pacific Islander": 920,
          "American Indian/Alaska Native": 925,
          "Two or More Races": 935,
        },
      ],
    },
    isFavorite: [],
    title: "Trend of average PSAT by school for last 5 years in table format",
    visualisation: TABLE,
    secondAnswer:
      "The returned results show the average PSAT scores broken down by ethnicity for the year 2023. Individual cells are highlighted with green to indicate top scores for the school and red for bottom scores for the school. This data is useful to determine performance patterns amongst your students.",
    embedding: [
      -0.022777036, 0.0072389403, 0.0012296018, -0.012982348, -0.021864269, 0.026624696,
      -0.034769382, -0.02258044, -0.050525136, -0.017370649, 0.02724257, 0.015180009, -0.0141478805,
      0.019912353, 0.0035773425, -0.00050465454, 0.026077038, -0.026568526, -0.0032648956,
      0.020642566, -0.010735539, 0.03277534, -0.0044269175, 0.00022424216, 0.0009548942,
      -0.0005178195, 0.013944264, -0.022397885, -0.0099000055, 0.0032877147, 0.0028365974,
      -0.013052561, -0.017679585, -0.028183421, -0.025417037, 0.00069422915, -0.004553301,
      -0.022102993, 0.013228093, -0.012961284, -0.015264264, -0.0014920222, -0.005767982,
      -0.0054309606, 0.009984261, -0.006873834, -0.02061448, -0.026737038, -0.019814055,
      0.025585547, 0.007884898, 0.0064595784, -0.00039538587, -0.029236613, 0.00044212127,
      -0.0026224484, 0.0071336213, 0.0021695758, -0.02960172, -0.016092775, 0.021555332,
      -0.0019378735, -0.0224681, 0.034853637, -0.020403842, -0.01687916, -0.012322348, 0.021737885,
      0.011550007, -0.0058206418, 0.037297044, 0.013874051, -0.018437883, -0.008966176, 0.024855334,
      -0.0059786206, -0.025725974, -0.0090434095, 0.011971284, 0.0051150033, 0.03651066,
      -0.0141197955, -0.031651933, 0.004462024, 0.035022147, 0.01238554, -0.021723842, 0.02231363,
      -0.019814055, -0.015601286, 0.012048518, 0.010405539, 0.030837465, -0.006227876, -0.029292783,
      0.01638767, -0.028478315, 0.019505119, 0.03224172, -0.04802556, 0.0036791512, -0.010700432,
      0.016654478, -0.021976609, -0.021372778, -0.023563419, -0.017581288, 0.012575114, 0.005164152,
      0.007898941, -0.00773043, 0.031820446, 0.018353628, -0.023282567, 0.0027593633, -0.01908384,
      -0.0034772893, 0.007203834, -0.021990651, 0.0037212789, 0.018957458, 0.028576612, 0.016345542,
      -0.044851944, 0.028267676, 0.012104688, -0.0062629827, -0.034067255, -0.022482142,
      0.0048271306, 0.0015016764, -0.0189715, -0.0022275012, 0.008608091, -0.010201921, 0.020684693,
      -0.020038735, -0.025922569, -0.034937892, -0.020544268, 0.015559158, 0.017440861,
      0.0052378755, -0.0074425577, 0.0036615978, 0.03387066, 0.02993874, 0.02297363, -0.025529377,
      0.010925113, 0.00022391304, 0.000012211817, 0.0071125575, 0.031680018, -0.021091927,
      0.004742875, 0.008039366, 0.0024907994, -0.011002347, -0.023380864, -0.0251081, -0.020319587,
      0.0019694692, -0.009963198, 0.0176515, 0.035050236, -0.0035176617, -0.008285112, 0.013501923,
      -0.0109040495, 0.0026733526, 0.005771493, -0.03696002, 0.010089581, 0.014941285, 0.014772775,
      -0.0042724493, -0.008502771, -0.017328521, -0.0005691626, -0.003075321, 0.0020870757,
      0.0251081, 0.034235764, -0.0103423465, -0.01683703, 0.014758732, 0.013726604, -0.015306393,
      0.00822192, 0.010510857, 0.011887029, -0.00069027964, 0.006182238, -0.6394421, -0.043644283,
      0.013361498, -0.023675758, 0.02133065, 0.0014340966, 0.014618306, -0.0050447905, -0.02833789,
      0.033252787, 0.017890224, 0.011753624, 0.004430428, -0.024855334, -0.006561387, -0.02258044,
      -0.0024644695, -0.0067228763, 0.025248526, -0.0042408537, -0.018227246, 0.027593633,
      -0.016963415, 0.042015344, 0.008046388, 0.0023538845, 0.028576612, -0.00427596, -0.01627533,
      -0.0106372405, -0.02225746, 0.0029770231, -0.020600438, 0.023605546, 0.051227264,
      -0.0053537264, -0.018943416, 0.037634064, 0.018171076, 0.039038323, -0.019800011,
      0.0073231957, 0.01474469, 0.02516427, 0.004637556, 0.031343, -0.015854051, -0.014042562,
      0.005006173, -0.03726896, -0.00075039937, 0.019378735, 0.002259097, 0.005624046, 0.016092775,
      -0.004795535, 0.005497663, -0.014674477, 0.0012094156, -0.0073863873, -0.006463089,
      0.017988522, 0.016556181, -0.01908384, -0.03086555, 0.01546086, 0.0048868116, -0.0018167564,
      0.021611502, -0.031343, -0.006161174, 0.032382146, 0.0062419185, 0.010925113, 0.035555765,
      -0.009794687, 0.015053626, -0.013881072, -0.011880008, 0.0081306435, 0.006638621,
      -0.0126734115, -0.004170641, -0.001340187, 0.008615112, -0.021751927, -0.031567678,
      0.015109796, 0.0022345227, 0.010349368, 0.001244522, 0.0061752163, -0.0011488571, -0.01282788,
      0.013094689, -0.006199791, -0.021372778, -0.018845119, 0.024686823, -0.023703843,
      -0.006270004, -0.009829793, 0.014463838, 0.0019168097, 0.020628523, -0.0026610654,
      -0.0003142023, -0.0035738319, 0.01880299, -0.035499595, 0.017876182, -0.03224172,
      -0.017131925, -0.01129022, 0.012132773, -0.031483423, 0.01650001, -0.019013628, -0.009204899,
      -0.0020572352, 0.0039494704, 0.011627241, 0.004012662, -0.03432002, 0.007231919, 0.012406603,
      0.018648522, -0.012624263, -0.01386703, -0.0017913043, -0.028380018, -0.009225963,
      0.029966827, -0.00959107, 0.021625545, -0.010819794, 0.0035123958, 0.012926178, -0.008200856,
      -0.032691084, -0.0047990456, 0.0031420232, 0.0079831965, 0.008081494, -0.023324694,
      -0.023324694, -0.030051082, 0.004960535, 0.0071722385, 0.00031617706, -0.0032104806,
      -0.003974045, -0.013754689, -0.0020151075, 0.021457035, -0.01162022, 0.02565576, -0.00477096,
      -0.01678086, -0.0142953275, 0.0045919176, 0.014730647, -0.016303414, -0.0083061755,
      0.01825533, 0.0027453208, -0.01370554, 0.025613632, 0.003222768, -0.024068952, -0.0020361715,
      -0.009225963, 0.014140859, 0.007109047, -0.014576179, 0.010391495, -0.007154685,
      0.00072670256, 0.0006319153, -0.00581011, 0.008018303, 0.022299588, 0.0012296018, -0.01534852,
      0.018929373, 0.008938091, 0.0096051125, 0.0077163875, -0.004423407, 0.021302566, -0.01995448,
      0.010279155, -0.014049583, 0.010173836, 0.0030015975, 0.014133838, 0.015208094, 0.025417037,
      -0.00205548, 0.0029629804, 0.027691932, 0.0077585154, 0.015573201, -0.0033175552, 0.015376605,
      -0.039515767, 0.0023222887, -0.026652781, -0.016485967, 0.012125752, 0.020080863,
      -0.053108968, -0.016148945, -0.0021555333, -0.008762559, 0.019800011, -0.010967241,
      0.008516814, -0.026765123, -0.016359584, -0.008467665, -0.018494053, 0.010293198,
      0.0065824506, 0.030275762, 0.0010180857, 0.004402343, 0.015685542, 0.025515335, -0.032045126,
      -0.011781709, 0.025402995, 0.03519066, 0.0072880895, 0.023310652, -0.022229375, 0.011227028,
      -0.0027593633, 0.013768732, 0.0008412372, -0.004237343, 0.008207877, -0.0026522889,
      0.0093102185, 0.0024153206, -0.022819163, 0.015432775, 0.029714061, -0.023085972, 0.017805967,
      -0.0060488335, 0.027382996, -0.022538312, -0.0018834586, 0.023535334, -0.008488729,
      0.0076110684, 0.0067228763, 0.0106372405, 0.028787252, 0.011086603, 0.009619155, 0.020474056,
      -0.0031227146, 0.012848944, -0.023858313, -0.010953198, -0.0016570223, -0.019771926,
      -0.0077866004, -0.0061085145, -0.0012647082, -0.0007087105, -0.00042215452, -0.024434058,
      -0.02067065, -0.008095536, 0.0050904285, 0.018859161, -0.0031665976, -0.019533204,
      -0.009949155, 0.005322131, 0.04170641, -0.017777883, -0.029966827, -0.004686705, -0.006733408,
      -0.022046821, -0.009907028, 0.0020467034, 0.007807664, 0.0148289455, -0.013256178,
      -0.024307674, -0.0035808533, 0.016106818, 0.018381713, 0.016233202, 0.019631501, 0.004746386,
      0.02701789, -0.031988956, -0.0072951107, 0.030781295, -0.00041513323, 0.0031034062,
      -0.019968523, -0.0027435655, -0.015488946, 0.0042724493, -0.012455752, -0.014337456,
      0.0026996825, 0.0047920244, -0.024869377, 0.0033351085, -0.016949372, 0.03196087, 0.005574897,
      -0.01381788, -0.010538942, -0.02258044, 0.030022997, 0.06150642, 0.015418733, 0.0094576655,
      0.015727669, 0.005641599, 0.006168195, -0.014997456, -0.006814153, -0.002231012, -0.023816185,
      0.00019319494, -0.004830641, 0.0050307475, -0.021527248, 0.037774492, -0.0010101867,
      0.0015376605, -0.016050648, -0.005269471, 0.0047990456, -0.017174052, -0.019013628,
      0.018058734, 0.0034246298, 0.019589374, 0.019252352, 0.024420016, 0.011002347, 0.0035053745,
      -0.009275112, -0.031708103, 0.021780014, 0.010616177, 0.025065973, -0.017230224, 0.032691084,
      0.026385974, -0.007449579, 0.02554342, 0.00092242076, 0.017834052, -0.01666852, 0.008629154,
      -0.013256178, 0.00068720785, 0.0015789105, 0.009506814, 0.021934481, -0.002225746,
      -0.028632782, 0.013768732, 0.0027312783, -0.030303849, 0.00066702167, -0.012413625,
      0.010426602, 0.0021555333, -0.005346705, -0.0103704315, -0.026245547, -0.036117468,
      -0.017328521, 0.014463838, -0.0025803207, -0.028899591, -0.00026944166, -0.029405124,
      -0.019617459, -0.018353628, -0.009240005, -0.02061448, -0.023408951, -0.0135932,
      0.00014327801, 0.0040337257, 0.0061646844, 0.01644384, 0.015980436, 0.0041390453, 0.008460644,
      -0.0071827704, -0.013242136, 0.020867247, -0.0183115, -0.001918565, 0.005792557, 0.013143837,
      -0.019448947, -0.017749798, 0.004735854, 0.000044157274, -0.018887246, -0.0005125535,
      0.01008256, -0.0025030866, 0.0056100036, 0.012104688, 0.01753916, -0.009548942, -0.001600852,
      0.008446601, -0.030556614, -0.009478729, 0.0023310652, 0.033842575, 0.035387255, -0.004462024,
      -0.013522987, -0.016373627, -0.005652131, 0.03196087, -0.023998737, -0.0044865985,
      0.0035492575, 0.012195965, 0.002390746, -0.0015078201, 0.025768101, -0.029461294,
      -0.013831924, -0.00062138337, -0.027214484, 0.023282567, 0.015151924, -0.014632349,
      0.025501292, -0.01995448, -0.0103704315, 0.0042443643, -0.009513835, -0.027551506,
      0.019757884, -0.005845216, 0.0060839397, -0.004040747, -0.013228093, -0.006238408,
      -0.0051114922, 0.0055117053, 0.0037177682, -0.0022836716, -0.021190226, -0.00345447,
      -0.012511922, 0.03552768, -0.022496184, -0.006238408, -0.007877877, -0.0068001105,
      0.0015323946, -0.010791709, 0.0031806403, -0.031230656, -0.013740647, 0.001685985,
      -0.034572788, -0.027172357, -0.0038055342, 0.021723842, 0.028028954, 0.024742994,
      0.0042619174, -0.004851705, 0.019069798, -0.0053537264, 0.019687671, -0.009829793,
      -0.00931724, -0.019575331, 0.009218941, 0.004542769, 0.008636176, -0.00047305878,
      -0.009871921, 0.002044948, 0.0046621305, 0.0020467034, -0.016485967, -0.024054907,
      -0.029461294, -0.0068106423, 0.021878311, -0.01621916, 0.0244481, -0.017061712, -0.0051325564,
      0.0038441513, 0.008741494, 0.012708519, 0.0038336194, 0.019266395, -0.038504705, 0.036594916,
      -0.022060864, -0.014274264, -0.01617703, -0.02291746, -0.0036300023, -0.0003488699,
      0.011753624, 0.0051009604, 0.04611577, 0.01825533, 0.012069582, -0.0076602176, 0.01791831,
      -0.009829793, -0.017131925, -0.0008719553, -0.012392561, 0.028464273, -0.006424472,
      -0.012848944, -0.0067404294, 0.020853205, 0.0045462796, -0.05066556, 0.021218311, -0.02461661,
      -0.009197878, 0.009422559, -0.021246396, 0.02390044, -0.0017184585, 0.009822772, 0.029405124,
      0.0115219215, -0.033786405, 0.0068492596, -0.0018378203, -0.0012041497, 0.011613199,
      0.017384691, 0.012806816, -0.0032719169, 0.025023844, -0.010152772, -0.015615328,
      -0.022777036, 0.0244481, -0.025768101, 0.011837879, -0.020319587, 0.014000434, -0.01238554,
      0.030247677, -0.006793089, -0.0018237777, -0.012420646, -0.012680433, -0.01343171,
      0.026666824, 0.0019168097, -0.0018167564, 0.0013507189, 0.014000434, -0.0001927561,
      -0.0073934086, 0.0016245488, -0.0043215984, -0.006255961, 0.037465554, -0.0028629273,
      0.0074425577, 0.007021281, -0.0000342836, 0.021864269, 0.013200008, -0.009176814, 0.036594916,
      -0.034291934, -0.014758732, 0.00058188866, 0.021274481, 0.013572136, 0.023858313,
      -0.009935113, 0.0007942824, -0.01621916, -0.0038617044, 0.019575331, -0.0033157999,
      -0.024700865, 0.0047112796, -0.01496937, 0.0014393625, -0.023422994, -0.0010452932,
      0.0010382719, -0.023479164, -0.0037037258, 0.015208094, 0.010763624, 0.02691959, -0.004553301,
      -0.00449362, -0.00022292566, -0.0022959588, -0.018128946, 0.0223698, 0.0050237263,
      0.0048692585, -0.021162141, 0.026652781, -0.025009802, -0.02012299, 0.019800011, -0.013052561,
      0.0031753744, -0.0068281954, 0.0009390963, 0.016373627, 0.022074906, -0.004082875,
      -0.0045252154, 0.037634064, 0.0026786185, -0.020712778, -0.011430645, 0.022510227,
      0.0074074515, -0.009907028, 0.0057609607, 0.008783623, 0.038560875, -0.047126837, 0.009815751,
      0.022889376, -0.024279589, -0.0034088318, -0.009864899, 0.05389535, -0.013108731,
      -0.008095536, -0.002489044, 0.013171922, -0.010967241, 0.023661716, -0.0023328206,
      -0.014674477, 0.01397937, 0.017805967, 0.0047885138, 0.018873204, -0.014885115, -0.015151924,
      0.014772775, -0.0032877147, -0.0049500028, -0.0016973946, 0.0029401614, 0.03962811,
      0.045132793, -0.035331085, -0.0065262807, -0.012062561, -0.04353194, -0.019814055,
      -0.0081306435, 0.034909807, 0.014927243, -0.0039319173, -0.0017684851, 0.00039384997,
      0.006940536, 0.0052729817, -0.020305544, -0.019252352, 0.008179792, -0.011079581,
      -0.015306393, 0.009850857, -0.026470229, 0.0043918113, 0.040161725, -0.0061541526,
      -0.002050214, 0.03864513, 0.000083158295, 0.0055117053, -0.012806816, 0.013284263,
      0.0025452143, -0.01704767, 0.010588092, -0.047548115, 0.015966393, 0.013389583, -0.023282567,
      0.00034250686, -0.021021714, 0.009991283, -0.01046873, 0.00058320514, -0.015980436,
      -0.007498728, -0.010833836, 0.009240005, 0.021695757, 0.0069475574, -0.006698302, 0.03207321,
      0.018985543, -0.012238093, -0.010272134, 0.007109047, -0.019687671, -0.010861921,
      0.0014937775, -0.0100474525, 0.010567028, -0.010707453, -0.007038834, -0.019856183,
      0.00076224783, -0.021232354, -0.037521724, 0.0001357082, 0.021288523, 0.02948938, -0.01474469,
      -0.004577875, -0.0077093663, -0.014190009, -0.03985279, -0.007597026, 0.004742875,
      -0.04024598, -0.01836767, -0.01902767, -0.01074256, -0.03179236, 0.004960535, 0.006863302,
      0.0050728754, 0.04170641, 0.19547246, -0.0074706427, 0.007905962, 0.038729385, 0.013017455,
      -0.00405479, 0.005982131, 0.021274481, 0.010588092, -0.008179792, -0.035022147, 0.013326391,
      -0.0061752163, -0.011943199, 0.009204899, -0.015587243, -0.021611502, 0.0091908565,
      -0.032213636, -0.008516814, -0.0024907994, -0.009731495, 0.008502771, -0.013438731,
      0.0035984064, -0.0004743753, 0.0022239906, -0.0060418122, 0.029714061, 0.0039284066,
      -0.002538193, 0.011381497, 0.007087983, 0.0049359603, -0.020137034, -0.0010891762,
      0.010265113, -0.011170858, 0.028702997, 0.0036826618, -0.008601069, -0.0030419698,
      0.022117035, -0.03184853, -0.021414906, 0.016429797, -0.0052308543, -0.00822192, 0.015910223,
      -0.0029103209, -0.024139164, -0.010651283, 0.048727687, -0.0065368125, -0.022594482,
      0.02016512, 0.019603416, 0.0076883025, -0.020839162, 0.012413625, 0.0132631995, 0.043728538,
      -0.011065539, 0.020305544, 0.011957241, 0.0109040495, -0.007667239, -0.03086555, 0.02384427,
      -0.0036651087, 0.00888192, -0.0047007473, -0.0013586178, 0.030331934, -0.020038735,
      -0.03392683, 0.038897894, 0.033168532, 0.006512238, 0.04462726, 0.004353194, -0.008418515,
      -0.01074256, -0.024799164, -0.027200442, -0.04055492, 0.001118139, -0.012891072, -0.05574897,
      -0.0050447905, 0.0011032188, 0.011297241, -0.008902984, -0.00069598446, 0.02225746,
      0.006403408, 0.014344477, 0.023043843, -0.022411928, 0.005880323, -0.017019585, 0.042352367,
      0.014758732, 0.0076812813, 0.0012927933, -0.01595235, 0.0038090448, 0.0034614915,
      0.0043286197, -0.0048201093, 0.008874899, -0.03715662, -0.00020668896, -0.010897028,
      -0.014204051, 0.008186813, 0.023675758, -0.036707256, 0.0023345759, -0.0016561446,
      -0.010005325, -0.03864513, 0.0069651105, 0.02937704, -0.021414906, -0.0080112815,
      -0.011739582, 0.007077451, -0.0115219215, -0.020474056, -0.0014419955, -0.023394907,
      -0.008348303, -0.010897028, -0.026105123, 0.0056872373, -0.011255113, -0.023114057,
      -0.009675325, 0.011732561, 0.038841724, -0.00054590456, 0.0063718124, 0.021681715,
      -0.008046388, -0.00926107, 0.020853205, 0.008179792, -0.015123839, -0.037802577, -0.039150663,
      -0.0095770275, -0.0050834073, 0.013600221, 0.029405124, -0.013607242, -0.027762145,
      -0.037577894, -0.007484685, 0.008228941, -0.020137034, 0.01776384, -0.000003802051,
      -0.008313197, -0.011198943, 0.006814153, -0.18199159, 0.0078638345, 0.031146402, -0.05869791,
      -0.0046445774, 0.0059891525, 0.038617045, 0.01468852, 0.00083597127, -0.018241288,
      0.011430645, 0.021569375, -0.040049385, 0.009415538, -0.0062945783, -0.0090153245,
      -0.0117887305, 0.040302154, 0.011142773, 0.0003899005, 0.037577894, -0.024799164, 0.019336607,
      0.01914001, 0.014175966, -0.0024100547, -0.011872986, 0.024167249, 0.012638305, -0.01140256,
      -0.005388833, -0.0017588309, 0.005269471, 0.0016640435, -0.022959588, 0.0007394286,
      -0.013221072, -0.005494152, -0.015783839, 0.01687916, 0.035218745, -0.017089797, -0.017216181,
      0.013038519, 0.01683703, 0.014140859, 0.008959155, -0.023437036, 0.01546086, 0.0032684063,
      0.008362345, -0.013256178, 0.03403917, -0.007870856, 0.028983848, 0.011304262, -0.009562984,
      -0.0025978738, 0.022397885, 0.009829793, -0.019364692, -0.011395539, 0.012188943,
      0.0023433524, -0.026961718, -0.014758732, -0.004430428, -0.0051150033, -0.0073653236,
      0.005146599, -0.011479794, 0.014997456, 0.005093939, -0.017300436, 0.018227246, -0.004265428,
      -0.023268525, 0.007449579, 0.0036300023, 0.02614725, -0.024532355, 0.04156598, -0.017876182,
      0.030949807, 0.0148289455, 0.013789795, 0.0088959625, -0.008678303, -0.0037142576,
      0.0036791512, 0.00067843124, -0.01842384, 0.0067966, 0.0017605862, -0.0011497347, 0.007066919,
      -0.027762145, 0.010581071, 0.008292133, -0.011472773, -0.01540469, -0.019154055,
      -0.0048095775, 0.002215214, 0.026568526, 0.0072880895, -0.0015929531, 0.024785122, 0.04538556,
      -0.0029120762, -0.023984695, -0.020684693, 0.01261022, 0.012532987, 0.0038722365, 0.031876616,
      -0.013986391, -0.010686389, 0.009225963, 0.034291934, 0.019575331, -0.013200008, 0.0016736978,
      0.030331934, 0.0129683055, -0.020403842, -0.09824176, -0.014674477, 0.022397885, 0.025346823,
      0.011262135, 0.0076602176, 0.00018419891, 0.017805967, -0.042745557, 0.057855353, 0.032747254,
      -0.02614725, -0.0033386191, -0.011262135, 0.02280512, -0.013312348, 0.011283198, -0.019491076,
      0.015769796, 0.0224681, 0.004704258, -0.02599278, 0.0022134588, -0.0028646826, -0.012975327,
      -0.008067451, -0.030837465, 0.01320703, 0.015025541, -0.012392561, -0.0067895786,
      -0.0067404294, 0.024700865, -0.025585547, -0.005037769, -0.0045041516, -0.03799917,
      -0.03128683, 0.006736919, -0.003524683, -0.007997239, -0.018437883, 0.0060839397,
      -0.016514052, -0.020572353, -0.029236613, -0.022791078, -0.0005704791, 0.024223419,
      -0.03328087, -0.008383409, -0.00082675583, -0.027621718, -0.02033363, 0.0054028756,
      -0.009394473, 0.0005156253, 0.014618306, -0.00007734379, -0.0128208585, -0.0042162794,
      -0.0041811727, 0.0030226614, 0.009064473, 0.035303, 0.01381788, -0.019322565, -0.019701714,
      0.00564511, -0.010454687, -0.006726387, 0.043335345, -0.011191921, 0.03350555, -0.015966393,
      0.0039459597, -0.015994478, -0.009513835, 0.015222137, -0.032101296, -0.020403842,
      -0.021218311, -0.00069905625, -0.02154129, 0.012919157, -0.026554484, -0.004735854,
      -0.00438479, 0.0072670258, -0.0007170483, 0.019743841, 0.03968428, 0.00964724, 0.0077374517,
      0.009085538, 0.013916179, 0.010819794, -0.009176814, 0.016289372, 0.003140268, -0.02960172,
      -0.009064473, -0.06257366, 0.021990651, 0.020305544, 0.0019010118, -0.0035878746,
      -0.0012708518, 0.004665641, -0.015713627, -0.0052659605, 0.0118449, -0.026385974, 0.004412875,
      -0.01255405, -0.024981717, 0.0007275802, -0.0027382995, 0.026400017, -0.017272351, 0.03300002,
      0.010588092, -0.020586396, 0.00066702167, 0.018086819, -0.00570128, -0.02522044, 0.0038125555,
      -0.017426819, 0.02856257, -0.0022432993, -0.017370649, 0.03799917, -0.022004694,
      -0.0030138849, 0.019561289, -0.0001869416, -0.035555765, 0.012476816, 0.0008188569, 0.033983,
      0.0025311718, -0.011304262, -0.014885115, -0.0010672347, -0.017553203, -0.014449796,
      0.026119165, -0.018395755, 0.0025890972, -0.01468852, -0.01546086, 0.04221194, 0.01589618,
      0.0176515, -0.012589157, -0.004307556, -0.032971933, -0.0013436977, 0.011823837, -0.007196813,
      -0.017342564, 0.022032779, 0.022833206, 0.008271069, -0.014716605, -0.016457882, 0.007667239,
      -0.00926107, -0.001735134, 0.0007236308, -0.01107256, -0.020488098, 0.0058733015, 0.017890224,
      0.046621304, 0.020530226, -0.0028207996, -0.02027746, 0.017412776, 0.007856813, 0.012813837,
      0.008411494, 0.017328521, -0.0057083014, 0.03651066, -0.0007837505, 0.003113938, -0.032382146,
      -0.00065648975, -0.02543108, 0.0107846875, 0.008074473, 0.010595113, -0.0034351617,
      0.017468946, -0.00010164401, -0.008116601, 0.015264264, -0.012701497, 0.020474056,
      0.040751513, -0.004725322, -0.008558941, -0.0029173421, -0.021737885, -0.0123785185,
      0.017960437, -0.0298264, -0.014281285, -0.001727235, -0.012799795, 0.011339368, -0.01134639,
      -0.008151707, 0.013319369, -0.013600221, 0.018845119, -0.03196087, -0.008292133,
      -0.0057188333, 0.027888527, 0.012406603, 0.006944047, -0.00073196855, -0.0015859319,
      0.031034062, 0.009001282, 0.023507249, -0.012778731, 0.027102144, 0.006768515, -0.009766602,
      -0.0011936177, -0.001079522, -0.011690432, -0.013838945, 0.0027769166, 0.0049148966,
      -0.0005972477, -0.0006284046, 0.069426425, 0.00931724, -0.012455752, 0.007898941, 0.011093624,
      0.01606469, -0.004830641, 0.030079167, -0.02373193, -0.015587243, 0.020052778, -0.025234483,
      -0.0034772893, -0.024841292, 0.014337456, -0.0045743645, -0.008692346, 0.011795752,
      -0.002943672, -0.0050342586, 0.0217098, -0.019069798, 0.04555407, 0.022819163, -0.012188943,
      -0.007955112, 0.009296176, -0.0023644164, 0.013494901, -0.027045975, -0.0096051125,
      -0.0025417036, -0.029461294, -0.010791709, -0.011304262, -0.028239591, -0.010328304,
      -0.012799795, 0.028759167, -0.0038687258, 0.015320435, 0.010875964, -0.017230224,
      0.0021941504, -0.000937341, -0.013445753, -0.005799578, -0.006638621, -0.037465554,
    ],
  },
  showmethestudentsthatareatriskofnotgraduating: {
    id: 5,
    data: {
      isDemo: true,
      rows: [
        { type: "John Smith", value: 50 },
        { type: "Emily Johnson", value: 20 },
        { type: "Michael Brown", value: 50 },
        { type: "Sarah Davis", value: 20 },
        { type: "James Miller", value: 45 },
        { type: "Jessica Wilson", value: 25 },
        { type: "David Anderson", value: 45 },
        { type: "Ashley Thomas", value: 25 },
        { type: "Robert Taylor", value: 55 },
        { type: "Jennifer Jackson", value: 50 },
        { type: "Michelle Johnson", value: 45 },
        { type: "Daniel Williams", value: 20 },
        { type: "Emma Brown", value: 20 },
        { type: "Joshua Davis", value: 30 },
        { type: "Amanda Miller", value: 20 },
      ],
    },
    isFavorite: [],
    title: "Risk of Not Graduating for 10 Students",
    secondAnswer:
      "The chart represents each student's risk of not graduating, based on factors such as GPA, attendance, total credits, and completion of the ethics requirement. Each factor is scored using specific criteria, and these scores are combined to create a total risk score between 0 and 100. Higher scores indicate a higher risk of not graduating. A score of 20 is given for failing in GPA, attendance, or total credits, with an additional 5 points if the student is within 10% of the failing criteria. For the ethics requirement, 25 points are given if it's marked as 'Incomplete'. This scoring system identifies students at risk and reveals the contributing factors, enabling targeted interventions.",
    visualisation: BAR_CHART,
    embedding: [
      -0.017698621, -0.019366274, 0.0016004073, -0.033837184, -0.008149973, 0.007134589,
      -0.025297195, 0.0070404476, -0.028242482, -0.001326388, 0.022593986, -0.0048382063,
      -0.0016693324, 0.028134892, 0.02713968, -0.022432601, 0.037871823, 0.0022409065,
      -0.0074977065, -0.015264389, -0.003994294, 0.009353641, -0.013052061, -0.0025956186,
      -0.0057964334, -0.010012632, 0.020832192, -0.029883236, 0.008398776, -0.0036311762,
      0.014282627, -0.011350788, -0.03768354, -0.04220234, -0.035155166, -0.028430765,
      -0.0038160973, 0.0038228217, 0.006630259, -0.0031201218, -0.0057997955, -0.02626551,
      0.0058334176, -0.02041528, -0.013919509, -0.0029839526, -0.017335504, -0.023535402,
      -0.033514414, 0.032277122, 0.038517367, -0.0125006605, -0.0072085573, -0.032895766,
      -0.000022379645, 0.0039203255, -0.009225878, 0.017348953, -0.011377686, -0.01972939,
      0.014148138, -0.0048079463, -0.021787059, 0.00796169, 0.007551502, 0.024301983, -0.03106673,
      -0.0005871242, 0.0039673964, -0.0038833413, 0.032519203, 0.0020105958, -0.0021770247,
      0.007215282, 0.014995413, 0.017698621, -0.011908913, 0.017644826, 0.003054559, 0.02982944,
      0.016111664, -0.024813037, 0.0025082014, 0.013509321, 0.006744574, 0.011202851, -0.011855118,
      0.022069482, -0.017093426, -0.038194597, 0.011115434, 0.004128782, 0.0046062144, 0.0269245,
      0.0075985724, 0.015533365, -0.0016743757, 0.035182063, 0.030824652, -0.019164542, 0.007201833,
      0.00031793807, 0.010510238, -0.021477735, -0.023844725, -0.022472946, -0.00073968404,
      0.0184921, 0.007907895, -0.03566622, -0.009286397, 0.053230356, -0.00102379, -0.013038613,
      -0.008849311, 0.0043574115, -0.011599591, 0.029264592, 0.0065562907, -0.024799589,
      0.011855118, 0.0056989295, 0.038893934, -0.011283544, -0.0021518082, 0.019917674, -0.03867875,
      -0.0020358122, -0.01615201, -0.005675394, 0.03835598, 0.005675394, 0.020590115, -0.0065193065,
      -0.0053660716, 0.023750583, -0.008008761, -0.0021349972, -0.041207127, -0.019043501,
      -0.0039001524, 0.033729594, -0.009770554, 0.015089555, -0.029425977, 0.0062873145,
      0.024638204, 0.015008862, -0.01838451, 0.004243097, 0.0018172693, -0.013576564, 0.00077582774,
      -0.024880283, -0.029372182, 0.01717412, -0.0042195613, 0.01558716, -0.022728473, -0.011962709,
      -0.001636551, 0.0055610794, 0.030609472, -0.010873356, -0.00020824627, 0.028430765,
      -0.0045423326, 0.0018761078, 0.010133672, 0.0013742994, 0.021195311, 0.020173201, -0.02738176,
      0.027435554, 0.021195311, 0.026870705, -0.034025468, 0.012352724, -0.026292406, -0.026884153,
      -0.01436332, 0.015896482, 0.034590315, 0.030098418, -0.018935911, 0.02030769, 0.022163624,
      0.01997147, -0.003990932, -0.011223025, 0.0058670393, 0.0065361173, -0.004078349,
      0.0015197145, -0.66706055, -0.026937949, 0.02288986, 0.020173201, 0.006549566, 0.029533567,
      0.025552722, 0.0067143138, -0.014322973, 0.034052365, 0.0010027762, -0.0029906773,
      -0.008365154, -0.03184676, 0.0142557295, -0.013280691, -0.0070068254, -0.023131939,
      0.00886276, 0.0024661738, -0.024826488, 0.037844926, -0.00681518, 0.0015474526, 0.011633213,
      0.029748747, -0.0014633976, -0.015883034, -0.029157002, 0.011001119, -0.010584206, 0.01447091,
      -0.004243097, 0.018586243, 0.055624243, -0.016515128, -0.020455627, 0.039889146, 0.003540397,
      0.028914923, -0.026655523, -0.017913803, 0.02233846, 0.016770655, 0.012419968, 0.0059813545,
      0.02231156, -0.015170247, 0.0010372387, 0.0029234332, 0.015062657, 0.007464085, -0.0058468664,
      0.012305653, 0.02233846, -0.0128974, 0.022405703, -0.014161588, -0.010200916, -0.00035366145,
      -0.026682422, 0.0031487006, 0.015452673, 0.0011221344, -0.010395924, -0.0025972996,
      -0.010564033, 0.016905144, 0.03771044, -0.0038362704, 0.01716067, 0.00712114, -0.012372897,
      0.016649615, 0.050325412, 0.037441462, 0.0055106464, -0.020791847, -0.016340293, 0.020469075,
      0.0066571566, -0.0094074365, -0.022822615, 0.006011614, 0.013334487, -0.017806213,
      -0.0014390217, -0.00037131298, -0.0033134483, 0.017214466, 0.0012910849, 0.021585327,
      -0.004001018, -0.010691796, 0.030501882, -0.00039001522, 0.029183898, 0.014766783,
      0.029748747, -0.013684155, -0.0052719302, -0.005322363, 0.017981047, 0.018774526, 0.010449719,
      -0.012776361, 0.0010187466, 0.0072287307, 0.016178908, -0.021692917, -0.017752418,
      -0.010140396, -0.0057829847, -0.016999284, 0.013173101, -0.024853384, 0.01751034,
      -0.0044112066, -0.002604024, -0.0013490829, 0.022029135, 0.012588078, 0.012231684,
      -0.0023955677, 0.002146765, -0.003039429, 0.020159753, 0.00022631811, -0.024866832,
      -0.0042397347, 0.014780233, -0.023710236, 0.017900355, -0.015842687, 0.012352724,
      0.0016836218, -0.025727557, -0.0007543937, 0.006458787, -0.026157917, -0.02287641,
      -0.01115578, -0.0014356595, -0.028215585, -0.021249106, -0.0002431291, 0.0127091175,
      0.0004375063, 0.030098418, 0.01278981, -0.009804176, -0.0040211915, -0.0035067748,
      -0.0063848183, 0.011875291, -0.029076308, 0.0059376457, 0.000020002464, -0.008889657,
      -0.019352825, 0.006838715, 0.028134892, -0.021692917, 0.017712072, 0.008856036, -0.016353741,
      0.007638919, 0.02556617, -0.013226896, -0.029291488, -0.0029805906, -0.022472946,
      0.0074977065, 0.032035045, 0.0012330869, 0.021195311, 0.008479469, 0.00011956825,
      -0.022257766, -0.0033790113, 0.022499844, -0.0040884353, -0.023131939, -0.009844522,
      0.010833009, 0.0037555776, 0.026628627, -0.020818744, -0.010200916, 0.012285479, -0.005527457,
      0.012924298, -0.009313295, 0.025377888, 0.006549566, 0.002787264, 0.02759694, 0.024463369,
      -0.003442893, 0.02625206, 0.018115535, -0.0019836982, 0.03311095, -0.009346916, 0.00964279,
      -0.021007027, -0.012413244, -0.024947526, 0.006196535, -0.00808273, -0.0052618436,
      -0.015076106, -0.029910134, 0.0069395816, -0.014430564, 0.04026571, -0.005692205, 0.020146305,
      -0.013879163, -0.021154964, -0.0347786, -0.021087721, 0.0128974, -0.008069281, 0.036069684,
      0.018599693, 0.0049760565, 0.0061393776, 0.007558226, -0.01751034, -0.018465204, 0.014457461,
      0.025176156, -0.0023569025, 0.018922463, 0.0077196117, -0.008472744, -0.006882424,
      0.019702494, -0.0072758016, 0.0056989295, 0.0131193055, 0.019379722, 0.011485276, 0.031362604,
      -0.0037152313, 0.031470194, 0.010456443, 0.0066739675, 0.0047474266, -0.016528577,
      0.030663267, -0.013838816, -0.0057863467, 0.015345082, -0.048657764, 0.0025451856,
      0.016138561, 0.02030769, 0.023952315, 0.004515435, 0.01670341, 0.01184167, -0.0060822205,
      0.0034092711, -0.0065562907, -0.0028057562, 0.0037891997, -0.01491472, -0.004770962,
      0.006549566, -0.0045053484, 0.0064218026, -0.005456851, 0.0028242483, -0.005436678,
      0.009118287, 0.0058636772, 0.0032999995, -0.011814772, 0.00903087, -0.012359448, 0.00829791,
      0.012823432, -0.009279673, -0.005907386, -0.0025266935, -0.004814671, -0.019998368,
      0.00079600094, 0.020294242, 0.0006728603, 0.0072421795, 0.0067681093, 0.0020828831,
      -0.005251757, -0.011424757, 0.0027923074, 0.0025552723, -0.00005374267, 0.020334587,
      -0.010725419, -0.029748747, 0.0011868567, 0.014941618, 0.011599591, -0.025108911,
      -0.010658175, -0.017752418, 0.0109540485, 0.003910239, -0.026467241, 0.0031671927,
      0.012258582, 0.0072623524, -0.0033638813, -0.0018004583, -0.01502231, 0.020738052,
      0.013663982, 0.0062200706, -0.0056081503, -0.031147424, 0.009911766, 0.07633539, 0.006694141,
      -0.015775444, 0.01182822, 0.0052282214, -0.008721547, -0.0116264885, -0.029399078,
      0.020038713, -0.016743757, 0.034536522, 0.0009187212, 0.015883034, 0.0069395816, 0.023427812,
      0.0019399895, -0.009185531, -0.00044381042, -0.0075649507, -0.014538154, -0.010073152,
      0.0011389453, -0.0075246044, -0.0040346403, -0.005759449, 0.0033050429, 0.03666143,
      0.004633112, 0.0052282214, -0.006502495, -0.011545796, 0.008620681, 0.0015440905, 0.01670341,
      -0.0012650279, 0.008869484, -0.00681518, -0.0042128367, 0.007813754, -0.007423738,
      0.009380539, 0.02379093, 0.009414161, -0.027166579, 0.011639938, -0.013764848, 0.0014902953,
      0.030125314, -0.01329414, -0.010019356, 0.045026585, -0.00829791, -0.0059544565, -0.027301066,
      -0.0018424857, -0.00858706, -0.003658074, -0.0017886906, -0.021343248, 0.0040716245,
      -0.026642075, -0.038759444, 0.030663267, -0.010819561, -0.014632296, -0.0021904735,
      -0.013347935, -0.024100251, -0.0107993875, -0.018787974, 0.007114416, 0.0010221088,
      -0.025377888, -0.01638064, 0.005779622, 0.005971268, -0.003459704, 0.0028645946, -0.013388282,
      0.011431481, -0.006452062, 0.0032462045, -0.0065865503, -0.007820478, 0.008344981,
      -0.006065409, -0.0043237894, -0.006246968, 0.0028259293, 0.00018187403, -0.0012936066,
      -0.0111087095, 0.018250024, -0.039862245, -0.0015314822, -0.012944471, 0.010214364,
      0.016205804, -0.0031184407, -0.010456443, 0.023992661, -0.028968718, -0.0010397604,
      -0.0002532157, 0.018882116, -0.008607233, -0.006932857, 0.0118820155, -0.025149258,
      -0.0007409449, 0.04631767, -0.0043506874, 0.0027923074, -0.009669688, 0.0073968405,
      0.004192664, -0.010281608, 0.034832396, 0.015909933, -0.0024275086, -0.009891593,
      0.0027839018, 0.025593068, 0.034832396, -0.0021955168, 0.00423301, 0.008923279, -0.027193476,
      -0.029533567, -0.02894182, 0.004337238, 0.003846357, 0.00040745665, -0.030690163, -0.01166011,
      -0.02972185, -0.022620883, 0.012198063, 0.010422821, -0.005628323, -0.018666936, -0.015398878,
      -0.005537544, -0.026413444, 0.046183184, -0.029291488, -0.018451756, -0.02232501,
      -0.00040115253, -0.01503576, -0.0021198674, 0.021128066, -0.03655384, -0.019420069,
      0.0006224273, -0.031147424, -0.024705447, -0.012379621, 0.0040380023, 0.008681201, 0.03163158,
      -0.013112581, 0.022903308, 0.0095620975, -0.003226031, 0.010241262, 0.022741923,
      -0.0049289856, -0.022177072, 0.029694952, 0.009158634, 0.015156799, 0.035477936, 0.018828321,
      0.0048953635, 0.033460617, 0.006401629, -0.013758124, -0.008519815, -0.025821699,
      -0.0106985215, 0.009938664, 0.0057056537, 0.0060317875, -0.023226079, 0.0032411611,
      0.0021703003, 0.010436269, 0.0073968405, -0.0079146195, 0.030636368, -0.012836881,
      0.016394088, -0.01502231, 0.012675495, -0.0023770756, -0.00942761, -0.00096999475,
      -0.003990932, 0.015708199, -0.0046263877, 0.01749689, -0.00032235094, -0.008398776,
      -0.0023014261, -0.016286498, -0.028403867, -0.005066836, -0.0071614864, -0.012520834,
      0.023387466, 0.004949159, 0.004091798, -0.016326845, 0.014659193, -0.024718896, -0.02400611,
      0.010059703, -0.029183898, -0.044112068, -0.007504431, -0.017375851, 0.0128974, 0.010597656,
      0.013516045, 0.009817624, 0.011149056, -0.017644826, 0.0381408, 0.025485478, -0.009400712,
      0.02197534, 0.011458378, 0.0054602134, -0.006179724, -0.0045120725, 0.0067277625, -0.00790117,
      -0.035074472, 0.033702694, -0.000291881, 0.0056182365, -0.0030680078, -0.000083424595,
      -0.03601589, 0.023925418, 0.01806174, -0.0123459995, -0.0014995413, -0.01896281, -0.009104839,
      0.019554557, -0.0020341312, 0.015076106, 0.0043036165, -0.022674678, -0.009851247,
      -0.02041528, 0.0001708418, -0.003879979, -0.007753234, 0.032680586, -0.003997656,
      0.0041624038, 0.014430564, -0.023091592, 0.004182577, 0.012614976, -0.03241161, 0.028538356,
      -0.010886804, -0.016676513, -0.013226896, -0.0071009668, -0.018922463, 0.022607435,
      -0.004912175, -0.009891593, -0.023911968, -0.004868466, 0.005147529, 0.033837184, 0.00857361,
      0.013247069, -0.004333876, -0.0038060108, 0.010221089, -0.016676513, -0.009750381,
      -0.018263472, -0.022136727, -0.009104839, -0.006697503, 0.01728171, -0.016394088,
      0.0065058577, 0.013025164, 0.026494138, -0.033622004, 0.023535402, -0.006979928, -0.013031888,
      -0.019164542, 0.033568207, -0.018774526, -0.03397167, 0.0023215993, -0.02085909, -0.01300499,
      -0.012359448, -0.019379722, 0.0035740188, -0.0073968405, -0.0118820155, 0.02478614,
      0.027570043, 0.007511155, -0.008203769, -0.019446967, 0.03456342, 0.0071009668, -0.0005976311,
      0.0128637785, 0.0065731015, 0.006109118, -0.026547933, 0.010476616, 0.007538053,
      -0.0032377988, 0.018720731, -0.010241262, 0.02770453, 0.005204686, -0.021961892, -0.002166938,
      -0.010086601, -0.01928558, 0.025122361, -0.008156698, -0.013549667, 0.017591031, 0.0021518082,
      -0.01850555, 0.011955984, -0.015452673, -0.005120631, -0.011545796, 0.002249312, -0.015277838,
      0.0026460516, -0.0076792655, 0.03187366, 0.01088008, -0.016689962, -0.010792663, -0.023078144,
      -0.005339174, 0.029076308, 0.0031503816, 0.018357614, 0.026346201, -0.0025250125,
      -0.011041466, -0.0054198666, -0.0072085573, -0.0023922054, -0.0392436, 0.0015928424,
      0.009979011, -0.010342128, 0.0014482677, -0.0010809473, -0.007511155, -0.0004564187,
      0.04126092, 0.026937949, 0.0019063676, 0.028914923, 0.0064554247, 0.011323891, 0.0072085573,
      -0.008344981, 0.006162913, -0.009837798, -0.0073901163, -0.045349356, -0.0010431226,
      -0.021854302, -0.02041528, 0.0002962939, -0.006986652, 0.0056182365, -0.009010697,
      0.014444012, -0.01939317, -0.00039968156, -0.0269245, 0.0064049917, 0.017846558, -0.01569475,
      0.005584615, 0.020065611, 0.013986753, -0.010994395, -0.0018256748, 0.00004307819,
      -0.010221089, -0.0063377474, 0.011969433, -0.047017008, -0.010940599, 0.01627305,
      0.0007081634, -0.013596738, 0.011088536, -0.0029957204, -0.041422307, 0.0069126836,
      0.021047374, 0.0018559345, -0.011162505, -0.0043506874, 0.0047373404, -0.024019558,
      0.017120324, -0.03397167, 0.010503514, -0.019769737, -0.022284664, -0.001384386, 0.0028998978,
      -0.014107793, -0.009346916, 0.006865613, -0.009393987, 0.011801323, 0.19355515, 0.0062805903,
      0.010254711, 0.022056034, 0.008667752, 0.018223125, 0.017564135, 0.015425775, -0.025189605,
      0.011404583, -0.010133672, -0.0018105449, 0.006109118, -0.007692714, -0.005345898,
      0.0057191025, -0.005258481, -0.02232501, -0.02680346, -0.0036042787, -0.015963728,
      0.017631378, -0.025068564, -0.008459296, 0.008795516, 0.026332753, -0.01491472, 0.012641873,
      0.034832396, 0.00982435, 0.00018628691, -0.028430765, 0.010530411, 0.00007507163,
      -0.022540191, -0.00462975, 0.0048751906, -0.024369227, 0.023495056, 0.012446865, 0.0062738657,
      0.013811919, -0.011290269, -0.014618847, -0.025767904, 0.012675495, 0.00088509923,
      -0.019231785, 0.0105909305, 0.00064722355, -0.023024347, -0.0008174349, 0.036500048,
      -0.0025149258, -0.006257055, 0.005870402, 0.020939784, -0.009979011, -0.030555677,
      0.009911766, -0.0014205296, 0.026117573, -0.013838816, 0.022540191, -0.0034126332,
      0.014860925, -0.02648069, 0.03424065, -0.00790117, 0.0075985724, -0.0067916447, -0.014148138,
      -0.0028595512, -0.00802221, -0.011028017, -0.02333367, 0.011801323, 0.022298113, 0.012023228,
      0.02826938, -0.016340293, -0.033837184, -0.016878245, -0.021464286, -0.010530411,
      -0.040561583, 0.008977075, 0.008035659, -0.024745794, 0.006879062, -0.0031268462,
      -0.015506468, -0.015533365, 0.00037089273, 0.00790117, 0.04846948, 0.0052315835, 0.039969835,
      0.0038900657, 0.009568822, -0.043278243, 0.03152399, 0.020980129, 0.01088008, 0.0046768207,
      -0.019702494, 0.013139479, 0.02658828, 0.0028444214, -0.014309525, -0.020267343, -0.016299946,
      0.0071009668, -0.008782067, -0.0056552207, 0.022499844, 0.031147424, 0.0021232294,
      0.016004073, -0.03580071, 0.017308608, -0.027623838, -0.010624553, 0.007948241, -0.011619764,
      -0.011081812, -0.011808047, -0.010369026, 0.008271012, -0.027408656, 0.01413469, -0.026709318,
      0.019890776, -0.031550888, -0.016030971, -0.018814873, -0.017698621, -0.020832192,
      -0.017201016, 0.0014633976, 0.0029805906, -0.010227813, -0.013556392, 0.0072892504,
      -0.011283544, -0.019245235, 0.020832192, -0.0052181347, -0.0036849715, -0.013838816,
      -0.009003973, -0.010247987, -0.019083848, -0.019473864, 0.026991744, -0.009481405,
      -0.023911968, -0.05729189, -0.03219643, 0.033595104, -0.034509625, 0.009326744, 0.015156799,
      -0.012937747, -0.01716067, 0.0058502285, -0.17343573, 0.033460617, -0.0012894038,
      -0.029533567, 0.014188485, -0.024544062, 0.03655384, 0.021262554, 0.0038698926, -0.0020845642,
      -0.005860315, -0.014390217, -0.019984918, 0.016326845, 0.0022728473, -0.007659092,
      0.006734487, 0.004347325, 0.01884177, 0.0019097298, 0.007020274, -0.007975139, 0.01318655,
      0.009373815, -0.0026662247, -0.011687008, -0.02489373, 0.023521952, 0.013475698, -0.001948395,
      -0.01660927, 0.0031638304, -0.0077128876, 0.01184167, 0.014027099, 0.008492918, -0.022862962,
      -0.04556454, -0.008526539, -0.0010960773, 0.01547957, 0.03432134, -0.001980336, -0.006025063,
      -0.009662963, 0.020226996, 0.010994395, 0.0074304626, 0.026897602, -0.01312603, 0.014457461,
      -0.037898723, -0.0026309218, -0.011270096, -0.01060438, 0.007336321, -0.01071197, 0.003155425,
      -0.0017718795, -0.001292766, -0.011122159, -0.015170247, -0.005191237, 0.009958837,
      -0.016972387, -0.019312479, -0.02635965, -0.012184613, -0.016797552, 0.0130184395,
      -0.00068588887, -0.026453791, 0.0027149767, 0.0058031576, 0.011680284, 0.0007842332,
      -0.001444065, 0.04510728, -0.007880998, -0.00028935936, -0.026440343, 0.02110117,
      -0.0039842073, 0.0094612315, 0.006115842, 0.01356984, 0.020590115, -0.019110747, 0.021840854,
      0.001049847, 0.023777481, -0.015829239, -0.008405501, -0.013213447, 0.0063713696, 0.010214364,
      0.0006106596, -0.015869586, 0.009346916, -0.01772552, 0.008822413, -0.0018878755,
      -0.029076308, -0.0043103406, 0.005006316, 0.00462975, 0.009676413, 0.009481405, 0.036419354,
      -0.012413244, -0.0074775335, -0.017254813, 0.025929289, 0.0053996937, 0.02635965, 0.008674476,
      0.0021316349, 0.002366989, 0.000759437, -0.018290369, 0.054440748, 0.015331633, -0.008069281,
      0.015197145, 0.018666936, -0.007470809, -0.09398022, -0.02197534, 0.015869586, 0.026964847,
      -0.011350788, 0.024154047, 0.003994294, 0.010409372, -0.014712988, 0.027892813, 0.007773407,
      -0.021356696, -0.014591949, -0.015089555, 0.00080230506, -0.025996532, 0.011639938,
      -0.019110747, -0.013193274, 0.0302867, -0.014430564, -0.003661436, 0.010402648, 0.02545858,
      -0.008593784, -0.01491472, -0.0062234327, 0.01560061, 0.0000028270063, 0.009784003,
      0.007773407, 0.0151299015, 0.02568721, -0.010792663, -0.0043910337, -0.021746712,
      -0.015708199, -0.03580071, 0.018182779, -0.013078959, 0.01884177, 0.013495872, 0.012029952,
      -0.028995614, -0.0039337743, -0.01873418, -0.027005192, 0.012312378, 0.009622618,
      -0.013973304, -0.01233255, -0.02547203, -0.017779315, -0.022472946, 0.014175036, -0.016945489,
      0.0035504834, 0.03340682, 0.0104900645, 0.00064890465, 0.01037575, -0.009057768, -0.011888741,
      0.0010137034, 0.023118488, 0.0034866016, -0.022674678, -0.012742739, -0.00090191024,
      -0.03120122, -0.000314786, 0.030501882, -0.03601589, 0.007706163, -0.0011607996, 0.027166579,
      -0.017927252, -0.029022513, -0.018666936, -0.031120526, -0.013448801, -0.002430871,
      0.016918592, -0.008701375, 0.030232904, 0.00456923, -0.0053593474, -0.0044112066, -0.01940662,
      0.009414161, 0.0031940902, 0.04005053, -0.0010263117, 0.008553438, -0.013226896,
      0.00080398616, 0.01155252, -0.012998266, 0.008499642, 0.013495872, -0.007000101, -0.009965561,
      -0.03184676, 0.027085885, 0.011713906, -0.018909015, -0.0066773295, -0.006075496,
      -0.006166275, -0.0030848188, -0.0027233823, -0.02614447, -0.017133772, 0.0055173705,
      -0.022002239, -0.011411307, -0.022809166, -0.021625672, 0.030878447, 0.005036576, 0.033272333,
      -0.00029545336, -0.01580234, -0.0370111, 0.021276003, 0.010106774, 0.016178908, 0.008412225,
      -0.0063545587, 0.052100655, -0.0005829215, -0.010247987, 0.015775444, -0.011451654,
      0.0034765152, -0.010429545, 0.013542943, -0.033514414, 0.013852265, 0.013650533, 0.027731428,
      0.0358814, 0.0074102893, -0.0017004327, 0.017241362, -0.027005192, -0.006075496,
      -0.0013742994, -0.031416398, 0.0013381557, 0.004515435, -0.027650734, 0.012816708,
      0.0055173705, -0.00459949, -0.02131635, -0.023427812, -0.032626793, 0.0022190523,
      -0.011330615, -0.006778196, -0.033595104, 0.019500762, -0.006125929, 0.009602444,
      0.0136370845, 0.018236574, 0.0011145694, -0.00942761, 0.0020072337, -0.0031503816,
      -0.008674476, -0.020617012, -0.0097167585, 0.009736932, 0.020240447, 0.009710034, 0.011007844,
      -0.00712114, -0.0042565456, -0.014981965, 0.009279673, 0.018075189, 0.021840854, -0.016622718,
      0.021773608, 0.01580234, 0.012386346, -0.019890776, 0.004044727, 0.013724501, 0.0053122765,
      -0.018330716, 0.00684544, -0.015291287, -0.012218236, 0.027570043, 0.0134824235, -0.008445847,
      -0.007336321, 0.0049760565, 0.015237492, 0.021948444, -0.009622618, 0.0016071317,
      -0.0053996937, -0.009844522, 0.0493302, -0.017564135, -0.03445583, -0.0018811511, 0.010537135,
      0.018128984, -0.010967498, 0.011485276, 0.006075496, -0.012830156, 0.014175036, -0.013233621,
      -0.03456342, -0.004498624, 0.032492302, 0.008896382, 0.025445132, 0.016878245, -0.014578501,
      0.036042787, -0.012890676, 0.0025166068, -0.008176871, -0.013341211, 0.0015121496,
      0.0025771265, 0.01318655, -0.033917878, -0.008479469, 0.02738176, -0.008365154, -0.008526539,
      0.029345283, -0.008667752, 0.028538356, -0.006257055, -0.005961181, -0.0042733564,
      0.008136525, 0.026198264, 0.017644826, 0.020334587, -0.0001089563, -0.0072892504, 0.021921545,
      -0.031443298, -0.0021904735, -0.037441462, -0.007080794, 0.0044212933, -0.013879163,
      0.013731226, 0.0055610794, 0.0014978602, 0.018653488, 0.014941618, 0.03297646, 0.020872539,
      -0.011357512, -0.01896281, 0.012971369, 0.00039568896, -0.01010005, -0.006146102,
      -0.0043574115, -0.005587977, -0.014591949, -0.010624553, 0.018007945, 0.0036479873,
      -0.022002239, -0.015210594, 0.011182678, -0.0058367797, 0.014309525, -0.009891593,
      -0.01369088, -0.041691285, -0.010516962, 0.013381558, -0.025229951, 0.00015917917,
      -0.032465406,
    ],
  },
  "whyaretheyatrisk?": {
    data: {
      isDemo: "HIGHLIGHT_2",
      headers: ["Student", "GPA", "Attendance", "Total Credits", "Ethics Requirement", "Reason"],
      rows: [
        {
          Student: "John Smith",
          GPA: 1.5,
          Attendance: 70,
          "Total Credits": 25,
          "Ethics Requirement": "Incomplete",
          Reason: "GPA, Ethics Requirement",
        },
        {
          Student: "Emily Johnson",
          GPA: 2.0,
          Attendance: 50,
          "Total Credits": 30,
          "Ethics Requirement": "Complete",
          Reason: "Attendance",
        },
        {
          Student: "Michael Brown",
          GPA: 1.9,
          Attendance: 65,
          "Total Credits": 22,
          "Ethics Requirement": "Incomplete",
          Reason: "Total Credits, Ethics Requirement",
        },
        {
          Student: "Sarah Davis",
          GPA: 2.2,
          Attendance: 75,
          "Total Credits": 20,
          "Ethics Requirement": "Complete",
          Reason: "Total Credits",
        },
        {
          Student: "James Miller",
          GPA: 1.6,
          Attendance: 80,
          "Total Credits": 28,
          "Ethics Requirement": "Incomplete",
          Reason: "GPA, Ethics Requirement",
        },
        {
          Student: "Jessica Wilson",
          GPA: 1.8,
          Attendance: 55,
          "Total Credits": 30,
          "Ethics Requirement": "Complete",
          Reason: "Attendance",
        },
        {
          Student: "David Anderson",
          GPA: 2.5,
          Attendance: 90,
          "Total Credits": 23,
          "Ethics Requirement": "Incomplete",
          Reason: "Total Credits, Ethics Requirement",
        },
        {
          Student: "Ashley Thomas",
          GPA: 2.1,
          Attendance: 58,
          "Total Credits": 25,
          "Ethics Requirement": "Complete",
          Reason: "Attendance",
        },
        {
          Student: "Robert Taylor",
          GPA: 1.7,
          Attendance: 60,
          "Total Credits": 23,
          "Ethics Requirement": "Incomplete",
          Reason: "Total Credits, Ethics Requirement",
        },
        {
          Student: "Jennifer Jackson",
          GPA: 1.6,
          Attendance: 70,
          "Total Credits": 24,
          "Ethics Requirement": "Incomplete",
          Reason: "GPA, Ethics Requirement",
        },
        {
          Student: "Michelle Johnson",
          GPA: 1.4,
          Attendance: 72,
          "Total Credits": 28,
          "Ethics Requirement": "Incomplete",
          Reason: "GPA, Ethics Requirement",
        },
        {
          Student: "Daniel Williams",
          GPA: 2.3,
          Attendance: 50,
          "Total Credits": 30,
          "Ethics Requirement": "Complete",
          Reason: "Attendance",
        },
        {
          Student: "Emma Brown",
          GPA: 2.6,
          Attendance: 70,
          "Total Credits": 22,
          "Ethics Requirement": "Complete",
          Reason: "Total Credits",
        },
        {
          Student: "Joshua Davis",
          GPA: 1.9,
          Attendance: 60,
          "Total Credits": 30,
          "Ethics Requirement": "Incomplete",
          Reason: "GPA, Ethics Requirement",
        },
        {
          Student: "Amanda Miller",
          GPA: 2.2,
          Attendance: 58,
          "Total Credits": 30,
          "Ethics Requirement": "Complete",
          Reason: "Attendance",
        },
      ],
    },
    id: 6,
    isFavorite: [],
    title: "Graduation Risk Factors for 10 Students",
    visualisation: "TABLE",
    secondAnswer:
      "The table reflects academic performance trends among students and their respective risks of not graduating. From the data, low GPA and incomplete ethics requirements emerge as common issues. For instance, students like John Smith, James Miller, Robert Taylor, and Jennifer Jackson are falling below the GPA threshold, indicating a trend of academic struggles. Similarly, an incomplete ethics requirement is common among several students, suggesting a trend of difficulties in meeting this particular requirement. Attendance and total credits are less common issues, but when they occur, as in the cases of Emily Johnson and Sarah Davis, they significantly contribute to the risk score. Overall, the data highlights a crucial need for targeted interventions to support students struggling with their GPA and ethics requirements, as these are the predominant factors placing students at risk of not graduating.",
    embedding: [
      0.014577443, -0.034202438, 0.009332717, -0.05147454, -0.046768747, 0.03167866, -0.014840336,
      0.013210396, -0.005570055, -0.020716004, 0.010916651, 0.021701856, -0.015799897,
      -0.0024284783, -0.0010080321, 0.020926319, 0.04343, -0.007499036, 0.0040682764, -0.024462236,
      -0.018455122, 0.006247006, -0.017258955, -0.008964667, -0.0046302113, 0.004751799,
      0.031494636, -0.017482415, 0.0047978056, -0.02033481, -0.0059808264, -0.002559925,
      -0.02193846, -0.01929638, -0.025434943, 0.00091930554, 0.012257407, -0.021885881,
      0.00042432648, -0.017784743, -0.008024823, -0.01234942, -0.0016767674, -0.02970696,
      -0.010706336, 0.01890204, -0.0007829296, -0.013072377, -0.0063587357, 0.0053630266,
      0.036437035, 0.00074554945, -0.025816137, -0.03170495, -0.009989952, -0.007282149,
      -0.005744222, 0.009115831, -0.009930801, -0.01367046, 0.022122486, 0.011376714, -0.021215502,
      0.025632113, -0.024711985, 0.023082046, 0.0072558597, 0.026565384, 0.003437332, 0.005504332,
      0.029812118, 0.019375248, 0.02067657, -0.00316458, 0.0048503843, 0.018967763, -0.00034073458,
      -0.021728145, -0.0022362375, 0.012283697, 0.02511947, -0.0037922382, -0.015116374, 0.02011135,
      -0.00925385, -0.02393645, -0.0017219522, 0.025868716, 0.008977812, 0.0035654926, 0.005014693,
      -0.0057507944, 0.016930338, 0.0067235003, 0.01268461, 0.03217816, -0.0022198067, 0.02607903,
      -0.0055141905, -0.012645176, -0.009996524, 0.0023956166, 0.008603188, -0.006424459,
      -0.019086065, -0.011580457, 0.0028868988, 0.0004621174, 0.0007246001, -0.031074008,
      -0.003320673, 0.028287336, -0.022753429, -0.017088074, 0.013893919, -0.0036673637,
      -0.008248283, 0.02013764, 0.013617882, -0.016036501, 0.016851472, 0.018349964, 0.0020554983,
      -0.011856495, 0.009293284, 0.002890185, -0.038356155, -0.010732626, -0.019178078,
      -0.019138644, 0.0055306214, -0.010831211, 0.037567474, 0.001074577, -0.026381359, 0.013105239,
      -0.009930801, -0.0125268735, -0.031967845, -0.009983379, 0.012415144, 0.022806007,
      -0.009319574, 0.015970778, -0.0045973496, 0.02559268, 0.013933353, 0.02610532, -0.029496646,
      0.008274572, -0.0020982185, 0.007913093, 0.010252845, -0.032309607, -0.018073926, 0.022740284,
      -0.035622064, -0.019454116, -0.02590815, -0.010075392, -0.0034537627, -0.003572065,
      0.012382282, 0.014314549, 0.007834225, 0.027446078, 0.0032927406, 0.027235763, 0.005182287,
      0.011987942, 0.017797887, 0.015997067, -0.025066892, 0.011304419, 0.016956627, 0.029812118,
      -0.019230656, 0.016167948, -0.025579534, -0.0030167024, -0.046795037, 0.016496565,
      0.007919665, 0.025185194, -0.025290351, 0.024212487, 0.02975954, -0.009970235, -0.020282231,
      -0.026907146, 0.0004695113, 0.008175987, -0.01105467, -0.018731158, -0.68268174, -0.031152874,
      0.009766492, 0.009970235, -0.002693015, 0.027840417, 0.003434046, 0.015786752, -0.02210934,
      0.04303566, -0.003084069, -0.0056949295, 0.013486435, -0.018257951, 0.010127971, -0.023673557,
      0.020926319, -0.009063252, 0.0281296, 0.011613319, -0.032388475, 0.01586562, -0.005839521,
      0.013893919, 0.01969072, 0.049292523, -0.0018665435, -0.030469352, 0.00091026863, 0.024817143,
      0.00027028733, 0.020886885, -0.004081421, 0.012474295, 0.031994134, -0.021609843,
      -0.0048306673, 0.03625301, 0.0005927426, 0.028050732, -0.03935515, -0.008958095, 0.02607903,
      0.007301866, 0.018073926, 0.0049325386, 0.02398903, -0.004318025, 0.012145678, 0.027209474,
      0.014340838, -0.00051839306, -0.026249912, -0.009431303, 0.012710899, -0.00026474192,
      0.016838327, -0.023463242, -0.001971701, 0.014301404, -0.007439885, 0.01668059, 0.009746775,
      -0.029154886, 0.002070286, 0.012217973, -0.0010762201, 0.025053747, 0.011856495, -0.033939544,
      0.030311616, -0.00032800066, -0.015629016, 0.00009452868, 0.025513811, 0.03417615,
      0.0013037872, -0.011731621, -0.019164933, 0.009069825, -0.015392412, 0.0028934712,
      -0.03136319, 0.0036903669, 0.019020341, 0.0014418063, 0.020729149, -0.008103691, -0.021044621,
      -0.014196247, 0.022976888, 0.00872149, 0.015615871, -0.011876212, 0.012566308, -0.01215225,
      0.0065164715, -0.002831034, 0.013407567, 0.011192689, -0.020821162, -0.014406562, 0.018441977,
      0.0056226337, 0.021465251, -0.0020538552, -0.019835312, 0.016641157, 0.025053747,
      -0.037252005, -0.0030742104, 0.009378724, -0.010364574, -0.015379268, 0.017324679,
      -0.021018332, 0.02734092, -0.0050245514, -0.012664893, -0.020978898, 0.011258412, 0.020886885,
      0.015458136, -0.033177156, -0.008143125, 0.02272714, 0.020952608, -0.008787214, -0.0036410745,
      -0.011159827, 0.0045809187, 0.004393607, 0.0042851632, -0.019598708, 0.016391408,
      -0.005096847, -0.0030413487, 0.0037758073, 0.008912088, -0.014445996, -0.028655387,
      -0.006095842, 0.01428826, -0.00812998, -0.008116836, -0.006362022, 0.010476304, 0.007873659,
      0.009943945, -0.0073544444, -0.0039368295, -0.021662422, -0.018402543, 0.013440428,
      0.008517748, 0.0064737513, 0.026210478, -0.021005187, 0.021504685, 0.00077758956, 0.00964819,
      0.016890904, -0.016075935, -0.035122566, -0.006700497, -0.010226555, 0.018073926, 0.009378724,
      0.01223769, -0.027524946, -0.008018251, -0.04211553, 0.014183102, 0.02511947, -0.0026075745,
      0.024777709, 0.0033502486, -0.027945574, -0.0062240027, 0.0081891315, 0.004820809,
      -0.028287336, -0.010081965, -0.01769273, -0.0028556802, -0.0017022352, 0.024317645,
      -0.00437389, -0.013788762, 0.03499112, -0.008655767, 0.019966759, -0.012520301, 0.02267456,
      0.01971701, -0.017705875, 0.022516824, 0.054892156, 0.006549333, 0.0204794, 0.029417777,
      -0.035911247, 0.031415768, -0.0035030553, -0.004643356, -0.023726135, -0.0055174767,
      -0.0069075255, 0.011744766, 0.008005106, 0.0014935635, -0.0025960729, -0.017705875,
      -0.008649195, -0.0061451346, 0.032730237, 0.016930338, 0.011863068, -0.038803075,
      -0.007012683, -0.013460145, -0.029181175, 0.020978898, -0.0017334537, 0.019598708, 0.0102397,
      0.009674479, 0.0028228185, 0.00018525774, -0.016417697, -0.02267456, 0.016785748,
      -0.005277586, 0.015681595, -0.0012142392, 0.007729068, -0.0038875372, -0.026026452,
      0.009069825, 0.002323321, -0.0026831564, 0.00945102, 0.015563292, 0.01648342, 0.016759459,
      -0.0022674561, 0.040853646, 0.037567474, -0.005070558, 0.013539013, -0.004025556, 0.01144901,
      -0.0013235043, 0.01606279, 0.0021047909, -0.031573504, 0.020190218, 0.0070324, 0.0018353249,
      0.004354173, 0.0152215315, 0.0104631595, -0.0054386086, -0.00953646, 0.015970778,
      -0.005195432, -0.008452024, -0.011633036, 0.0047025066, -0.009819071, -0.003982836,
      0.015352978, 0.008688629, -0.012027375, 0.022385377, 0.023108335, -0.015721029, -0.00984536,
      0.020716004, 0.011567312, -0.00925385, -0.028891992, 0.0056193476, 0.032020424, -0.014419707,
      -0.01507694, -0.027235763, 0.011383287, -0.010732626, 0.039171126, 0.030705957, -0.0033946119,
      -0.023752425, -0.0006625737, 0.02894457, 0.01951984, 0.0003970102, -0.014866625, 0.027840417,
      -0.014183102, -0.008662339, -0.019230656, -0.03906597, -0.0004945683, 0.0035950681,
      0.002346324, 0.0014902774, -0.011882785, -0.011849923, -0.01907292, -0.00022078943,
      -0.02371299, 0.012533446, -0.007637055, 0.00265851, -0.017258955, 0.00213108, -0.008018251,
      0.019309524, -0.0010753985, -0.0008675484, -0.024554249, -0.02272714, -0.017850466,
      0.09669221, -0.0025024172, -0.009989952, 0.025789848, 0.012592597, -0.009976807,
      -0.0025089895, -0.029838407, 0.0076830615, 0.019112354, 0.025671547, -0.0014771327,
      0.014998072, 0.0139596425, 0.024869721, 0.02067657, 0.00048553137, -0.024383368, -0.018744303,
      0.0019684148, -0.01167247, 0.020177074, 0.041142825, 0.032046713, -0.000039151615,
      -0.0065887673, 0.004994976, 0.009805926, -0.0034734798, -0.005109992, -0.012185112,
      -0.0030824258, -0.00942473, 0.015655305, -0.007321583, 0.008642622, -0.0077750743, 0.01369675,
      0.029628092, 0.009122403, 0.03449162, 0.0055601965, 0.027656391, -0.015734173, 0.01729839,
      -0.014616877, 0.004196437, 0.02213563, 0.011258412, -0.0048931045, 0.029444067, -0.0036016405,
      -0.009805926, -0.030679667, 0.0032401618, -0.008892371, 0.0019947041, -0.017311534,
      -0.009753347, -0.022319654, -0.024449091, -0.01063404, 0.01867858, -0.011744766,
      -0.0066512045, -0.008386302, -0.0062995846, -0.018192228, -0.006099128, -0.014222536,
      0.027446078, -0.01668059, -0.020400533, -0.013801906, 0.015537004, 0.025710981, -0.008668912,
      0.0029427637, -0.0072295703, 0.023371229, 0.016943483, -0.018928329, -0.0015790039,
      -0.0076107658, -0.0011148326, -0.0067300727, -0.02770897, -0.016075935, -0.0029887701,
      0.028024442, -0.0106734745, 0.021176068, 0.007945955, -0.012921213, 0.010456587, -0.004258874,
      0.020518834, 0.02559268, -0.006795796, -0.021767579, -0.004433041, -0.043114528,
      -0.0038612478, -0.011350425, -0.0005385208, 0.005139567, 0.0015707884, 0.013322126,
      0.0016800535, -0.032730237, 0.014196247, -0.013440428, 0.0055109044, -0.009911084,
      0.0029887701, -0.006306157, 0.019822167, 0.030285327, 0.026433937, 0.012724044, 0.0143802725,
      -0.016654301, 0.017508704, 0.017640151, -0.0032664512, 0.0018041064, 0.012612314,
      -0.025710981, -0.036042694, -0.0027603814, 0.0069995383, 0.032335896, 0.008498031,
      -0.02652595, -0.032835394, -0.009017246, -0.00060917344, 0.016549144, -0.0066479184,
      -0.006578909, 0.0025763558, -0.005507618, -0.003808669, -0.040354148, 0.016522855,
      -0.028786834, -0.00040460945, -0.00024605184, -0.014577443, -0.0016406195, -0.051684853,
      -0.0076041934, -0.048740447, -0.009720486, 0.0042555877, -0.024988024, 0.006092556, 0.0070324,
      0.033098288, 0.021833302, 0.007939382, -0.018783737, 0.0077224956, 0.010522311,
      -0.00049703295, -0.016365118, 0.006933815, 0.0018172511, -0.0021228646, 0.0054156054,
      0.0145905875, 0.009313001, -0.012277124, -0.01114011, 0.022490535, 0.018323675, -0.012829201,
      -0.0148009015, -0.0281296, -0.038040683, 0.012329703, -0.00277024, -0.024212487, 0.017311534,
      -0.035464328, 0.004193151, -0.012671465, -0.001103331, 0.0014524864, -0.015550149,
      0.015813041, 0.00029370128, 0.009332717, -0.019164933, 0.0067037833, -0.012743761,
      -0.012079954, -0.008208849, -0.0094115855, 0.009983379, 0.007926238, 0.027183184,
      -0.005760653, -0.015287255, 0.007518753, 0.0048766737, -0.025066892, 0.0027242335,
      0.005895386, -0.015208387, -0.0041997232, -0.02149154, -0.026670542, -0.020019338,
      -0.00874778, -0.0054780426, -0.033203445, 0.0002339341, -0.020203363, -0.015918199,
      -0.021833302, -0.000008138401, 0.01791619, -0.0054977597, 0.022963744, -0.002382472,
      -0.0023381086, -0.021807013, -0.0029608377, 0.03409728, -0.001300501, 0.024685696,
      0.0062568644, -0.01769273, -0.012060237, -0.015721029, 0.006720214, -0.01367046, -0.03659477,
      0.034228727, -0.0029805547, -0.022976888, 0.008918661, -0.039565466, 0.0017005921,
      0.010791777, 0.007834225, 0.0021524401, 0.013486435, -0.029838407, -0.008708346, 0.014406562,
      -0.022884876, 0.013223541, 0.002753809, -0.016338829, -0.00006849607, -0.018836316,
      -0.007459602, -0.0018484696, 0.00062190735, 0.0067300727, 0.0018649005, 0.0053564543,
      0.0024514815, -0.0059972573, -0.015471281, 0.012599169, -0.011067815, 0.033334892,
      -0.015063795, -0.021373238, -0.007617338, 0.012047092, 0.00026576885, 0.027235763,
      -0.012277124, 0.010568317, -0.0057179327, -0.01549757, 0.021005187, 0.025106326,
      -0.00002808647, 0.013269547, -0.022424811, 0.0112452675, -0.017653296, -0.008202276,
      0.0028984004, -0.0018616143, -0.012645176, 0.0039171125, 0.0014557726, 0.027998153,
      0.00617471, 0.0007015969, 0.0049719727, 0.037409738, -0.018836316, 0.009595611, 0.00017889078,
      0.013499579, -0.035464328, 0.022529969, -0.011725049, -0.036042694, 0.0014952066, 0.004301594,
      -0.035911247, 0.0046827896, -0.025053747, 0.0024892725, -0.01946726, -0.03344005, 0.025421798,
      0.019427827, 0.014748323, -0.031994134, -0.021386383, 0.040879935, -0.025408654, 0.014722033,
      0.014958638, -0.0014516648, -0.0002041532, -0.012303414, 0.017679585, 0.0038546755,
      0.013880774, -0.018428832, -0.0049654003, 0.018888894, 0.004886532, -0.008892371,
      -0.0017893186, 0.0012093099, -0.022990033, 0.0011312634, 0.004475761, -0.009792781,
      0.014708889, 0.0016529426, -0.000044414617, -0.0052085766, -0.0029345483, 0.0021935173,
      0.0038973955, -0.010798349, -0.012290269, -0.016798893, -0.022543114, 0.04174748, 0.013197252,
      -0.013210396, -0.012835774, -0.011777627, -0.025658403, 0.027524946, 0.01043687, 0.012060237,
      0.017232666, 0.0005631671, 0.0135784475, 0.014419707, 0.0038251, 0.03012759, -0.016956627,
      0.0028638956, -0.006112273, -0.015563292, 0.010686619, 0.023108335, -0.025448088,
      0.0094115855, 0.042746477, 0.0052874447, 0.0003047921, 0.025684692, -0.020058772, 0.018152794,
      -0.028261047, 0.015747318, 0.008793786, 0.00742674, -0.01077206, -0.013788762, -0.0038743925,
      0.008314006, 0.0067037833, 0.00863605, -0.0025829282, -0.008182559, 0.0063587357, 0.008741207,
      -0.0049884035, -0.014735178, 0.006940387, -0.0024136906, 0.02013764, 0.007696206,
      0.0061648516, 0.013394422, 0.0039894083, -0.026263056, -0.016943483, -0.0033683225,
      0.00031978523, -0.01369675, 0.005763939, -0.026249912, -0.012638603, -0.007748785,
      -0.0019158361, -0.008530892, 0.0007389771, 0.013341843, -0.031862687, -0.018810026,
      0.019993048, -0.008918661, -0.008642622, 0.0027603814, -0.0084191635, -0.01608908,
      0.0013497936, -0.029049728, -0.0057113604, 0.010936368, -0.0031202168, 0.025855571,
      -0.0076436275, -0.02630249, 0.0018254665, -0.017613862, 0.0035260585, 0.013788762, 0.2094735,
      -0.0016463703, -0.009661335, 0.03299313, 0.005142853, 0.008866082, 0.018363109, 0.0056522093,
      -0.009207844, 0.025513811, 0.011186116, 0.019348959, 0.0068220855, -0.0072690044, -0.01994047,
      -0.0106734745, -0.027288342, -0.019151788, -0.0029164744, -0.018008202, -0.000015763339,
      -0.005501046, -0.026591673, -0.01744298, 0.04427126, 0.027945574, -0.0032779528, 0.016864616,
      0.023397518, -0.002814603, -0.0037659488, -0.018862605, -0.0049654003, 0.016430842,
      0.013723039, -0.020190218, -0.020413678, -0.021425817, -0.0015839331, 0.010404008, 0.00714413,
      0.016496565, -0.010450015, 0.0017022352, -0.026617963, 0.023358084, -0.0007611587,
      -0.014156813, -0.0079590995, 0.010910079, -0.030521931, 0.009247278, 0.018980907,
      0.0032155158, -0.011041526, 0.004866815, 0.0069798212, -0.016956627, -0.027156895,
      0.008774069, 0.0073741614, 0.011159827, -0.029996144, 0.014025366, -0.0062568644, 0.031862687,
      -0.013072377, 0.01288178, -0.00002639204, 0.0034011842, 0.0020932893, -0.00092669943,
      0.006601912, 0.018547134, -0.017942479, 0.00032717912, 0.01096923, 0.015878765, -0.0038513893,
      0.016049646, -0.020584557, -0.024790853, -0.01670688, 0.006854947, -0.019979903, -0.010450015,
      0.024659406, -0.005570055, -0.0045184814, 0.015116374, 0.0038020967, 0.00406499, -0.01428826,
      -0.0034011842, 0.0098519325, 0.02588186, 0.008300861, 0.020610847, -0.011048098, 0.005481329,
      -0.024843432, 0.0281296, -0.00023044254, 0.021570409, 0.0016808751, 0.0029920563,
      -0.010666902, 0.023239782, 0.00050565915, -0.027814128, -0.0054320362, -0.01890204,
      0.00263715, -0.0012084884, -0.004981831, 0.02692029, 0.017456125, -0.015537004, -0.011048098,
      -0.004456044, 0.012842346, -0.03496483, -0.004672931, -0.000776768, -0.011048098,
      -0.021596698, -0.015957633, 0.010719481, -0.004472475, -0.04211553, 0.001698949, -0.025106326,
      -0.00014315371, 0.0029148313, -0.0008757638, -0.008537465, 0.009661335, -0.013262975,
      0.017324679, 0.03662106, 0.009194699, -0.016378263, 0.00852432, -0.0032352326, -0.007242715,
      -0.0018829744, 0.029522935, -0.004242443, -0.005724505, 0.0012479224, -0.019348959,
      -0.008011678, -0.012217973, 0.00964819, 0.031652372, -0.02154412, -0.016812038, -0.031152874,
      -0.0059841126, 0.012802912, -0.014209392, 0.030022433, 0.01813965, -0.0015149235,
      0.0012273839, -0.0029591946, -0.16846213, 0.03212558, 0.005195432, -0.03265137, 0.024409657,
      0.009595611, 0.009483881, 0.016430842, -0.034807093, 0.0015609299, 0.03102143, -0.02270085,
      -0.022582548, 0.0038678201, -0.0024679124, -0.012434861, 0.016759459, 0.026013307,
      0.025329785, 0.0019815594, 0.019283235, 0.00020066166, -0.000119328986, -0.015589582,
      0.00002170668, -0.0075253253, -0.022464246, 0.012474295, -0.00191255, -0.0071375575,
      -0.010594606, 0.01032514, 0.011435865, 0.011501589, -0.004531626, -0.0034899106,
      -0.0063193017, -0.015392412, 0.004081421, 0.009359007, 0.02132066, 0.016562289, -0.0074924636,
      0.011462155, 0.0057540806, -0.0031120013, 0.019164933, -0.019835312, 0.0112847015,
      -0.0038941093, 0.0040912796, -0.017758453, 0.020400533, 0.011113821, 0.020006193, 0.010009669,
      0.0056982157, 0.0089909565, -0.00081168354, 0.0043640314, -0.016075935, -0.019598708,
      0.023897016, -0.011777627, -0.012441433, -0.027419789, -0.004525054, 0.007407023,
      -0.026631108, 0.012480867, -0.0043837484, -0.002129437, -0.00032964375, -0.015931344,
      0.018783737, 0.015957633, -0.004659787, 0.0055503384, -0.0112649845, -0.011731621,
      -0.005586486, 0.011823634, -0.019217512, 0.010752343, 0.00034956614, 0.0011074387,
      0.011961653, 0.0038185276, 0.004791233, -0.0070586894, 0.01988789, -0.033676654, -0.023857582,
      -0.014367128, 0.018612858, 0.009727058, 0.009102686, 0.019243801, 0.0038875372, -0.009615328,
      -0.0028277477, -0.008478314, -0.028655387, 0.003028204, 0.02511947, -0.006266723,
      0.0033173868, 0.0063784528, 0.02894457, -0.0028474648, -0.013604737, -0.011100677,
      0.013256403, -0.0072164256, 0.01853399, 0.0037626626, 0.007262432, -0.006874664, 0.007012683,
      -0.003457049, 0.04261503, 0.0056784987, -0.019559273, 0.0023578256, 0.017140653, 0.001670195,
      -0.12008973, 0.008281144, -0.0012873564, 0.0069469595, -0.0051297084, 0.01631254, 0.02494859,
      0.0049719727, -0.0033650363, 0.032388475, -0.0034209013, -0.022201354, -0.016194237,
      -0.018376254, -0.0017827463, -0.0050837025, -0.015182097, -0.010877217, -0.024462236,
      0.025290351, -0.01175791, -0.023082046, 0.00084865297, -0.0041307136, -0.0078013632,
      -0.0012257408, -0.015116374, -0.0035884958, -0.005839521, 0.014051655, 0.0013818338,
      0.008931805, 0.0011945221, -0.01766644, -0.0053203064, -0.0028458217, -0.023226637,
      -0.042746477, 0.0025221342, -0.012434861, 0.014603732, 0.018994052, 0.016023356, -0.008964667,
      0.0009817427, -0.022332799, -0.015523859, 0.016825182, 0.02045311, -0.027971864, -0.025776703,
      -0.028891992, -0.021399528, -0.0054221777, 0.024173053, -0.013788762, -0.00277024,
      0.035280302, -0.00106554, 0.0062798676, 0.0066676354, -0.0009119117, -0.026420793, 0.01988789,
      0.025198339, -0.03259879, -0.021084055, -0.02087374, 0.0044987644, -0.029128596, -0.01884946,
      0.0026404362, -0.011547595, -0.008971239, -0.025290351, 0.0084191635, 0.009004101,
      -0.007282149, -0.012599169, -0.012336276, -0.0011674112, -0.0062732953, 0.017955624,
      -0.011383287, 0.029917276, -0.004975259, 0.0004039933, -0.011817061, -0.005662068,
      0.012079954, 0.0038941093, 0.03893452, -0.011698759, -0.011166399, 0.016838327, 0.03409728,
      0.0027883137, -0.009266995, 0.02247739, 0.0146300215, -0.009753347, -0.0045053368,
      -0.021859592, 0.01702235, 0.0014048369, -0.0040485593, -0.0070652617, -0.0041471445,
      0.009740203, -0.021649277, -0.0026026452, 0.011113821, 0.00059479644, 0.031442057,
      0.0074924636, 0.01507694, -0.018704869, -0.016102225, 0.009608756, 0.017206376, 0.036515903,
      0.02067657, 0.0033814672, -0.0059841126, -0.0072558597, 0.0004695113, -0.0067432174,
      0.0060662664, -0.014840336, 0.033702943, -0.015063795, -0.009345862, 0.023594689,
      -0.010870645, -0.0051034195, -0.0015560007, -0.0043311696, -0.01487977, 0.02129437,
      0.008649195, 0.018455122, 0.011718476, -0.0059676818, -0.020203363, 0.0005545409,
      -0.027025448, 0.0010482877, -0.009096114, -0.018323675, 0.0015905055, -0.0011616605,
      -0.02033481, 0.014945493, 0.0062042857, -0.0015675023, -0.012835774, -0.012401999,
      -0.0060104015, -0.0022477391, -0.002205019, -0.007781646, -0.017219521, 0.013762473,
      0.01043687, 0.00852432, 0.0044987644, 0.008235138, 0.00029945208, 0.00046170663, 0.0029920563,
      0.0025451374, -0.027419789, -0.011376714, 0.010147688, 0.0010014598, -0.013801906,
      0.0014130523, 0.010601179, -0.016023356, 0.009628473, -0.0030446348, -0.01122555,
      0.0036772222, -0.013604737, -0.019309524, 0.0129277855, 0.0156027265, -0.0028770403,
      -0.007321583, 0.0024711986, -0.009477309, 0.023108335, -0.049529128, 0.014984927,
      0.0010737554, 0.005037696, 0.018323675, 0.01927009, -0.015786752, -0.0014426279, 0.023358084,
      0.013893919, 0.013617882, -0.007242715, -0.01294093, -0.02475142, -0.008852937, 0.022832297,
      -0.0065329024, -0.04385063, -0.003218802, -0.0026240053, 0.0062535782, 0.027630102,
      0.015931344, -0.011810489, -0.0062634367, -0.007597621, -0.019204367, -0.0081891315,
      -0.00964819, 0.0020357813, 0.01769273, 0.012388854, 0.00265851, -0.017403547, 0.010417153,
      -0.009477309, 0.0023791858, -0.032467343, 0.005855952, -0.0024350507, 0.022661416,
      -0.012835774, -0.03583238, -0.0017663154, -0.009963662, -0.01927009, 0.01856028, 0.034833383,
      -0.025829282, 0.046058934, 0.00036127312, -0.0027160181, -0.014577443, -0.01668059,
      0.029549224, -0.0056522093, -0.018389398, -0.0044987644, -0.000192857, 0.01294093,
      -0.014761467, 0.0001899816, -0.014945493, 0.0015601084, 0.0062962985, 0.013151245,
      0.00042062954, 0.0019191223, -0.0016808751, 0.0172721, -0.011166399, 0.018179083,
      -0.011409576, -0.008912088, -0.015247821, 0.021872737, -0.021241792, 0.0014450925,
      -0.012592597, -0.00243998, -0.00046581434, -0.014130523, -0.020952608, 0.017482415,
      -0.008175987, 0.011468727, -0.027971864, 0.01257288, -0.0030364194, 0.022069907, 0.01746927,
      -0.0018829744, -0.027051738, 0.0075253253, -0.013157818, -0.014695744, -0.035884958,
      -0.0053367373,
    ],
  },
};
