// Function to group data by seriesKey
const getGroupedData = (data, categoryKey, valueKey, seriesKey) => {
  let groupedData = {};
  data.forEach((item) => {
    let seriesName = item[seriesKey];
    if (!groupedData[seriesName]) {
      groupedData[seriesName] = [];
    }
    groupedData[seriesName].push({
      name: item[categoryKey],
      value: item[valueKey],
    });
  });

  return Object.keys(groupedData).map((seriesName) => ({
    name: seriesName,
    type: "line",
    data: groupedData[seriesName].sort((a, b) => a.name - b.name), // Sort by categoryKey
  }));
};

export const transformDataForLineChart = (data, selectedColumns, isDemo) => {
  let series = [];
  let categories = [];
  let yAxisScale = {};

  if (isDemo) {
    // Demo mode logic
    let categoryKey = "time";
    let valueKey = "score";
    let seriesKey = "school";
    series = getGroupedData(data, categoryKey, valueKey, seriesKey);
    categories = [...new Set(data.map((item) => item[categoryKey]))];

    let scores = data.map((item) => item[valueKey]);
    let minScore = Math.min(...scores);
    let maxScore = Math.max(...scores);
    yAxisScale = { min: minScore, max: maxScore };
  } else {
    let allNumber = false; // Flag to indicate if both columns are of type number

    // Check if there are exactly two selectedColumns and both are of type 'number'
    if (Object.keys(selectedColumns).length === 2) {
      const types = Object.keys(selectedColumns).map((key) => selectedColumns[key].type);
      allNumber = types.every((type) => type === "number");
    }

    let categoryKey = allNumber
      ? Object.keys(selectedColumns)[0]
      : Object.keys(selectedColumns).find((key) => selectedColumns[key].type === "timestamp");
    let valueKeys = allNumber
      ? [Object.keys(selectedColumns)[1]]
      : Object.keys(selectedColumns).filter((key) => selectedColumns[key].type === "number");
    let seriesKey = Object.keys(selectedColumns).find(
      (key) => selectedColumns[key].type === "string"
    );

    if (seriesKey) {
      series = getGroupedData(data, categoryKey, valueKeys[0], seriesKey);
    } else {
      series = valueKeys.map((valueKey) => ({
        name: selectedColumns[valueKey].name,
        type: "line",
        data: data.map((item) => ({
          name: item[categoryKey],
          value: item[valueKey],
        })),
      }));
    }

    categories = [...new Set(data.map((item) => item[categoryKey]))];
  }

  return { series, categories, yAxisScale };
};
