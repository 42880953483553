import { MutableRefObject, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";

import { Box } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";

import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { CANCELED, DEFAULT_QUERY_OPTIONS, EMPTY_ROWS, ERROR } from "../../../Constants/constants";
import { useAuth } from "../../../context";
import { useChatData } from "../../../context/chat";
import { useQuery } from "../../../hooks/useQuery";
import { Doowii } from "../../../search/Doowii";
import { Theme } from "../../../styled";
import { ErrorDisplay } from "../ChartContent/ErrorDisplay/ErrorDisplay";
import { LoadingPanda } from "../ChatTab/ResultArea/Whiteboard/LoadingStatus/LoadingPanda";
import CancelDisplay from "./CancelDisplay";
import EmptyTableOverlay from "./EmptyTableOverlay";
import {
  applyColumnOrderFromRef,
  applyColumnWidthsFromRef,
  determineColumns,
  getEstimateReadingTime,
  handleColumnOrderChange,
  handleColumnResize,
} from "./helpers";

interface DataTableProps {
  result: any;
  setTotalRows?: any;
  isMini?: boolean;
  width?: number | string;
  height?: number | string;
  selectable?: boolean;
  loadingText?: string;
  chartType?: string;
  setPageData?: any;
  doowii?: MutableRefObject<Doowii | null>;
}

export const DataTable = ({
  result,
  setTotalRows = () => {},
  width = "100%",
  height = "100%",
  selectable = true,
  loadingText = "",
  chartType,
  setPageData,
  doowii,
}: DataTableProps) => {
  const { _ } = useLingui();
  const tableRef = useRef(null);
  const [tableRenderedCheck, setTableRenderedCheck] = useState(0);
  const [paginationModel, setPaginationModel] = useState(DEFAULT_QUERY_OPTIONS);
  const [sortModel, setSortModel] = useState(
    JSON.parse(localStorage.getItem(`sortModel-${result?.id}`) || "[]")
  );

  const [shouldFetchData, setShouldFetchData] = useState(false);
  const prevSqlRef = useRef();
  const columnWidthsRef = useRef({});
  const columnOrderRef = useRef([]);

  const { baseURL } = useAuth();
  const [queryOptions, setQueryOptions] = useState({ paginationModel: paginationModel });
  const {
    loading,
    rows,
    pageInfo: _pageInfo,
    queryError,
  } = useQuery(result, queryOptions, shouldFetchData);
  const { loading: chatLoading, currentResult, allResults, answer } = useChatData();

  const [progressValue, setProgressValue] = useState<number>(0);
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showTableComponent, setShowTableComponent] = useState<boolean>(true);
  const [debouncedProgress, setDebouncedProgress] = useState(0);
  const timeCheck = useRef(0);

  useLayoutEffect(() => {
    document.querySelector(".MuiTablePagination-displayedRows")?.setAttribute("tabindex", "0");
  });

  useEffect(() => {
    if (setPageData && pageInfo?.totalRowCount !== undefined) {
      setPageData({ rows, pageInfo });
    }
  }, [rows]);

  useEffect(() => {
    // Handle the initial loading phase
    if (chatLoading) {
      setShowProgress(true);
      setProgressValue(0);
      let intervalDuration = 400; // Initial duration

      let interval = setInterval(() => {
        setProgressValue((prevProgress) => {
          if (prevProgress >= 80) {
            intervalDuration = 3500; // Slow down once we hit 80 percent
            clearInterval(interval); // Clear the existing interval
            // Start a new interval with the slower pace
            interval = setInterval(() => {
              setProgressValue((slowProgress) => {
                const nextProgress = slowProgress + 1;
                return nextProgress <= 90 ? nextProgress : slowProgress;
              });
            }, intervalDuration);
          }

          const nextProgress = prevProgress + 1;
          return nextProgress <= 90 ? nextProgress : prevProgress;
        });
      }, intervalDuration);

      return () => clearInterval(interval);
    }
  }, [chatLoading]);

  useEffect(() => {
    if (chartType && (chartType === EMPTY_ROWS || chartType === ERROR || chartType === CANCELED)) {
      if (showProgress) {
        setProgressValue(100);
        setTimeout(() => {
          setShowTableComponent(false);
          setShowProgress(false);
        }, 500);
      } else {
        setShowTableComponent(false);
      }
    } else {
      setShowTableComponent(true);
    }
  }, [chartType]);

  useEffect(() => {
    // Skip this effect if still in initial loading phase
    if (chatLoading || !showProgress) return;

    if (loading) {
      let interval = setInterval(() => {
        setProgressValue((prevProgress) => {
          const nextProgress = prevProgress + (100 - prevProgress) * 0.1; // Dynamically adjust increment
          return nextProgress < 100 ? nextProgress : prevProgress;
        });
      }, 200);

      return () => clearInterval(interval);
    } else if (!loading && progressValue < 100) {
      if (pageInfo?.totalRowCount !== undefined || queryError) {
        setProgressValue(100);

        setTimeout(() => {
          setShowProgress(false);
        }, 100);
      }
    }
  }, [loading, chatLoading, progressValue, queryError]);

  useEffect(() => {
    const currentTime = new Date().getTime();

    if (currentTime - timeCheck.current > 2000 || progressValue >= 100) {
      setDebouncedProgress(progressValue);
      timeCheck.current = currentTime;
    }
  }, [progressValue]);

  const pageInfo = _pageInfo as any;

  const [rowCountState, setRowCountState] = useState(pageInfo?.totalRowCount || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      pageInfo?.totalRowCount !== undefined ? pageInfo?.totalRowCount : prevRowCountState
    );
    if (setTotalRows) {
      setTotalRows((prevRowCountState) =>
        pageInfo?.totalRowCount !== undefined ? pageInfo?.totalRowCount : prevRowCountState
      );
    }
  }, [pageInfo?.totalRowCount, setRowCountState, setTotalRows]);

  const handlePaginationModelChange = (paginationModel) => {
    setQueryOptions((prev) => {
      return {
        ...prev,
        paginationModel: paginationModel,
      };
    });
    setPaginationModel(paginationModel);
    setShouldFetchData(true);
  };

  const handleSortModelChange = (newSortModel) => {
    if (rows.length === 0) {
      return;
    }
    setPaginationModel((prev) => {
      return {
        ...prev,
        page: 0,
      };
    });

    setQueryOptions((prev) => {
      return {
        ...prev,
        sortModel: newSortModel,
        paginationModel: { ...prev.paginationModel, page: 0 },
      };
    });

    setShouldFetchData(true);

    setSortModel(newSortModel);
    localStorage.setItem(`sortModel-${result?.id}`, JSON.stringify(newSortModel));
  };

  const handleColumnHeaderClick = (event, params) => {
    // Stop the default sorting behavior
    event.stopPropagation();
    // handleColumnSelect(params);
  };

  const onColumnWidthChange = (params) => handleColumnResize(params, columnWidthsRef);
  const onColumnOrderChange = (params) => handleColumnOrderChange(params, columnOrderRef);

  const rawColumns = determineColumns(result, pageInfo, [], handleColumnHeaderClick, baseURL);
  const orderedColumns = applyColumnOrderFromRef(rawColumns, columnOrderRef);
  const columns = applyColumnWidthsFromRef(orderedColumns, columnWidthsRef);

  useEffect(() => {
    const isSqlChanged = prevSqlRef.current !== result?.sql;
    if (isSqlChanged) {
      prevSqlRef.current = result?.sql;
      const localSortModel = JSON.parse(localStorage.getItem(`sortModel-${result?.id}`) || "[]");
      setShouldFetchData(true);

      setPaginationModel(DEFAULT_QUERY_OPTIONS);
      setQueryOptions((prev) => {
        return {
          ...prev,
          paginationModel: DEFAULT_QUERY_OPTIONS,
          sortModel: localSortModel,
        };
      });
      setSortModel(localSortModel);
      columnOrderRef.current = [];
      columnWidthsRef.current = {};
    }
  }, [result?.sql]);

  useEffect(() => {
    const localSortModel = JSON.parse(localStorage.getItem(`sortModel-${result?.id}`) || "[]");
    setShouldFetchData(true);

    setPaginationModel(DEFAULT_QUERY_OPTIONS);
    setQueryOptions((prev) => {
      return {
        ...prev,
        paginationModel: DEFAULT_QUERY_OPTIONS,
        sortModel: localSortModel,
      };
    });
    setSortModel(localSortModel);
  }, [result?.id, result?.status]);

  let colors = {
    headerBackground: Theme.canvas.colors.blue,
    selectedBackground: Theme.canvas.colors.lightBlue,
    selectedRowBackground: Theme.canvas.colors["lightBlue:hover"],
  };

  const getSummaryAnswer = useCallback((): string => {
    const currentResultIndex = allResults.findIndex((res) => res.id === currentResult.id);
    return answer[currentResultIndex];
  }, [allResults, currentResult, answer]);

  useEffect(() => {
    let timeout;
    if (!showProgress) {
      if (tableRef.current) {
        const summaryAnswer = getSummaryAnswer();
        if (summaryAnswer) {
          const estimateReadingTime = getEstimateReadingTime(summaryAnswer);
          timeout = setTimeout(() => {
            const chatInput = document.getElementById("chat-input");
            (chatInput as HTMLTableCellElement)?.focus();
          }, estimateReadingTime);
        }
      } else {
        if (tableRenderedCheck < 5) {
          setTableRenderedCheck((e) => e + 1);
        }
      }
    }

    return () => clearTimeout(timeout);
  }, [tableRenderedCheck, showProgress, getSummaryAnswer]);

  if (!showTableComponent) {
    return (
      <>
        {chartType === EMPTY_ROWS && <ErrorDisplay version={EMPTY_ROWS} />}
        {chartType === ERROR && <ErrorDisplay version={ERROR} />}
        {chartType === CANCELED && <CancelDisplay results={result} doowii={doowii} />}
      </>
    );
  }

  const isError = chartType === EMPTY_ROWS || chartType === ERROR || chartType === CANCELED;

  const debouncedProgressRounded = Math.round(debouncedProgress);
  const percentProgressText =
    debouncedProgressRounded >= 100 ? "100" : `${debouncedProgressRounded}`;
  return (
    <>
      {!isError && (
        <div
          aria-live="assertive"
          aria-atomic="true"
          style={{ position: "absolute", left: "-999999px" }}
        >
          {`${
            debouncedProgressRounded >= 100
              ? _(
                  msg`${percentProgressText} % - Data table was loaded successfully with columhs: ${columns.map(
                    (column) => `${column.headerName} - `
                  )} and ${rowCountState} rows. Loading result summary, please wait.`
                )
              : _(msg`${percentProgressText} %`)
          }`}
        </div>
      )}

      {showProgress && (
        <LoadingPanda
          loadingText={loadingText}
          progressValue={progressValue}
          setProgressValue={setProgressValue}
          doowii={doowii}
        />
      )}
      {!showProgress && (
        <Box
          sx={{
            height,
            width,
            maxWidth: width,
            "& .MuiDataGrid-columnHeaderTitleContainer": {
              cursor: selectable ? "pointer" : "default",
            },
            "& .MuiDataGrid-columnHeaders": {
              height: "35px",
              minHeight: "35px !important",
              maxHeight: "35px !important",
            },
            "& div.MuiDataGrid-columnHeader div.MuiDataGrid-columnHeaderTitleContainer button": {
              display: "none",
            },
            "& div.MuiDataGrid-columnHeader div.MuiDataGrid-menuIcon button": {
              color: "white",
              padding: "2px",
              margin: "0px 5px 0px 0px",
              ":hover": {
                backgroundColor: "white",
                color: colors.headerBackground,
              },
            },
            "& div.MuiDataGrid-columnHeader div.MuiDataGrid-columnSeparator ": {
              color: "white",
            },
            "& div.MuiDataGrid-columnHeader": {
              position: "relative",
              "::before": {
                pointerEvents: "none",
                content: '""',
                position: "absolute",
                borderStyle: "solid",
                borderWidth: "0.125rem",
                borderColor: "#0374B5",
                opacity: 0,
                borderRadius: "calc(0.25rem + (0.3125rem - 0.125rem))",
                top: "12px",
                left: "4px",
                right: "4px",
                bottom: "12px",
                WebkitTransition: "all 0.2s",
                transition: "all 0.2s",
                WebkitTransform: "scale(0.95)",
                MozTransform: "scale(0.95)",
                msTransform: "scale(0.95)",
                transform: "scale(0.95)",
              },
              ":focus": {
                color: "black",
                fontWeight: "bold",
                backgroundColor: "#F5F5F5",
                outline: "none",
                "::before": {
                  opacity: 1,
                  transform: "scale(1)",
                },
              },
              ":focus-within": {
                outline: "none",
                color: "black",
                fontWeight: "bold",
                backgroundColor: "#F5F5F5",
                "::before": {
                  opacity: 1,
                  transform: "scale(1)",
                },
              },
            },
            "& .column-header": {
              backgroundColor: colors.headerBackground,
              color: "white",
            },
            "& .column-header-selected": {
              backgroundColor: colors.headerBackground,
              color: "white",
            },
            "& .row.selected": {
              backgroundColor: "#e3f3f7",
              color: "black",
            },
          }}
        >
          <DataGridPro
            slots={{
              noRowsOverlay: EmptyTableOverlay,
              noResultsOverlay: EmptyTableOverlay,
            }}
            data-testid="table"
            rowHeight={30}
            sx={{
              ".MuiDataGrid-columnHeaderTitleContainer": { width },
              ".MuiDataGrid-columnHeaderTitleContainerContent": { width },
            }}
            loading={loading}
            rows={rows}
            columns={columns}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            pageSizeOptions={[50]}
            rowCount={rowCountState}
            onPaginationModelChange={handlePaginationModelChange}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            disableColumnFilter
            onColumnWidthChange={onColumnWidthChange}
            onColumnOrderChange={onColumnOrderChange}
            ref={tableRef}
          />
        </Box>
      )}
    </>
  );
};
