import { IconButton, Menu, Tooltip } from "@instructure/ui";
import {
  IconCheckMarkSolid,
  IconEditLine,
  IconEndLine,
  IconTrashLine,
  IconShareLine,
  IconArrowOpenDownLine,
  IconMoveUpTopLine,
  IconMoveUpLine,
  IconMoveDownLine,
  IconMoveDownBottomLine,
} from "@instructure/ui-icons";
import { CHAT_VIEW } from "../../../../../Constants/constants";
import { usePinboard } from "../../../../../context/pinboard";
import { useUI } from "../../../../../context/ui";
import { withSentry } from "../../../../../helpers/wrapper";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsUpDownLeftRight } from "@fortawesome/free-solid-svg-icons";
import { Box } from "@mui/material";
import { useAuth } from "../../../../../context";
import { PINBOARD_UPGRADES } from "../../../../../Constants/features";

interface ActionButtonsProps {
  value: string;
  results: any;
  readOnly: boolean;
  setDeleteChartModalOpen: (value: boolean) => void;
  setReadOnly: (value: boolean) => void;
  updateTitle: (pinboardId: string, pinId: string, title: string) => Promise<any>;
  setValue: (value: string) => void;
}

export const ActionButtons = ({
  value,
  setValue,
  readOnly,
  setReadOnly,
  setDeleteChartModalOpen,
  updateTitle,
  results,
}: ActionButtonsProps) => {
  const { _ } = useLingui();
  const { view } = useUI();
  const { currBoardId } = usePinboard();
  const { featureFlags } = useAuth();

  const handleEndClick = () => {
    setReadOnly(true);
    setValue(results?.title);
  };

  const handleCheckClick: any = withSentry(async () => {
    view === CHAT_VIEW
      ? await (updateTitle as any)(value) // ?? why doesn't it use all arguments?
      : await updateTitle(currBoardId!, results?.id, value);

    setReadOnly(true);
  });

  return (
    <>
      {featureFlags.includes(PINBOARD_UPGRADES) && (
        <Tooltip renderTip={<Trans>Share</Trans>}>
          <IconButton
            margin="x-small"
            withBackground={false}
            withBorder={false}
            onClick={() => {}}
            screenReaderLabel={_(msg`Share`)}
          >
            <IconShareLine />
          </IconButton>
        </Tooltip>
      )}
      {readOnly && (
        <Tooltip renderTip={<Trans>Edit name</Trans>}>
          <IconButton
            margin="x-small"
            withBackground={false}
            withBorder={false}
            onClick={() => setReadOnly(false)}
            screenReaderLabel={_(msg`Edit name`)}
          >
            <IconEditLine />
          </IconButton>
        </Tooltip>
      )}
      {!readOnly && (
        <>
          <Tooltip renderTip={<Trans>Confirm</Trans>}>
            <IconButton
              margin="xx-small"
              withBackground={false}
              withBorder={false}
              onClick={handleCheckClick}
              screenReaderLabel={_(msg`Submit new name`)}
            >
              <IconCheckMarkSolid color="success" />
            </IconButton>
          </Tooltip>
          <Tooltip renderTip={<Trans>Cancel</Trans>}>
            <IconButton
              margin="xx-small"
              withBackground={false}
              withBorder={false}
              onClick={handleEndClick}
              screenReaderLabel={_(msg`Cancel edit name`)}
            >
              <IconEndLine color="error" />
            </IconButton>
          </Tooltip>
        </>
      )}

      {featureFlags.includes(PINBOARD_UPGRADES) && (
        <Box sx={{ "& button > span": { width: "auto" } }}>
          <Menu
            withArrow={false}
            placement="bottom"
            trigger={
              <Tooltip renderTip={<Trans>Open move menu</Trans>}>
                <IconButton
                  margin="x-small"
                  withBackground={false}
                  withBorder={false}
                  onClick={() => {}}
                  screenReaderLabel={_(msg`Open move menu`)}
                >
                  <span className="flex w-[60px] justify-center gap-2">
                    <FontAwesomeIcon icon={faArrowsUpDownLeftRight} size="xs" />
                    <IconArrowOpenDownLine size="x-small" />
                  </span>
                </IconButton>
              </Tooltip>
            }
          >
            <Menu.Item value="mastery">
              <span className="flex items-center  gap-2">
                <IconMoveUpTopLine />
                <Trans>Move to Top</Trans>
              </span>
            </Menu.Item>

            <Menu.Item value="mastery">
              <span className="flex items-center  gap-2">
                <IconMoveUpLine />
                <Trans>Move up</Trans>
              </span>
            </Menu.Item>

            <Menu.Item value="mastery">
              <span className="flex items-center  gap-2">
                <IconMoveDownLine />
                <Trans>Move down</Trans>
              </span>
            </Menu.Item>

            <Menu.Item value="mastery">
              <span className="flex items-center  gap-2">
                <IconMoveDownBottomLine />
                <Trans>Move to bottom</Trans>
              </span>
            </Menu.Item>
          </Menu>
        </Box>
      )}

      <Tooltip renderTip={<Trans>Remove Chart from Pinboard</Trans>}>
        <IconButton
          margin="xx-small"
          withBackground={false}
          withBorder={false}
          onClick={() => setDeleteChartModalOpen(true)}
          screenReaderLabel={_(msg`Remove Pin`)}
        >
          <IconTrashLine />
        </IconButton>
      </Tooltip>
    </>
  );
};
