import Editor from "@monaco-editor/react";
import { FC } from "react";

interface SQLEditorProps {
  editorSQL: string;
  setEditorSQL: Function;
  isValid: boolean;
  setIsValid: (isValid: boolean) => void;
  performingUpdate: boolean;
  validationMessage: string | null;
  setValidationMessage: (validationMessage: string) => void;
}

export const SQLEditor: FC<SQLEditorProps> = ({
  editorSQL,
  setEditorSQL,
  isValid,
  setIsValid,
  performingUpdate,
  validationMessage,
  setValidationMessage,
}) => {
  const handleChange = async (value) => {
    if (isValid !== null) {
      setIsValid(true);
    }

    if (validationMessage !== "") {
      setValidationMessage("");
    }

    setEditorSQL(value);
  };

  return (
    <Editor
      defaultLanguage="sql"
      defaultValue={editorSQL}
      onChange={handleChange}
      options={{
        minimap: { enabled: false },
        readOnly: performingUpdate,
        lineNumbers: "off",
        glyphMargin: false,
        folding: false,
        lineDecorationsWidth: 0,
        lineNumbersMinChars: 0,
      }}
    />
  );
};
