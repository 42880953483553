import { format } from "sql-formatter";

export const formatSqlFn = (sql: string) => {
  let formattedSQL: string;
  try {
    formattedSQL = sql.includes("\n") ? sql : format(sql, { language: "bigquery" });
  } catch (e) {
    formattedSQL = sql;
    console.error(e);
  }
  return formattedSQL;
};
