import { IconButton, IconLikeLine, IconLikeSolid, Text } from "@instructure/ui";
import { FC, useState } from "react";

import { DISSATISFIED, SATISFIED } from "../../../Constants/constants";
import { useChatData } from "../../../context/chat";
import { FeedbackModal } from "./FeedbackModal";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

interface FeedbackProps {
  index: number;
  disabled?: boolean;
}

export const Feedback: FC<FeedbackProps> = ({ index, disabled }) => {
  const { _ } = useLingui();
  const { allResults, updateIndividualSatisfaction } = useChatData();
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [feedbackPositive, setFeedbackPositive] = useState(false);

  const feedbackResult = allResults[index];
  let hasLike = false;
  let likeIsFalse = false;

  if (feedbackResult?.satisfied && typeof feedbackResult.satisfied === "object") {
    hasLike = !!feedbackResult.satisfied.like;
    likeIsFalse = (feedbackResult.satisfied.like as any) === false; // ?? like is a string, how can it be false?
  }

  const openFeedBackModal = async (like) => {
    setFeedbackPositive(like === SATISFIED);
    setFeedbackModalOpen(true);
    // @ts-ignore
    await updateIndividualSatisfaction(index, like === SATISFIED, "", like === DISSATISFIED); // ? like should be a string
  };

  return (
    <>
      <div className="flex items-center gap-3 py-3">
        <Text as="span" size="x-small" weight="bold">
          <Trans>How did I do?</Trans>
        </Text>

        <IconButton
          renderIcon={hasLike ? <IconLikeSolid color="success" /> : IconLikeLine}
          screenReaderLabel={_(msg`Give Positive Feedback`)}
          onClick={() => openFeedBackModal(SATISFIED)}
          disabled={disabled}
        />

        <div className="rotate-180">
          <IconButton
            renderIcon={likeIsFalse ? <IconLikeSolid color="error" /> : IconLikeLine}
            screenReaderLabel={_(msg`Give Negative Feedback`)}
            onClick={() => openFeedBackModal(DISSATISFIED)}
            disabled={disabled}
          />
        </div>
      </div>

      <FeedbackModal
        index={index}
        open={feedbackModalOpen}
        setOpen={setFeedbackModalOpen}
        like={feedbackPositive as any} // ? like should be a string
      />
    </>
  );
};
