import { useEffect, useMemo, useState } from "react";

import { TABLE } from "../../../Constants/constants";
import { DataTable } from "../DataTable/DataTable";
import { ChartRenderer } from "../ChartRenderer/ChartRenderer";
import { allCanvasCharts, ChartProps } from "../ChartBuilder/ChartBuilder";
import { ChartType } from "../../types";
import { Pin } from "../../../context/pinboard/pinboard.i";
import { useChatData } from "../../../context/chat";

interface PinboardChartContentProps {
  pinResult: Pin;
  width: number;
  height: number;
}

export const PinboardChartContent = ({ pinResult, width, height }: PinboardChartContentProps) => {
  const [chartType, setChartType] = useState<ChartType>(ChartType.TABLE);
  const [graphData, setGraphData] = useState<any>({});
  const [totalRows, setTotalRows] = useState(0);
  const { dataMap } = useChatData();

  useEffect(() => {
    const resultObject = dataMap?.[pinResult?.id];
    if (resultObject) {
      const rows = Object.values(resultObject.rows);
      setTotalRows(rows.length);
      setChartType(pinResult.visualisation as unknown as ChartType);
      setGraphData(rows);
    }
  }, [dataMap, pinResult]);

  const chartProps: ChartProps = useMemo(() => {
    return {
      chartConfig: pinResult.chart_config || pinResult.chartConfig,
      data: graphData,
      selectedColumns: pinResult.selectedColumns,
      docId: pinResult.id,
      title: pinResult.title,
      loading: false,
      error: false,
      totalRows,
      chartType,
    } as ChartProps;
  }, [graphData, pinResult]);

  return (
    <>
      {chartType === TABLE && height ? (
        <DataTable result={pinResult} height={height} selectable={false} />
      ) : null}
      {allCanvasCharts.includes(chartType) && <ChartRenderer chartProps={chartProps} />}
    </>
  );
};
