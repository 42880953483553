import { Button, CloseButton, Heading, Modal, Text, Tray, View } from "@instructure/ui";
import React, { PropsWithChildren, useEffect, useState } from "react";

import { useAlert } from "../../../../../../context/alert";
import { TableColumns } from "../../../../../../context/chart/chart.i";
import { useChatData } from "../../../../../../context/chat";
import { usePinboard } from "../../../../../../context/pinboard";
import { Board } from "../../../../../../context/pinboard/pinboard.i";
import { withSentry } from "../../../../../../helpers/wrapper";
import { ButtonLoader } from "../../../../UI/ButtonLoader/ButtonLoader";
import { CreatePinboardSection } from "./CreatePinboardSection";
import { PinboardList } from "./PinboardList";
import useBreakpoint from "../../../../../../hooks/useBreakpoint";
import { Divider } from "@mui/material";
import { useLingui } from "@lingui/react";
import { Trans, msg } from "@lingui/macro";

interface PinboardFormProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  visualisation: string;
  charts: string[];
  selectedColumns: TableColumns;
}

export const PinboardForm = ({
  open,
  setOpen,
  visualisation,
  charts,
  selectedColumns,
}: PinboardFormProps) => {
  const { _ } = useLingui();
  const { isChatDesktop } = useBreakpoint();
  const { boards, pinToBoard, unpinFromBoard } = usePinboard();
  const { currentResult: results } = useChatData();

  const { showAlert } = useAlert();

  const getUserSelectedBoards = (): (keyof Board)[] => {
    if (!results || !boards) {
      throw new Error("Results or boards not found");
    }

    const keysArray: (keyof Board)[] = Array.from(boards.keys());

    return keysArray.filter((key) => boards.get(key).pins.includes(results.id)).map((key) => key);
  };

  const [selectedBoards, setSelectedBoards] = useState(getUserSelectedBoards());
  const [creating, setCreating] = useState(false);
  const [saving, setSaving] = useState(false);
  const [initialSelectedBoards, setInitialSelectedBoards] = useState<string[]>([]);
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    setSelectedBoards(getUserSelectedBoards());
    setInitialSelectedBoards(getUserSelectedBoards());
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setHasChanged(selectedBoards.sort().join() !== initialSelectedBoards.sort().join());
  }, [selectedBoards, initialSelectedBoards]);

  const handleSaveChanges: any = withSentry(async () => {
    if (!results) {
      throw new Error("Results not found");
    }

    setSaving(true);
    let updated = false;
    for (const [key, board] of boards.entries()) {
      try {
        if (selectedBoards.includes(key)) {
          if (!board.pins.includes(results.id)) {
            updated = true;

            const fullResult: any = {
              ...results,
              visualisation,
              visualisationArray: charts,
              selectedColumns,
            };
            await pinToBoard(fullResult, key); // ?? type mismatch
          }
        } else {
          if (board.pins.includes(results.id)) {
            updated = true;
            await unpinFromBoard(results.id, key);
          }
        }
        if (updated) {
          const title = results.title;
          showAlert({
            variant: "success",
            message: _(msg`${title} has been successfully added!`),
          });
        }
      } catch (e) {
        showAlert({
          variant: "error",
          message: _(msg`Failed to add to pinboard. Please try again later.`),
        });
      }
    }
    setSaving(false);
    handleClose();
  });

  const loading = creating || saving;

  return (
    <PinFormContainer isOpen={open} handleClose={handleClose}>
      <div>
        <CloseButton
          placement="end"
          offset="small"
          onClick={handleClose}
          screenReaderLabel={_(msg`Close`)}
          size="medium"
          disabled={loading}
        />
        <Heading>
          <Trans>Add to Pinboard</Trans>
        </Heading>
      </div>
      <div>
        <View as="div" margin="0 0 small 0">
          <Text>
            <Trans>Add to your existing pinboards or create a new board.</Trans>
          </Text>
        </View>
        <PinboardList
          boards={boards}
          selectedBoards={selectedBoards}
          setSelectedBoards={setSelectedBoards}
        />
        <hr className="mb-6 mt-4 border-[#C7CDD1]" />
        <CreatePinboardSection
          setSelectedBoards={setSelectedBoards}
          loading={loading}
          creating={creating}
          setCreating={setCreating}
        />
      </div>
      <div>
        <Button margin="0" disabled={loading} onClick={handleClose}>
          <Trans>Cancel</Trans>
        </Button>
        <Button
          margin="0 0 0 x-small"
          disabled={loading || !hasChanged}
          color="primary"
          type="submit"
          onClick={handleSaveChanges}
          renderIcon={saving && <ButtonLoader />}
        >
          <Trans>Save</Trans>
        </Button>
      </div>
    </PinFormContainer>
  );
};

type PinFormContainerProps = { isOpen: boolean; handleClose: () => void };
const PinFormContainer: React.FC<PropsWithChildren<PinFormContainerProps>> = ({
  children,
  isOpen,
  handleClose,
}) => {
  const { isChatDesktop } = useBreakpoint();
  const Container = isChatDesktop ? PinFormModal : PinFormDrawer;

  return (
    <Container isOpen={isOpen} handleClose={handleClose}>
      {children}
    </Container>
  );
};

const PinFormDrawer: React.FC<PropsWithChildren<PinFormContainerProps>> = ({
  children,
  isOpen,
  handleClose,
}) => {
  const { _ } = useLingui();
  const [header, body, footer] = React.Children.toArray(children);
  return (
    <Tray
      label={_(msg`Pinboard drawer`)}
      open={isOpen}
      onDismiss={handleClose}
      size="large"
      placement="bottom"
    >
      <div className="h-screen  pt-16">
        <div className="relative flex h-full flex-col justify-between gap-6">
          <div className="p-5">
            <div>{header}</div>
            <div className="my-6">
              <Divider />
            </div>
            <div>{body}</div>
          </div>
          <div className="flex justify-end bg-[#F5F5F5] p-4">{footer}</div>
        </div>
      </div>
    </Tray>
  );
};

const PinFormModal: React.FC<PropsWithChildren<PinFormContainerProps>> = ({
  children,
  isOpen,
  handleClose,
}) => {
  const { _ } = useLingui();
  const [header, body, footer] = React.Children.toArray(children);
  return (
    <Modal open={isOpen} onClose={handleClose} label={_(msg`Pinboard Modal`)}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>{footer}</Modal.Footer>
    </Modal>
  );
};
