import {
  Flex,
  IconButton,
  IconSearchLine,
  IconXSolid,
  ScreenReaderContent,
  TextInput,
  Tooltip
} from "@instructure/ui";
import { useDebounce } from "@uidotdev/usehooks";
import { FC, useEffect, useState } from "react";

import { IconBulletListLine } from "@instructure/ui-icons";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import { useAuth } from "../../../../context/auth";
import { usePinboard } from "../../../../context/pinboard";
import { PinView } from "../PinboardTab";
import { EditMode } from "./EditMode/EditMode";
import { SelectMode } from "./SelectMode/SelectMode";

interface SelectionAndSearchProps {
  search: string;
  hasData: boolean;
  setSearch: (search: string) => void;
  setPinView: (view: PinView) => void;
  setCreateModalOpen: (value: boolean) => void;
  setDeleteModalOpen: (value: boolean) => void;
}

export const SelectionAndSearch: FC<SelectionAndSearchProps> = ({
  search,
  hasData,
  setSearch,
  setPinView,
  setCreateModalOpen,
  setDeleteModalOpen,
}) => {
  const { _ } = useLingui();
  const { authUser } = useAuth();
  const { boards, currBoardId, setCurrBoardId } = usePinboard();

  const [editMode, setEditMode] = useState(false);
  const [localSearch, setLocalSearch] = useState("");
  const debouncedSearchTerm = useDebounce(localSearch, 300);

  useEffect(() => {
    setSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (search === "") setLocalSearch("");
  }, [search]);

  const handlePinboardSelect = (_: any, { id }: { id: string }) => {
    if (id === "new") {
      setCreateModalOpen(true);

      return;
    }

    setCurrBoardId(id);
    if (!authUser) {
      throw new Error("User not found!");
    }
  };

  const setPinViewWithStorage = (view: PinView) => {
    setPinView(view);
    localStorage.setItem(`pinView-${currBoardId}`, view);
  };
  

  const currentBoardName = boards.get(currBoardId)?.name;

  return (
    <div className="mx-[3px] my-6 flex flex-col items-center gap-2 md:flex-row md:gap-9">
      <div className="flex w-full min-w-80 gap-2 md:w-2/5">
        {editMode ? (
          <EditMode setEditMode={setEditMode} />
        ) : (
          <SelectMode
            boards={boards}
            currentBoardName={currentBoardName}
            currBoardId={currBoardId!}
            handlePinboardSelect={handlePinboardSelect}
            setEditMode={setEditMode}
            setDeleteModalOpen={setDeleteModalOpen}
          />
        )}
      </div>
      <div className="w-full md:w-3/5">
        <TextInput
          renderBeforeInput={IconSearchLine}
          renderLabel={
            <ScreenReaderContent>
              <Trans>Type to search for a Pinboard</Trans>
            </ScreenReaderContent>
          }
          renderAfterInput={
            !!localSearch && (
              <Tooltip renderTip={_(msg`Clear search`)} on={["hover", "focus"]}>
                <IconButton
                  screenReaderLabel={_(msg`Delete tag`)}
                  withBackground={false}
                  withBorder={false}
                  size="small"
                  onClick={() => setLocalSearch("")}
                >
                  <IconXSolid />
                </IconButton>
              </Tooltip>
            )
          }
          disabled={!hasData}
          placeholder={_(msg`Search within ${currentBoardName}`)}
          value={localSearch}
          onChange={(e) => setLocalSearch(e.target.value)}
          id="pinboard-search-input"
        />
      </div>
      <Flex gap="small">
        <Tooltip renderTip={<Trans>Grid view</Trans>}>
          <IconButton
            margin="x-small"
            withBackground={false}
            withBorder={false}
            onClick={() => setPinViewWithStorage("grid")}
            screenReaderLabel={_(msg`Grid view`)}
          >
            <GridViewOutlinedIcon />
          </IconButton>
        </Tooltip>

        <Tooltip renderTip={<Trans>List view</Trans>}>
          <IconButton
            margin="x-small"
            withBackground={false}
            withBorder={false}
            onClick={() => setPinViewWithStorage("list")}
            screenReaderLabel={_(msg`List view`)}
          >
            <IconBulletListLine />
          </IconButton>
        </Tooltip>
      </Flex>
    </div>
  );
};
