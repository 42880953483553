import { MutableRefObject, useEffect, useState } from "react";

import {
  BAR_CHART,
  LINE_CHART,
  PIE_CHART,
  SCATTER_CHART,
  SCHEMA,
} from "../../../Constants/constants";
import { BarChart } from "../../../components/Charts/BarChart/BarChart";
import { LineChart } from "../../../components/Charts/LineChart/LineChart";
import { PieChart } from "../../../components/Charts/PieChart/PieChart";
import { ScatterChart } from "../../../components/Charts/ScatterChart/ScatterChart";
import { useChatData } from "../../../context/chat";
import { usePinboard } from "../../../context/pinboard";
import { useUI } from "../../../context/ui";
import { ViewEnum } from "../../../context/ui/ui.i";
import { ParentDocTypeEnum } from "../../../api/retriever.i";
import { Chart } from "../../../helpers/Chart";
import { Doowii } from "../../../search/Doowii";
import { DataTable } from "../DataTable/DataTable";
import SchemaExplore from "../SchemaExplore/SchemaExplore";

interface ChartContentProps {
  chartType: string;
  results: any;
  isMini?: boolean;
  totalRows: number;
  setTotalRows: any;
  selectedColumns?: any;
  setSelectedColumns?: any;
  // setTotalRows: (totalRows: number) => void;
  width: number;
  height: number;
  loadingText?: string;
  _setPageData?: any;
  doowii?: MutableRefObject<Doowii | null>;
}

export const ChartContent = ({
  chartType,
  results,
  isMini = false,
  totalRows,
  setTotalRows,
  selectedColumns,
  setSelectedColumns,
  width,
  height,
  loadingText = "",
  _setPageData,
  doowii,
}: ChartContentProps) => {
  const [pageData, setPageData] = useState([]);
  const { dataMap, currentResult, currentThread } = useChatData();
  const { currBoardId } = usePinboard();
  const { view } = useUI();
  const parentDocId = view === ViewEnum.CHAT_VIEW ? currentThread : currBoardId;
  const parentDocType =
    view === ViewEnum.CHAT_VIEW ? ParentDocTypeEnum.THREAD : ParentDocTypeEnum.PINBOARD;
  const sortModel = JSON.parse(
    localStorage.getItem(`sortModel-${(currentResult as any)?.id}`) || "[]"
  );

  useEffect(() => {
    if (dataMap && results) {
      setTimeout(() => {
        const graphData = Chart.buildFromPin(results, dataMap, totalRows);
        setPageData(graphData);

        if (_setPageData) {
          _setPageData(graphData);
        }
      }, 200);
    }
  }, [dataMap, results, totalRows, chartType]);

  return (
    <>
      <DataTable
        result={results}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        setTotalRows={setTotalRows}
        isMini={isMini}
        width={width}
        height={height}
        loadingText={loadingText}
        chartType={chartType}
        doowii={doowii}
      />

      {chartType === PIE_CHART && (
        <PieChart
          data={pageData}
          selectedColumns={selectedColumns}
          docId={results?.id}
          parentDocId={parentDocId}
          parentDocType={parentDocType}
          totalRows={totalRows}
          width={width}
          height={height}
        />
      )}
      {chartType === BAR_CHART && (
        <BarChart
          data={pageData}
          selectedColumns={selectedColumns}
          docId={results?.id}
          parentDocId={parentDocId}
          parentDocType={parentDocType}
          totalRows={totalRows}
          width={width}
          height={height}
          sortModel={sortModel}
        />
      )}
      {chartType === LINE_CHART && (
        <LineChart
          data={pageData}
          selectedColumns={selectedColumns}
          docId={results?.id}
          parentDocId={parentDocId}
          parentDocType={parentDocType}
          totalRows={totalRows}
          width={width}
          height={height}
          sortModel={sortModel}
        />
      )}
      {chartType === SCATTER_CHART && (
        <ScatterChart
          data={pageData}
          height={height}
          width={width}
          selectedColumns={selectedColumns}
          docId={results?.id}
          parentDocId={parentDocId}
          parentDocType={parentDocType}
          totalRows={totalRows}
        />
      )}
      {chartType === SCHEMA && (
        <SchemaExplore
          htmlFilePath={"https://drawsql.app/teams/siddhant-1/diagrams/canvas-full/embed"}
        />
      )}
    </>
  );
};
