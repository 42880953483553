/* eslint-disable lingui/no-unlocalized-strings */
import * as s from "./LoadingPage.s";

export const LoadingPage = () => {
  return (
    <s.LoadingPage>
      <span>d</span>
      <s.Bounce $animationDelay="0s">o</s.Bounce>
      <s.Bounce $animationDelay="0.1s">o</s.Bounce>
      <span>w</span>
      {/* Wrap the letter 'i' and the dot in separate spans */}
      <s.Letter>
        ı<s.Dot $animationDelay="0.2s">.</s.Dot>
      </s.Letter>
      <s.Letter>
        ı<s.Dot $animationDelay="0.23">.</s.Dot>
      </s.Letter>
    </s.LoadingPage>
  );
};
