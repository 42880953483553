import {
  IconAnalyticsLine,
  IconEmptyLine,
  IconPredictiveLine,
  IconTableTopHeaderLine,
  IconTargetLine,
  IconBlueprintLine,
  IconClockLine,
  IconInfoLine,
} from "@instructure/ui-icons";

import {
  BAR_CHART,
  LINE_CHART,
  PIE_CHART,
  SCATTER_CHART,
  TABLE,
  SCHEMA,
} from "../../../Constants/constants";
import { ChartType } from "../../types";
import { ChartColumnType } from "./ChartBuilder";
import { CategoryIcon } from "../../assets/CategoryIcon";
import { HashIcon } from "../../assets/HashIcon";
import { Tooltip } from "@instructure/ui";
import { msg } from "@lingui/macro";
import { Trans } from "@lingui/macro";

const DataTypesObject = {
  numerical: "numerical",
  categorical: "categorical",
  temporal: "temporal",
} as const;

type DataTypes = typeof DataTypesObject;
type DataType = DataTypes[keyof DataTypes];
const allDataTypes = Object.values(DataTypesObject);

const renderChartIcon = ({ type, chartType }: { type: ChartType; chartType?: string }) => {
  switch (type) {
    case TABLE:
      return <IconTableTopHeaderLine color={chartType === TABLE ? "brand" : "primary"} />;
    case BAR_CHART:
      return <IconAnalyticsLine color={chartType === BAR_CHART ? "brand" : "primary"} />;
    case PIE_CHART:
      return <IconEmptyLine color={chartType === PIE_CHART ? "brand" : "primary"} />;
    case LINE_CHART:
      return <IconTargetLine color={chartType === LINE_CHART ? "brand" : "primary"} />;
    case SCATTER_CHART:
      return <IconPredictiveLine color={chartType === LINE_CHART ? "brand" : "primary"} />;
    case SCHEMA:
      return <IconBlueprintLine color={chartType === SCHEMA ? "brand" : "primary"} />;
  }
};

const getColumnLabel = (column: ChartColumnType) => {
  const { name, type } = column;
  const iconStyle = { display: "inline-block", marginRight: "4px" };

  const iconMap = {
    [DataTypesObject.numerical]: <HashIcon style={iconStyle} />,
    [DataTypesObject.categorical]: <CategoryIcon style={iconStyle} />,
    [DataTypesObject.temporal]: <IconClockLine style={iconStyle} />,
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "75%",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {iconMap[type]}
        <span>{name}</span>
      </div>
      <Tooltip
        renderTip={<Trans>{type} data type</Trans>}
        placement="top"
        on={["click", "hover", "focus"]}
      >
        <IconInfoLine />
      </Tooltip>
    </div>
  );
};

const getChartTitle = (type: ChartType) => {
  switch (type) {
    case TABLE:
      return msg`Table`;
    case BAR_CHART:
      return msg`Bar Chart`;
    case PIE_CHART:
      return msg`Pie Chart`;
    case LINE_CHART:
      return msg`Line Chart`;
    case SCATTER_CHART:
      return msg`Scatter Chart`;
    case SCHEMA:
      return msg`Schema`;
  }
};

const getAvailableDataTypes = ({
  selectedTypes,
  chart,
}: {
  selectedTypes: DataType[];
  chart: ChartType;
}): DataType[] => {
  const { categorical, temporal, numerical } = DataTypesObject;

  let availableTypes: DataType[] = [];
  const selectedTypesIncludesNumberType = selectedTypes.some((type) => type === numerical);

  switch (chart) {
    // Bar Chart Rules:
    // - If the selected types include "number", then the available types must include "string"
    // - If the selected types include "string", then the available types must include "number"
    // - If the selected types include "timestamp", then the available types must include "number"
    // - If no types are selected, then the available types can include all types
    case ChartType.BAR_CHART:
      if (selectedTypesIncludesNumberType) {
        availableTypes = [categorical];
      } else if (selectedTypes.includes(categorical)) {
        availableTypes = [numerical];
      } else if (selectedTypes.includes(temporal)) {
        availableTypes = [numerical];
      } else if (selectedTypes.length === 0) {
        availableTypes = allDataTypes;
      }
      break;
    // Pie Chart Rules:
    // - If the selected types include "number", then the available types must include "string"
    // - If the selected types include "string", then the available types must include "number"
    // - If no types are selected, then the available types can include numbers and strings (no timestamps)
    case ChartType.PIE_CHART:
      if (selectedTypesIncludesNumberType) {
        availableTypes = [categorical];
      } else if (selectedTypes.includes(categorical)) {
        availableTypes = [numerical];
      } else if (selectedTypes.length === 0) {
        availableTypes = [numerical, categorical];
      }
      break;
    // Line Chart Rules:
    // - If the selected types include "number", then the available types must include "timestamp" or "number"
    // - If the selected types include "timestamp", then the available types must include "number"
    // - If the selected types include "string", then the available types must include "number"
    // - If no types are selected, then the available types can include numbers and timestamps (no strings)
    case ChartType.LINE_CHART:
      if (selectedTypesIncludesNumberType) {
        availableTypes = [temporal, numerical];
      } else if (selectedTypes.includes(temporal)) {
        availableTypes = [numerical];
      } else if (selectedTypes.length === 0) {
        availableTypes = [numerical, temporal];
      }
      // TODO: determine if line can/should support string
      //  else if (selectedTypes.includes("string")) {
      //     availableTypes = ["number"];
      //   }
      break;
    case ChartType.SCATTER_CHART:
      // Scatter Chart Rules:
      // should only support numbers and timestamps
      availableTypes = [numerical, temporal];
      break;
    default:
      break;
  }

  return availableTypes;
};

const availableBuilderCharts = (columns: ChartColumnType[]) => {
  const { numerical, categorical, temporal } = DataTypesObject;
  let numberCount = 0;
  let stringCount = 0;
  let timestampCount = 0;
  let length = 0;

  // Determine the types of columns present
  for (let key in columns) {
    if (columns.hasOwnProperty(key)) {
      let type = columns[key].type;
      if (type === numerical) numberCount++;
      if (type === categorical) stringCount++;
      if (type === temporal) timestampCount++;
      length = columns[key].length;
    }
  }
  const charts = [];

  // Bar chart rules
  // (y-axis must be a number and x-axis must be a string)
  // so we need at least 1 number and 1 string
  if ((numberCount > 0 && stringCount > 0) || (numberCount > 0 && timestampCount > 0)) {
    charts.push(BAR_CHART);
  }

  // Pie chart rules
  // TODO: Is this length check necessary?
  if (numberCount > 0 && stringCount > 0 && length <= 20) {
    charts.push(PIE_CHART);
  }

  // Line chart rules
  //(x-axis must be a timestamp & y-axis must be a number)
  // so if we have atleast 1 number and 1 timestamp, we can plot a line chart
  // TODO: add support for numbers on x-axis
  if (numberCount > 0 && timestampCount > 0) {
    charts.push(LINE_CHART);
  }

  // Scatter chart rules
  // (x-axis must be a number or timestamp & y-axis must be a number)
  // so if we have 1 number and 1 timestamp or at least 2 numbers, we can plot a scatter chart
  if ((numberCount > 0 && timestampCount > 0) || numberCount >= 2) {
    charts.push(SCATTER_CHART);
  }
  return charts as ChartType[];
};

export {
  availableBuilderCharts,
  getAvailableDataTypes,
  renderChartIcon,
  getChartTitle,
  getColumnLabel,
  type DataType,
};
