import * as Sentry from "@sentry/react";

import axios from "axios";
import { USE_WEBSOCKET } from "../Constants/features";
import { env } from "../env";
import { withSentry } from "../helpers/wrapper";
import { SequalizerError } from "../search/errors";
import { getCurrentUserAuthorization, sequalizerPost } from "./base";

const SOCKET_URL = "wss://sequalizerai-ws-test-dot-doowii-staging-376207.wl.r.appspot.com";
class SequalizerResult {
  sql: string;
  messageList: string[];

  constructor(sql, messageList = []) {
    this.sql = sql;
    this.messageList = messageList;
  }
}

function fetchSQLViaWebSocket(db_id, query, user_id, featureFlags) {
  if (featureFlags.includes(USE_WEBSOCKET)) {
    let messageList = [];
    return new Promise((resolve, reject) => {
      const websocket = new WebSocket(SOCKET_URL);

      websocket.onopen = () => {
        websocket.send(
          JSON.stringify({
            db_id: db_id,
            query: query,
          })
        );
      };

      websocket.onmessage = (event) => {
        let process_message = event.data;
        if (!process_message.startsWith("{") || !process_message.endsWith("}")) {
          messageList.push(process_message);
        } else {
          const messageObj = JSON.parse(process_message);
          if (query in messageObj) {
            let sql = messageObj[query];
            resolve(new SequalizerResult(sql, messageList));
          } else {
            reject(new Error("SQL not found in the received message"));
          }
          websocket.close();
        }
      };

      websocket.onerror = (error) => {
        Sentry.withScope((scope) => {
          scope.setTag("error_type", "WebSocket Error");
          Sentry.captureException(error);
        });
      };
    });
  }
}

const callSequalizer = withSentry(async function callSequalizer(
  org_id,
  question,
  user_id,
  recent_messages,
  thread_id,
  question_id,
  source_type,
  query_type,
  question_type = "user",
  abortController?
) {
  try {
    const token = await getCurrentUserAuthorization();
    const response = await axios.post(
      `${env.REACT_APP_SEQUALIZER_API_URL}/process`,
      {
        question,
        source_type,
        recent_messages,
        thread_id,
        question_id,
        query_type,
        question_type,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        signal: abortController?.signal,
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      throw error;
    } else if (error.response) {
      throw new SequalizerError(
        error.response.data.detail.error_reason,
        error.response.data.detail.error,
        error.response.status
      );
    } else if (error.request) {
      throw new Error("No response received from server");
    } else {
      throw error;
    }
  }
});

const fetchSuggestions = withSentry(async function fetchSuggestions(
  org_id,
  previous_questions,
  source_type,
  query_type,
  totalQuestions = 4
) {
  try {
    const currentPath = window.location.pathname;

    if (currentPath.startsWith("/canvas-ui")) {
      totalQuestions = 3;
    }

    const response = await sequalizerPost("/suggestions", {
      previous_questions,
      limit: totalQuestions,
      source_type,
      query_type,
    });
    return response.data.suggestions;
  } catch (error) {
    if (error.response) {
      // Handle error response from the server
      throw new Error(`Server responded with error: ${error.response.data.detail.error_reason}`);
    } else if (error.request) {
      // Handle no response received from the server
      throw new Error("No response received from server");
    } else {
      // Handle other errors
      throw error;
    }
  }
});

const fetchFollowUpPrompts = withSentry(async function fetchFollowUpPrompts({
  org_id,
  thread_id,
  question_id,
  recent_messages,
}) {
  try {
    const response = await sequalizerPost("/refinements", {
      thread_id,
      question_id,
      recent_messages,
      limit: 3,
    });
    return response.data.questions;
  } catch (error) {
    console.error(error);
  }
});

export { callSequalizer, fetchFollowUpPrompts, fetchSQLViaWebSocket, fetchSuggestions };
