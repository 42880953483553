export enum ChartType {
  TABLE = "TABLE",
  BAR_CHART = "BAR",
  PIE_CHART = "PIE",
  LINE_CHART = "LINE",
  SCATTER_CHART = "SCATTER",
  SCHEMA = "SCHEMA",
}

export enum PandaOnboardingType {
  Loading,
  Initial,
}
