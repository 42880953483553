import styled, { keyframes } from "styled-components";

export const LoadingPage = styled.div`
  font-size: 4em;
  text-align: center;
  font-family: "Outfit";
  font-weight: 600;
  margin-bottom: 20px;
  color: #003a47;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex: 1;
`;

export const Letter = styled.span`
  display: inline-block;
  position: relative;
`;

export const BounceKeyframe = keyframes`
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-0.2em);
  }
`;

export const Bounce = styled.span<{ $animationDelay?: string }>`
  display: inline-block;
  animation: ${BounceKeyframe} 0.6s infinite alternate;

  ${({ $animationDelay }) => $animationDelay && `animation-delay: ${$animationDelay}`};
`;

export const Dot = styled(Bounce)`
  position: absolute;
  top: -0.7em; /* Adjust as necessary for your font */
  left: 0%;
  transform: translateX(-50%);
`;
