import { Badge, Button, Flex, IconButton, Popover, Tooltip } from "@instructure/ui";
import { IconStatsLine } from "@instructure/ui-icons";
import { useState } from "react";

import { IconArrowOpenDownLine } from "@instructure/ui-icons";
import { MessageDescriptor } from "@lingui/core";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useAuth } from "../../../context";
import { Analytics } from "../../../services/analytics/Analytics";
import { ChartType } from "../../types";
import { renderChartIcon } from "./helpers";

interface ChartSelectorProps {
  charts: ChartType[];
  setChartType: (chart: ChartType) => void;
  chartType: string;
  disabled?: boolean;
  renderTrigger?: React.ReactNode;
}

export const allCharts: ChartType[] = [
  ChartType.TABLE,
  ChartType.PIE_CHART,
  ChartType.BAR_CHART,
  ChartType.LINE_CHART,
  ChartType.SCATTER_CHART,
];

export const getOptionTooltip = (charts: ChartType[], type: ChartType): MessageDescriptor => {
  const chartType = type !== ChartType.TABLE ? " chart" : "";
  const chartName = `${type.toLowerCase()}${chartType}`;
  return !charts.includes(type)
    ? msg`Select appropriate columns to enable ${chartName}.`
    : msg`Click to show ${chartName}.`;
};

export const ChartSelector = ({
  charts,
  chartType,
  setChartType: _setChartType,
  disabled,
  renderTrigger,
}: ChartSelectorProps) => {
  const { _ } = useLingui();
  const [isShowingContent, setIsShowingContent] = useState(false);
  const { featureFlags } = useAuth();
  const shouldShowBadge = charts.length > 1;

  const setChartType = (value: ChartType) => {
    Analytics.track("Chart Selector", { chartType: value });
    _setChartType(value);
  };

  return (
    <Badge count={shouldShowBadge ? charts.length : null}>
      <Popover
        renderTrigger={
          renderTrigger || (
            <Button disabled={disabled} renderIcon={<IconStatsLine />}>
              <IconArrowOpenDownLine height="18px" />
            </Button>
          )
        }
        isShowingContent={isShowingContent}
        onShowContent={() => setIsShowingContent(true)}
        onHideContent={() => setIsShowingContent(false)}
        on="click"
        screenReaderLabel={_(msg`Select chart type`)}
        shouldReturnFocus
        shouldCloseOnDocumentClick
        mountNode={() => document.getElementById("main")}
        defaultFocusElement={() => document.getElementById(`chart-selector-${chartType}`)}
      >
        <Flex direction="row" padding="small" gap="small">
          {allCharts.map((type) => (
            <Tooltip renderTip={_(getOptionTooltip(charts, type))} key={type}>
              <IconButton
                renderIcon={renderChartIcon(type, chartType)}
                withBackground={false}
                withBorder={false}
                screenReaderLabel={_(msg`Chart ${type}`)}
                onClick={() => setChartType(type)}
                id={`chart-selector-${type}`}
                disabled={!charts.includes(type)}
              />
            </Tooltip>
          ))}
        </Flex>
      </Popover>
    </Badge>
  );
};
