import * as echarts from "echarts";
import { useEffect, useRef, useState } from "react";
import { DEFAULT_QUERY_OPTIONS } from "../../../Constants/constants";
import { useQuery } from "../../../hooks/useQuery";
import DoowiiLoader from "../../Loading/DoowiiLoader";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
const { DateTime } = require("luxon");

const PredictionLineChart = ({ result, height, width }) => {
  const { _ } = useLingui();
  const chartRef = useRef(null);
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const {
    loading,
    rows,
    pageInfo: _pageInfo,
    fetchComplete,
    setFetchComplete,
  } = useQuery(result, { paginationModel: { page: 0, pageSize: 5000 } }, shouldFetchData);

  useEffect(() => {
    setShouldFetchData(true);
  }, [result]);

  useEffect(() => {
    if (fetchComplete) {
      setShouldFetchData(false);
      setFetchComplete(false);
    }
  }, [fetchComplete]);

  useEffect(() => {
    if (rows && rows?.length > 0) {
      const chart = echarts.init(chartRef.current);

      const timestamps = rows?.map((item) => {
        // eslint-disable-next-line lingui/no-unlocalized-strings
        return DateTime.fromISO(item.forecast_timestamp).toFormat("yyyy-MM-dd HH:mm:ss");
      });

      const historicalValues = rows?.map((item) => item.history_value);
      const forecastValues = rows?.map((item) => item.forecast_value);
      const upperConfidenceBounds = rows?.map((item) => item.prediction_interval_upper_bound);
      const upperConfidenceBoundsForShading = rows?.map((item, index) =>
        item.prediction_interval_upper_bound > forecastValues[index] * 3
          ? forecastValues[index] * 3
          : item.prediction_interval_upper_bound
      );
      const lowerConfidenceBounds = rows?.map((item) => item.prediction_interval_lower_bound);
      const base = Math.min(...lowerConfidenceBounds);

      const options = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            animation: false,
            label: {
              backgroundColor: "#ccc",
              borderColor: "#aaa",
              borderWidth: 1,
              shadowBlur: 0,
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              color: "#222",
            },
          },
          formatter: function (params) {
            const historical = params.find((p) => p.seriesName === "Historical Value");
            const forecast = params.find((p) => p.seriesName === "Forecast Value");
            const upper = params.find((p) => p.seriesName === "Upper Confidence Bound");
            const lower = params.find((p) => p.seriesName === "Lower Confidence Bound");

            return forecast?.value
              ? // eslint-disable-next-line lingui/no-unlocalized-strings
                `
              ${forecast?.marker} ${forecast?.seriesName}: ${
                forecast?.value?.toFixed(2) || "-"
              }<br/>
              ${upper?.seriesName}: ${upperConfidenceBounds[upper?.dataIndex]?.toFixed(2)}<br/>
              ${lower?.seriesName}: ${lowerConfidenceBounds[lower?.dataIndex]?.toFixed(2)}
            `
              : `
            ${historical?.marker} ${historical?.seriesName}: ${historical?.value?.toFixed(2) || "-"}
            `;
          },
        },
        toolbox: {
          restore: {},
          saveAsImage: {},
        },
        legend: {
          data: [_(msg`Historical Value`), _(msg`Forecast Value`), _(msg`Confidence Interval`)],
          top: 25,
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100,
            xAxisIndex: [0],
          },
          {
            type: "slider",
            start: 0,
            end: 10,
            xAxisIndex: [0],
          },
          {
            type: "inside",
            start: 0,
            end: 100,
            yAxisIndex: [0],
          },
          {
            type: "slider",
            start: 0,
            end: 100,
            yAxisIndex: [0],
          },
        ],
        grid: {
          bottom: "20%", // Increase the bottom margin to ensure space for x-axis labels and zoom slider
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: timestamps.map((str) => str.replace(" ", "\n")),
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: _(msg`Historical Value`),
            type: "line",
            data: historicalValues,
            itemStyle: {
              color: "#3388A6",
            },
            showSymbol: false,
          },
          {
            name: _(msg`Lower Confidence Bound`),
            type: "line",
            data: lowerConfidenceBounds.map((value) => value - base),
            lineStyle: {
              opacity: 0,
            },
            stack: "confidence-band",
            symbol: "none",
          },
          {
            name: _(msg`Upper Confidence Bound`),
            type: "line",
            data: upperConfidenceBoundsForShading.map(
              (value, index) => value - lowerConfidenceBounds[index]
            ),
            lineStyle: {
              opacity: 0,
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#29BDF0" },
                { offset: 1, color: "#F6CD3C" },
              ]),
            },
            stack: "confidence-band",
            symbol: "none",
          },
          {
            name: _(msg`Forecast Value`),
            type: "line",
            data: forecastValues,
            itemStyle: {
              color: "#33A665",
            },
            showSymbol: false,
          },
        ],
      };

      const updateDataZoom = () => {
        const option = chart.getOption();
        const relevantSeries = [_(msg`Historical Value`), _(msg`Forecast Value`)];
        const availableSeries = relevantSeries.filter(
          (seriesName) => option.legend[0].selected[seriesName] !== false
        );
        // @ts-ignore
        const visibleSeries = option.series.filter((series) =>
          availableSeries.includes(series.name)
        );

        let minIndex = Infinity;
        let maxIndex = -Infinity;
        visibleSeries.forEach((series) => {
          series.data.forEach((dataPoint, index) => {
            if (dataPoint !== null) {
              minIndex = Math.min(minIndex, index);
              maxIndex = Math.max(maxIndex, index);
            }
          });
        });

        if (minIndex === Infinity || maxIndex === -Infinity) {
          minIndex = 0;
          maxIndex = option.xAxis[0].data.length - 1;
        }

        const totalLength = option.xAxis[0].data.length;
        const startPercent = (minIndex / totalLength) * 100;
        const endPercent = ((maxIndex + 1) / totalLength) * 100;

        chart.setOption({
          dataZoom: [
            {
              type: "inside",
              start: startPercent,
              end: endPercent,
              xAxisIndex: [0],
            },
            {
              type: "slider",
              start: startPercent,
              end: endPercent,
              xAxisIndex: [0],
            },
          ],
        });
      };
      chart.setOption(options);
      chart.on("legendselectchanged", updateDataZoom);

      return () => {
        chart.off("legendselectchanged", updateDataZoom);
        chart.dispose();
      };
    }
  }, [rows, height, width, loading]);

  return loading ? (
    <DoowiiLoader />
  ) : (
    <div ref={chartRef} style={{ width: width || "100%", height: height || "500px" }}></div>
  );
};

export default PredictionLineChart;
