import { BAR_CHART, LINE_CHART, PIE_CHART, SCATTER_CHART } from "../Constants/constants";

export interface SortModel {
  field: string;
  sort: string;
}

export type Rows = { [key: string]: any }[];

export type Headers = string[];

export interface PageInfo {
  totalRowCount: number;
  headers: Headers;
  dataTypes: {
    [key: string]: ["timestamp" | "datetime" | "date" | "string" | "integar" | "boolean" | "float"];
  };
  hyperlinkColumns: { [key: string]: string[] };
}

export enum ParentDocTypeEnum {
  THREAD = "thread",
  PINBOARD = "pinboard",
}
export interface FetchPageDataParams {
  page_size: number;
  page_number: number;
  sort_model?: SortModel[];
  doc_id: string;
  parent_doc_type: ParentDocTypeEnum;
  parent_doc_id?: string;
  retrieval_type?: string;
  user_defined_query?: boolean;
}

export interface retrieverResponse {
  rows: Rows;
  data_types: {
    [key: string]: ["timestamp" | "datetime" | "date" | "string" | "integar" | "boolean" | "float"];
  };
  hyperlink_columns: { [key: string]: string[] };
  totalRows: number;
}

type ChartType = typeof BAR_CHART | typeof LINE_CHART | typeof PIE_CHART | typeof SCATTER_CHART;

export interface FetchChartDataParams {
  columns: any;
  sort_model?: SortModel[];
  chart_type: ChartType;
  doc_id: string;
  parent_doc_type: ParentDocTypeEnum;
  parent_doc_id?: string;
}
