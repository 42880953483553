import { useState } from "react";
import GoogleButton from "react-google-button";
import { toast } from "react-hot-toast";
import PasswordStrengthBar from "react-password-strength-bar";
import { useNavigate } from "react-router-dom";

import { Spinner } from "../components/Loading/Spinner";
import { useAuth } from "../context";
import { formatErrorMessageAndAlert } from "../helpers";
import { withSentry } from "../helpers/wrapper";
import { useInput } from "../hooks/useInput";
import { signInWithGoogle } from "../services/firebase";

import "./SignIn.css";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

export const SignIn = () => {
  const { _ } = useLingui();
  const [view, setView] = useState("SIGN_IN");
  const auth = useAuth();
  const navigate = useNavigate();
  const email = useInput("");
  const [password, setPassword] = useState("");
  const password2 = useInput("");
  const [isResetSuccessful, setIsResetSuccessful] = useState(false);

  const onFormSubmit = withSentry(async (e) => {
    e.preventDefault();
    if (view === "SIGN_UP" && password !== password2.value) {
      toast.error("Passwords do not match");
    } else {
      let response;

      switch (view) {
        case "SIGN_IN":
          try {
            response = await auth.signIn(email.value, password);
            if (response?.user) toast.success(_(msg`Signed in successfully!`));
          } catch (e) {
            formatErrorMessageAndAlert(e.code);
          }
          break;

        case "SIGN_UP":
          try {
            response = await auth.signUp(email.value, password);
            if (response?.user) toast.success(_(msg`Signed up successfully!`));
          } catch (e) {
            toast.error(e.message);
          }
          break;

        case "RESET_PWD":
          try {
            response = await auth.resetPwd(email.value);
            setIsResetSuccessful(true);
            if (response?.user) toast.success(_(msg`Password reset instructions sent!`));
          } catch (e) {
            formatErrorMessageAndAlert(e.code);
          }
          break;

        default:
          toast.error("Invalid view!");
          break;
      }
    }
  });

  const handleGoogleSignIn = withSentry(async () => {
    try {
      await signInWithGoogle();
      toast.success(_(msg`Signed in with Google successfully!`));
      navigate("/");
    } catch (error) {
      toast.error(error.message);
    }
  });
  return (
    <section className="signInPage">
      <div className="flex flex-col items-center justify-center">
        <div className="signInFormContainer">
          <div className="signInForm">
            {view !== "RESET_PWD" && <h1>doowii</h1>}

            {/* TODO: make it render conditionally through feature flag */}
            {/* {view === "SIGN_IN" && (
              <div>
                <h1 className="mainTitle">Sign in with demo account</h1>
                <button
                  type="submit"
                  disabled={auth.isLoading}
                  onClick={loginDemoAccount}
                  className="mainBtn"
                >
                  {auth.isLoading ? <Spinner /> : "Login to Demo Account"}
                </button>
                <span className="separator" />{" "}
              </div>
            )} */}

            {view !== "RESET_PWD" && (
              <h1 className="mainTitle">
                {view === "SIGN_IN" ? _(msg`Sign in to your account`) : _(msg`Sign up for doowii`)}
              </h1>
            )}
            <form className="signInFormElements" onSubmit={onFormSubmit}>
              <div>
                <label htmlFor="email" className="formLabel">
                  {view === "RESET_PWD" ? (
                    <div style={{ marginBottom: "1rem" }}>
                      <strong>
                        <Trans>Enter your email address to reset password</Trans>
                      </strong>
                    </div>
                  ) : (
                    <Trans>Your email</Trans>
                  )}
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="formInput"
                  placeholder="name@school.edu"
                  required
                  {...email}
                />
              </div>

              <div>
                <label htmlFor="password" className="formLabel">
                  <Trans>Password</Trans>
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  className="formInput"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
              </div>

              {view === "SIGN_UP" && (
                <div>
                  {password === "" ? null : (
                    <PasswordStrengthBar className="strengthBar" password={password} />
                  )}

                  <label htmlFor="password2" className="formLabel">
                    <Trans>Confirm Password</Trans>
                  </label>
                  <input
                    type="password"
                    name="password2"
                    id="password2"
                    placeholder="••••••••"
                    className="formInput"
                    required
                    {...password2}
                  />
                </div>
              )}
              {view === "SIGN_IN" ? (
                <div className="formLink">
                  <a onClick={() => setView("RESET_PWD")} className="linkText">
                    <Trans>Forgot password?</Trans>
                  </a>
                </div>
              ) : (
                <>
                  <br />
                </>
              )}
              <button type="submit" disabled={auth.isLoading} className="mainBtn">
                {auth.isLoading ? (
                  <Spinner />
                ) : view === "SIGN_IN" ? (
                  <Trans>Sign in</Trans>
                ) : view === "RESET_PWD" ? (
                  isResetSuccessful ? (
                    <Trans>Email Sent!</Trans> // show a success message
                  ) : (
                    <Trans>Reset Password</Trans>
                  )
                ) : (
                  <Trans>Sign up</Trans>
                )}
              </button>
              {view === "SIGN_IN" ? (
                <p className="signUpText">
                  <Trans>Don't have an account yet?</Trans>{" "}
                  <a onClick={() => setView("SIGN_UP")} className="linkText">
                    <Trans>Sign up</Trans>
                  </a>
                </p>
              ) : view === "RESET_PWD" ? (
                <p className="signUpText">
                  <Trans>Return to sign in?</Trans>{" "}
                  <a onClick={() => setView("SIGN_IN")} className="linkText">
                    <Trans>Sign in</Trans>
                  </a>
                </p>
              ) : (
                <p className="signUpText">
                  <Trans>Already have an account?</Trans>{" "}
                  <a onClick={() => setView("SIGN_IN")} className="linkText">
                    <Trans>Sign in</Trans>
                  </a>
                </p>
              )}

              {view !== "RESET_PWD" && (
                <div className="separator">
                  <span>
                    <Trans>or</Trans>
                  </span>
                </div>
              )}

              {view !== "RESET_PWD" && (
                <GoogleButton
                  onClick={handleGoogleSignIn}
                  label={
                    view === "SIGN_IN" ? _(msg`Sign in with Google`) : _(msg`Sign up with Google`)
                  }
                />
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
