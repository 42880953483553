import { FC, ReactElement } from "react";

import { Link, Text } from "@instructure/ui";
import { CHAT_VIEW } from "../../../../Constants/constants";
import { Pin } from "../../../../context/pinboard/pinboard.i";
import { useUI } from "../../../../context/ui";
import { FullContainer } from "../../FullContainer/FullContainer";
import { HappyPanda } from "../../UI/HappyPanda/HappyPanda";
import { Loader } from "../../UI/Loader/Loader";
import { useLingui } from "@lingui/react";
import { Trans, msg } from "@lingui/macro";
import { PinboardChart } from "./PinboardChart/PinboardChart";

interface PinboardListProps {
  currBoardId: string;
  filteredPinboardResults: Pin[];
  pinboardResults: any[];
  hasSearch: boolean;
  loading: boolean;
  clearSearch: () => void;
}

export const PinboardList: FC<PinboardListProps> = ({
  currBoardId,
  filteredPinboardResults,
  hasSearch,
  loading,
  clearSearch,
  pinboardResults,
}): ReactElement => {
  const { _ } = useLingui();
  const { setView }: any = useUI();

  if (loading) {
    return <Loader message={_(msg`Loading pins...`)} />;
  }

  if (!pinboardResults.length) {
    return (
      <HappyPanda>
        <Text>
          <Trans>There are no pins to display yet.</Trans>
          <br />
          <Link forceButtonRole onClick={() => setView(CHAT_VIEW)}>
            <Trans>Start chatting</Trans>
          </Link>{" "}
          <Trans>to discover and pin your favorite reports to a pinboard.</Trans>
        </Text>
      </HappyPanda>
    );
  }

  if (!filteredPinboardResults.length && hasSearch) {
    return (
      <HappyPanda>
        <Text>
          <Trans>No pins found with your search criteria.</Trans>
        </Text>
        <Text>
          <Trans>Try a different search term or</Trans>
          <Link forceButtonRole onClick={clearSearch}>
            <Trans>clear your search</Trans>
          </Link>
        </Text>
      </HappyPanda>
    );
  }

  return (
    <FullContainer className="flex h-full flex-1 flex-col overflow-y-auto pb-2">
      {filteredPinboardResults.map((pin, index) => {
        if (!pin || pin?.visualisation === "ERROR" || pin?.visualisation === "EMPTY_ROWS")
          return null;

        return <PinboardChart key={`${index}-${pin?.id}-${currBoardId}`} chart={pin} />;
      })}
    </FullContainer>
  );
};
