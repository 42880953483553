import { IconButton, Tooltip } from "@instructure/ui";
import {
  IconCheckMarkSolid,
  IconEditLine,
  IconEndLine,
  IconTrashLine,
} from "@instructure/ui-icons";
import { CHAT_VIEW } from "../../../../../Constants/constants";
import { usePinboard } from "../../../../../context/pinboard";
import { useUI } from "../../../../../context/ui";
import { withSentry } from "../../../../../helpers/wrapper";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

interface ActionButtonsProps {
  value: string;
  results: any;
  readOnly: boolean;
  setDeleteChartModalOpen: (value: boolean) => void;
  setReadOnly: (value: boolean) => void;
  updateTitle: (pinboardId: string, pinId: string, title: string) => Promise<any>;
  setValue: (value: string) => void;
}

export const ActionButtons = ({
  value,
  setValue,
  readOnly,
  setReadOnly,
  setDeleteChartModalOpen,
  updateTitle,
  results,
}: ActionButtonsProps) => {
  const { _ } = useLingui();
  const { view } = useUI();
  const { currBoardId } = usePinboard();

  const handleEndClick = () => {
    setReadOnly(true);
    setValue(results?.title);
  };

  const handleCheckClick: any = withSentry(async () => {
    view === CHAT_VIEW
      ? await (updateTitle as any)(value) // ?? why doesn't it use all arguments?
      : await updateTitle(currBoardId!, results?.id, value);

    setReadOnly(true);
  });

  return (
    <>
      {readOnly && (
        <Tooltip renderTip={<Trans>Edit name</Trans>}>
          <IconButton
            margin="x-small"
            withBackground={false}
            withBorder={false}
            onClick={() => setReadOnly(false)}
            screenReaderLabel={_(msg`Edit name`)}
          >
            <IconEditLine />
          </IconButton>
        </Tooltip>
      )}
      {!readOnly && (
        <>
          <Tooltip renderTip={<Trans>Confirm</Trans>}>
            <IconButton
              margin="xx-small"
              withBackground={false}
              withBorder={false}
              onClick={handleCheckClick}
              screenReaderLabel={_(msg`Submit new name`)}
            >
              <IconCheckMarkSolid color="success" />
            </IconButton>
          </Tooltip>
          <Tooltip renderTip={<Trans>Cancel</Trans>}>
            <IconButton
              margin="xx-small"
              withBackground={false}
              withBorder={false}
              onClick={handleEndClick}
              screenReaderLabel={_(msg`Cancel edit name`)}
            >
              <IconEndLine color="error" />
            </IconButton>
          </Tooltip>
        </>
      )}
      <Tooltip renderTip={<Trans>Remove Chart from Pinboard</Trans>}>
        <IconButton
          margin="xx-small"
          withBackground={false}
          withBorder={false}
          onClick={() => setDeleteChartModalOpen(true)}
          screenReaderLabel={_(msg`Remove Pin`)}
        >
          <IconTrashLine />
        </IconButton>
      </Tooltip>
    </>
  );
};
