import * as d3 from "d3";
import React, { useEffect, useRef, useState } from "react";

import { CHART_LIMIT, PIE_CHART } from "../../../Constants/constants";
import { fetchChartTransformedData } from "../../../api/retriever";
import { ChartErrorState } from "../ChartErrorState/ChartErrorState";
import { ChartLoadingSpinner } from "../ChartLoadingSpinner/ChartLoadingSpinner";
import { ParentDocTypeEnum } from "../../../api/retriever.i";
import { mapNewToOldDataTypes } from "../helpers";

interface PieChartProps {
  data: any[];
  width: number;
  height: number;
  selectedColumns: Record<string, { type: string }>;
  docId: string;
  parentDocId: string;
  parentDocType: ParentDocTypeEnum;
  totalRows: number;
}

interface TransformedDataItem {
  type: string;
  value: number;
}

export const PieChart: React.FC<PieChartProps> = ({
  data,
  width,
  height,
  selectedColumns,
  docId,
  parentDocId,
  parentDocType,
  totalRows,
}) => {
  const svgRef = useRef<SVGSVGElement>(null);

  const [transformedData, setTransformedData] = useState<TransformedDataItem[]>([]);

  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const transformData = (
    inputData: any[],
    columns: Record<string, { type: string }>
  ): TransformedDataItem[] => {
    const { firstHeader, secondHeader } = Object.entries(columns).reduce<{
      firstHeader?: string;
      secondHeader?: string;
    }>((acc, [key, value]: [string, { type: string }]) => {
      if (value.type === "string") return { ...acc, firstHeader: key };
      if (value.type === "number") return { ...acc, secondHeader: key };
      return acc;
    }, {});

    if (!firstHeader || !secondHeader) return [];

    const totalSum = inputData.reduce((sum, row) => sum + Number(row[secondHeader]), 0);
    return inputData
      .filter((row) => typeof row[firstHeader] === "string")
      .map((row) => ({
        type: row[firstHeader],
        value: Math.round((Number(row[secondHeader]) / totalSum) * 1000) / 10,
      }));
  };

  useEffect(() => {
    const fetchData = async () => {
      setHasError(false);

      if (data?.length > 0) {
        const updatedSelectedColumns = mapNewToOldDataTypes(selectedColumns);
        setLoading(true);
        try {
          if (totalRows > CHART_LIMIT) {
            const response = await fetchChartTransformedData({
              doc_id: docId,
              parent_doc_id: parentDocId,
              parent_doc_type: parentDocType,
              columns: updatedSelectedColumns,
              chart_type: PIE_CHART,
            });

            if (response.data) {
              setTransformedData(response.data);
            } else {
              throw new Error("No data received");
            }
          } else {
            setTransformedData(transformData(data, updatedSelectedColumns));
          }
        } catch (error) {
          console.error("Error fetching or transforming data:", error);
          setHasError(true);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [data, selectedColumns, totalRows, docId, parentDocId, parentDocType]);

  useEffect(() => {
    if (!svgRef.current || transformedData.length === 0) return;

    const radius = Math.min(width, height) / 2;
    const colorScale = d3
      .scaleOrdinal<string>()
      .domain(transformedData.map((d) => d.type))
      .range(
        d3.quantize((t) => d3.interpolateSpectral(t * 0.8 + 0.1), transformedData.length).reverse()
      );

    const svgEl = d3.select(svgRef.current);
    svgEl.selectAll("*").remove();

    const svg = svgEl
      .attr("viewBox", `0 0 ${width} ${height}`)
      .append("g")
      // eslint-disable-next-line lingui/no-unlocalized-strings
      .attr("transform", `translate(${width / 2}, ${height / 2})`);

    const pie = d3
      .pie<TransformedDataItem>()
      .value((d) => d.value)
      .sort(null);

    const arcGenerator = d3
      .arc<d3.PieArcDatum<TransformedDataItem>>()
      .outerRadius(radius * 0.8)
      .innerRadius(radius * 0.4);

    const hoverArcGenerator = d3
      .arc<d3.PieArcDatum<TransformedDataItem>>()
      .outerRadius(radius * 0.85)
      .innerRadius(radius * 0.35);

    const animate = (d: d3.PieArcDatum<TransformedDataItem>) => {
      const interpolate = d3.interpolate({ startAngle: 0, endAngle: 0 }, d);
      return (t: number) => arcGenerator(interpolate(t));
    };

    const g = svg
      .selectAll<SVGGElement, d3.PieArcDatum<TransformedDataItem>>(".arc")
      .data(pie(transformedData))
      .enter()
      .append("g")
      .attr("class", "arc");

    g.append("path")
      .attr("d", arcGenerator)
      .attr("class", "arc")
      .style("fill", (_, i) => colorScale(i.toString()))
      .style("fill-opacity", 0.9)
      .style("stroke", "#f5f5f5")
      .style("stroke-width", 2)
      .transition()
      .ease(d3.easeExp)
      .duration(2000)
      .attrTween("d", animate);

    g.append("text")
      .attr("transform", (d) => `translate(${arcGenerator.centroid(d)})`)
      .attr("text-anchor", "middle")
      .attr("font-size", "10")
      .each(function (d) {
        const el = d3.select(this);
        el.append("tspan").text(d.data.type).attr("y", "-0.4em").attr("font-weight", "bold");

        el.append("tspan")
          .text(`${d.data.value}%`)
          .attr("x", 0)
          .attr("y", "0.7em")
          .attr("fill-opacity", 0.7);
      });

    g.on("mouseover", function (event, d) {
      const currentGroup = d3.select(this);

      currentGroup
        .select("path")
        .style("fill-opacity", 1)
        .transition()
        .duration(200)
        .attr("d", hoverArcGenerator);

      this.parentNode!.appendChild(this);

      currentGroup.select("text").selectAll("tspan").style("font-size", "15px");
    }).on("mouseout", function (event, d) {
      const currentGroup = d3.select(this);

      currentGroup
        .select("path")
        .style("fill-opacity", 0.9)
        .transition()
        .duration(200)
        .attr("d", arcGenerator);

      currentGroup.select("text").selectAll("tspan").style("font-size", "10px");
    });
  }, [transformedData, height, width]);

  return (
    <>
      <svg ref={svgRef} id="pie-chart" className="pie-chart" width={width} height={height}></svg>
      {loading && <ChartLoadingSpinner />}
      {hasError && <ChartErrorState />}
    </>
  );
};
