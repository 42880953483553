import {
  Button,
  CloseButton,
  Heading,
  IconButton,
  IconDownloadLine,
  Modal,
  Text,
  Tooltip,
  TruncateText,
} from "@instructure/ui";
import { FC, useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { TABLE } from "../../../../../../Constants/constants";
import { useAuth } from "../../../../../../context";
import { useChatData } from "../../../../../../context/chat";
import { availableChartsForCanvas } from "../../../../../../helpers";
import { downloadCSV } from "../../../../../../helpers/downloadCSV";
import { withSentry } from "../../../../../../helpers/wrapper";
import { ChartType } from "../../../../../types";
import { ChartContent } from "../../../../ChartContent/ChartContent";
import { ChartSelector } from "../../../../ChartSelector/ChartSelector";
import { ParentDocTypeEnum } from "../../../../../../api/retriever.i";

interface FullScreenProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  result: any; // TODO: Add type when context is fixed
}

export const FullscreenModal: FC<FullScreenProps> = ({ open, setOpen, result }) => {
  const { _ } = useLingui();
  const [charts, setCharts] = useState<ChartType[]>([]);
  const [chartType, setChartType] = useState<ChartType>(TABLE as ChartType);
  const [selectedColumns, setSelectedColumns] = useState({});
  const [totalRows, setTotalRows] = useState(0);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [isTitleTruncated, setIsTitleTruncated] = useState(false);

  const { userDocument, featureFlags } = useAuth();
  const { answer, allResults, currentThread } = useChatData();

  const whiteboardRef = useRef(null);

  // Table is flickering when closing the modal
  const [closing, setClosing] = useState(false);
  const clear = () => {
    setCharts([]);
    setSelectedColumns({});
    setChartType(TABLE as ChartType);
    setHeight(0);
    setWidth(0);
  };

  const handleClose = () => {
    setClosing(true);
    setOpen(false);
  };

  useEffect(() => {
    if (open) setClosing(false);
  }, [open]);

  useEffect(() => {
    if (whiteboardRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        if (!closing) {
          for (const entry of entries) {
            setHeight(entry.contentRect.height);
            setWidth(entry.contentRect.width);
          }
        }
      });

      resizeObserver.observe(whiteboardRef.current);

      return () => {
        if (resizeObserver) {
          resizeObserver.disconnect();
        }
      };
    }
  }, [whiteboardRef.current]);

  useEffect(() => {
    return () => clear();
  }, [open]);

  useEffect(() => {
    const charts = availableChartsForCanvas(selectedColumns);
    setCharts(charts as ChartType[]);
  }, [selectedColumns]);

  const handleDownloadCSV = withSentry(async () => {
    await downloadCSV({
      doc_id: result?.id,
      parent_doc_id: currentThread,
      parent_doc_type: ParentDocTypeEnum.THREAD,
      filename: `${result?.title}.csv`,
    });
  });

  const renderHeader = () => (
    <Heading>
      <TruncateText maxLines={3} onUpdate={(isTruncated) => setIsTitleTruncated(isTruncated)}>
        {result?.title}
      </TruncateText>
    </Heading>
  );

  const createMarkdownText = (markdownText) => {
    try {
      if (!markdownText) return null;

      return <ReactMarkdown children={markdownText} rehypePlugins={[rehypeRaw]} />;
    } catch (e) {
      console.error(e);
      return markdownText;
    }
  };

  return (
    <Modal
      open={open}
      label={_(msg`Fullscreen Pin: ${result?.title}`)}
      id="modal-container"
      allowFullScreen={true}
      size={"fullscreen"}
    >
      <Modal.Header className="pt-12">
        <CloseButton
          placement="end"
          offset="small"
          screenReaderLabel={_(msg`Close`)}
          onClick={handleClose}
        />
        {isTitleTruncated ? (
          <Tooltip
            renderTip={result?.title}
            constrain="parent"
            mountNode={() => document.getElementById("modal-container")}
          >
            {renderHeader()}
          </Tooltip>
        ) : (
          renderHeader()
        )}
      </Modal.Header>

      <Modal.Body>
        <div className="mb-4">
          <Text weight="bold" size="x-large">
            <Trans>Summary</Trans>
          </Text>
          <div>
            <div className="mt-3">
              {createMarkdownText(answer[allResults?.findIndex((item) => item.id === result?.id)])}
            </div>
          </div>
        </div>

        <div className="mb-4 flex items-center justify-end">
          <ChartSelector charts={charts} setChartType={setChartType} chartType={chartType} />
          <Tooltip renderTip={_(msg`Download data as CSV`)} placement="top">
            <IconButton
              withBackground={false}
              withBorder={false}
              screenReaderLabel={_(msg`Download data as CSV`)}
              onClick={handleDownloadCSV as any}
            >
              <IconDownloadLine />
            </IconButton>
          </Tooltip>
        </div>

        <div ref={whiteboardRef} style={{ minHeight: "55vh", height: "55vh" }}>
          <ChartContent
            chartType={chartType}
            height={height}
            width={width}
            results={result}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            totalRows={totalRows}
            setTotalRows={setTotalRows}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button color="primary" onClick={handleClose}>
          <Trans>Done</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
