import { IconButton } from "@instructure/ui";
import { IconArrowOpenEndLine, IconArrowOpenStartLine } from "@instructure/ui-icons";

import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { FC } from "react";
import { EMPTY_ROWS, ERROR, TABLE } from "../../../../../../Constants/constants";
import { ChartSelector } from "../../../../ChartSelector/ChartSelector";
import PinButton from "../../PinButton";
import { ChartTitle } from "./ChartTitle/ChartTitle";
import { DropdownMenu } from "./DropdownMenu/DropdownMenu";

interface WhiteBoardHeaderProps {
  loading: boolean;
  results: any;
  allResults: any;
  updateTitle: (title: string) => void;
  handleNavigation: (direction: string) => void;
  totalRows: number;
  charts: any;
  chartType: string;
  setChartType: (chartType: string) => void;
  openPinboardModal: boolean;
  setOpenPinboardModal: (open: boolean) => void;
  setSqlTrayOpen: (open: boolean) => void;
  setFullScreenOpen: (open: boolean) => void;
}

export const WhiteBoardHeader: FC<WhiteBoardHeaderProps> = ({
  loading,
  results,
  allResults,
  updateTitle,
  handleNavigation,
  totalRows,
  charts,
  chartType,
  openPinboardModal,
  setChartType,
  setOpenPinboardModal,
  setSqlTrayOpen,
  setFullScreenOpen,
}) => {
  const { _ } = useLingui();
  const invalidState =
    results?.visualisation === EMPTY_ROWS ||
    results?.visualisation === ERROR ||
    chartType === EMPTY_ROWS ||
    chartType === ERROR ||
    (chartType === TABLE && totalRows === 0);

  return (
    <div className="flex items-center justify-between p-[6px]">
      <div className="flex">
        <IconButton
          size="small"
          margin="small"
          renderIcon={IconArrowOpenStartLine}
          withBackground={false}
          withBorder={false}
          screenReaderLabel={_(msg`Navigate Left`)}
          onClick={() => handleNavigation("left")}
          disabled={loading || results?.id === allResults[0]?.id}
        />

        <IconButton
          size="small"
          margin="small"
          renderIcon={IconArrowOpenEndLine}
          withBackground={false}
          withBorder={false}
          screenReaderLabel={_(msg`Navigate Right`)}
          onClick={() => handleNavigation("right")}
          disabled={loading || results?.id === allResults[allResults.length - 1]?.id}
        />
      </div>
      <div className="flex flex-1">
        <ChartTitle
          results={results}
          updateTitle={updateTitle}
          disabled={loading || !results?.sql || invalidState}
        />
      </div>
      <div className="flex">
        {results?.error?.name === undefined && (
          <div className="flex gap-3">
            <PinButton
              isOpen={openPinboardModal}
              setIsOpen={setOpenPinboardModal}
              chartType={chartType}
              disabled={loading || invalidState}
              onClick={() => setOpenPinboardModal(true)}
            />
            <ChartSelector
              charts={charts}
              chartType={chartType}
              setChartType={setChartType}
              disabled={loading || invalidState}
            />
            <DropdownMenu
              setSqlTrayOpen={setSqlTrayOpen}
              disabled={loading}
              invalidState={invalidState}
              setFullScreenOpen={setFullScreenOpen}
            />
          </div>
        )}
      </div>
    </div>
  );
};
