import { Trans } from "@lingui/macro";
import * as s from "./ChartErrorState.s";

export const ChartErrorState = () => {
  return (
    <s.ChatErrorStateContainer>
      <s.Heading>
        <Trans>Sorry</Trans>
      </s.Heading>
      <s.Message>
        <Trans>We could not generate the graph this time.</Trans>
        <br />
        <Trans>Please try the table view instead.</Trans>
      </s.Message>
    </s.ChatErrorStateContainer>
  );
};
