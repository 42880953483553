import React from "react";
import { Button, Modal, CloseButton, Heading, Text, View, Link } from "@instructure/ui";
import { useUI } from "../../../context/ui";
import SchemaExplore from "../SchemaExplore/SchemaExplore";
import { IconInstructureLogoLine } from "@instructure/ui-icons";
import { useAuth } from "../../../context";
import { SHOW_CANVAS_SCHEMA } from "../../../Constants/features";
import useBreakpoint from "../../../hooks/useBreakpoint";
import { useLingui } from "@lingui/react";
import { Trans, msg } from "@lingui/macro";

const AvailableDataModal: React.FC = () => {
  const { _ } = useLingui();
  const { availableDataModalOpen, setAvailableDataModalOpen } = useUI();
  const { isChatDesktop } = useBreakpoint();
  const { featureFlags } = useAuth();
  const handleButtonClick = () => {
    setAvailableDataModalOpen(!availableDataModalOpen);
  };

  const renderCloseButton = () => {
    return (
      <CloseButton
        placement="end"
        offset="small"
        onClick={handleButtonClick}
        screenReaderLabel={_(msg`Close`)}
      />
    );
  };

  return (
    <Modal
      as="form"
      open={availableDataModalOpen}
      onDismiss={() => {
        setAvailableDataModalOpen(false);
      }}
      size={isChatDesktop ? "auto" : "fullscreen"}
      label={_(msg`Modal Dialog: Hello World`)}
    >
      <Modal.Header>
        {renderCloseButton()}
        <Heading>
          <Trans>Available Data</Trans>
        </Heading>
      </Modal.Header>
      <Modal.Body>
        <Text lineHeight="double">
          <Trans>
            Ask Your Data product uses Instructure's Data Access Platform to generate queries.
            Documentation on what is included can be found{" "}
          </Trans>
          <span> </span>
          <Link
            target="_blank"
            href="https://data-access-platform-api.s3.eu-central-1.amazonaws.com/tables/catalog.html#tables-in-canvas"
            renderIcon={<IconInstructureLogoLine size="x-small" />}
            iconPlacement="end"
          >
            <Trans>here</Trans>
          </Link>
          !
        </Text>
        {featureFlags.includes(SHOW_CANVAS_SCHEMA) && (
          <View height="50rem" display="block">
            <SchemaExplore
              htmlFilePath={"https://drawsql.app/teams/siddhant-1/diagrams/canvas-full/embed"}
            />
          </View>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleButtonClick} margin="0 x-small 0 0">
          <Trans>Close</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AvailableDataModal;
