import {
  Breadcrumb,
  Button,
  Flex,
  Heading,
  IconButton,
  IconHomeLine,
  IconKeyboardShortcutsLine,
  Tooltip,
} from "@instructure/ui";
import { useState } from "react";

import { useAuth } from "../../../context";
import { useUI } from "../../../context/ui";
import { ViewEnum } from "../../../context/ui/ui.i";
import { handleAnalyticsHubNavigation } from "../../../helpers";
import { KeyboardShortcutsModal } from "./KeyboardShortcutsModal/KeyboardShortcutsModal";
import SettingsMenu from "./SettingsMenu/SettingsMenu";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

export const Header = () => {
  const { _ } = useLingui();
  const { organization, baseURL, accountId } = useAuth();

  const [open, setOpen] = useState(false);
  const { view, setView } = useUI();

  const isInUsageView = view === ViewEnum.CANVAS_USAGE;
  const homeButton = (
    <Button
      renderIcon={<IconHomeLine />}
      onClick={() => setView(ViewEnum.CHAT_VIEW)}
      margin="0 0 0 small"
    >
      <Trans>Home</Trans>
    </Button>
  );

  let title;

  switch (view) {
    case ViewEnum.CANVAS_USAGE:
      title = <Trans>Usage</Trans>;
      break;
    case ViewEnum.PINBOARD_VIEW:
      title = <Trans>Pinboard</Trans>;
      break;
    case ViewEnum.CHAT_VIEW:
    default:
      title = <Trans>Chats</Trans>;
      break;
  }

  return (
    <div className="flex flex-col items-center justify-between border-b border-black md:flex-row">
      <Heading level="h1" margin="medium 0" color="primary" width="100%">
        <Breadcrumb label={_(msg`You are here:`)}>
          <Breadcrumb.Link
            target="_parent"
            href={handleAnalyticsHubNavigation(baseURL, accountId, false)}
          >
            {organization}
          </Breadcrumb.Link>
          <Breadcrumb.Link target="_parent" href={handleAnalyticsHubNavigation(baseURL, accountId)}>
            <Trans>Analytics Hub</Trans>
          </Breadcrumb.Link>
          <Breadcrumb.Link onClick={() => setView(ViewEnum.CHAT_VIEW)}>
            <Trans>Ask Your Data</Trans>
          </Breadcrumb.Link>
          <Breadcrumb.Link>{title}</Breadcrumb.Link>
        </Breadcrumb>
      </Heading>
      <Flex>
        {isInUsageView ? homeButton : <SettingsMenu />}
        <Tooltip renderTip={_(msg`Keyboard shortcuts`)} placement="bottom">
          <IconButton
            margin="x-small"
            withBackground={false}
            withBorder={false}
            onClick={() => {
              setOpen(true);
            }}
            screenReaderLabel={_(msg`Keyboard shortcuts`)}
          >
            <IconKeyboardShortcutsLine />
          </IconButton>
        </Tooltip>
      </Flex>
      <KeyboardShortcutsModal open={open} setOpen={setOpen} />
    </div>
  );
};
