import { DateTime } from "luxon";

import { MutableRefObject } from "react";
import { useChatData } from "../../../../../context/chat";
import { Doowii } from "../../../../../search/Doowii";
import { SpeechBubble } from "./SpeechBubble/SpeechBubble";
import { useDrawer } from "../../../../../context/mobileDrawers";

interface ChatTileProps {
  index: number;
  item: any;
  timestamp: DateTime;
  userName: { firstName: string; lastName: string };
  loading: boolean;
  single: boolean;
  doowii: MutableRefObject<Doowii | null>;
  followUpQuestions: string[];
  setQuery?: (value: string) => void;
}

export const ChatTile = ({
  index,
  item,
  timestamp,
  userName,
  loading,
  followUpQuestions,
  setQuery,
  single,
  doowii,
}: ChatTileProps) => {
  const { setDrawerState } = useDrawer();
  const {
    allResults,
    selectResult,
    currentResult: results,
    answer: answers,
    streamLoading,
  } = useChatData();

  const handleSearchHistoryClick = (i) => {
    if (allResults[i] !== undefined) {
      // @ts-ignore
      selectResult(allResults[i]);
      setDrawerState("result", true);
    }
  };

  const determineHighlight = () => {
    if (index > allResults.length - 1) return true;
    if (allResults.findIndex((res) => res?.id === results?.id) === index) return true;

    return false;
  };

  const selected = determineHighlight();

  const handleOnSelectChat = () => {
    if (loading) return;

    handleSearchHistoryClick(index);
  };

  return (
    <>
      <div>
        <SpeechBubble
          text={item}
          selected={selected}
          onSelectChat={handleOnSelectChat}
          timestamp={timestamp}
          userName={userName}
          loading={loading}
          single={single}
          doowii={doowii}
        />
        {(answers[index] && answers[index] !== "") || ((loading || streamLoading) && selected) ? (
          <SpeechBubble
            index={index}
            text={answers[index]}
            selected={selected}
            onSelectChat={handleOnSelectChat}
            timestamp={timestamp}
            loading={loading || streamLoading}
            followUpQuestions={followUpQuestions}
            setQuery={setQuery}
            single={single}
            doowii={doowii}
          />
        ) : null}
      </div>
    </>
  );
};
