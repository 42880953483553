/* eslint-disable lingui/no-unlocalized-strings */
import React from "react";
import styled, { keyframes } from "styled-components";

// Define keyframes for the bar growing animation
const grow = keyframes`
  0%, 20% {
    height: 0;
  }
  100% {
    height: 3.2em; /* Adjust as necessary for your font */
  }
`;

// Styled component for the loader container
const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%; /* Adjust as necessary for your layout */
`;

// Styled component for the static letter "d" with a dot
const StaticLetter = styled.div`
  font-size: 4em;
  font-family: "Outfit";
  font-weight: 600;
  color: #003a47;
  display: inline-flex;
  align-items: center;
  position: relative;
  margin-right: 20px;
`;

// Styled component for the dot next to the letter "d"
const Dot = styled.span`
  font-size: 0.5em;
  margin-left: 0.1em; /* Adjust as necessary for your font */
`;

// Styled component for the bar
const Bar = styled.div<{ animationDelay?: string }>`
  width: 0.5em; /* Adjust width as necessary */
  background-color: #003a47;
  border-radius: 0.15em; /* Makes the bar rounded */
  margin: 0 0.2em; /* Spacing between bars */
  animation: ${grow} 2s ease-in-out infinite;
  animation-delay: ${(props) => props.animationDelay || "0s"};
`;

// Styled component for the bar container
const BarContainer = styled.div`
  display: flex;
  align-items: flex-end; /* Align bars to bottom */
`;

const DoowiiLoader: React.FC = () => {
  return (
    <LoaderContainer>
      <StaticLetter>
        <span>d</span>
        <Dot>.</Dot>
      </StaticLetter>
      <BarContainer>
        <Bar animationDelay="0s" />
        <Bar animationDelay="0.3s" />
        <Bar animationDelay="0.6s" />
      </BarContainer>
    </LoaderContainer>
  );
};

export default DoowiiLoader;
