export function transformData(isDemo, selectedColumns: {}, data) {
  if (isDemo) {
    // Assuming fixed data structure: { type: string, value: number }
    return {
      categories: data.map((item) => item.type),
      seriesData: [
        {
          // eslint-disable-next-line lingui/no-unlocalized-strings
          name: "Factors",
          type: "bar",
          data: data.map((item) => item.value),
        },
      ],
      shouldStack: false,
    };
  }
  let allNumber = false; // Flag to indicate if both columns are of type number

  // Check if there are exactly two selectedColumns and both are of type 'number'
  if (Object.keys(selectedColumns).length === 2) {
    const types = Object.keys(selectedColumns).map((key) => selectedColumns[key].type);
    allNumber = types.every((type) => type === "number");
  }
  const categoryKey = allNumber
    ? Object.keys(selectedColumns)[0]
    : Object.keys(selectedColumns).find(
        (key) => selectedColumns[key].type === "string" || selectedColumns[key].type === "timestamp"
      );
  const valueKeys = allNumber
    ? [Object.keys(selectedColumns)[1]]
    : Object.keys(selectedColumns).filter((key) => selectedColumns[key].type === "number");

  // Count subcategories per category to decide on stack or group
  const subcategoryCounts = {};
  data.forEach((item) => {
    const category = item[categoryKey];
    subcategoryCounts[category] = (subcategoryCounts[category] || 0) + 1;
  });

  // Decide if we are stacking or grouping based on the subcategory count
  const shouldStack = Object.values(subcategoryCounts).some((count: number) => count > 5);

  const seriesData = valueKeys.map((valueKey) => {
    const name = selectedColumns[valueKey].name;
    return {
      name,
      type: "bar",
      stack: shouldStack ? "total" : null,
      data: data.map((item) => ({
        value: item[valueKey],
        // If not stacking, this will allow ECharts to group by subcategory
        name: item[categoryKey],
      })),
    };
  });

  return {
    categories: [...new Set(data.map((item) => item[categoryKey]))],
    seriesData,
    shouldStack,
  };
}
