import { DateTime } from "luxon";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useAuth } from "../../../../context/auth";
import { useChatData } from "../../../../context/chat";
import { Doowii } from "../../../../search/Doowii";
import { FullContainer } from "../../FullContainer/FullContainer";
import { fixUserName } from "./ChatArea.helper";
import { ChatTile } from "./ChatTile/ChatTile";
import { SpeechBubble } from "./ChatTile/SpeechBubble/SpeechBubble";
import SearchBar from "./SearchBar/SearchBar";
import ThreadSelector from "./ThreadSelector/ThreadSelector";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";

interface ChatAreaProps {
  loading: boolean;
  doowii: MutableRefObject<Doowii | null>;
  query: string;
  setQuery: (value: string) => void;
  loadingThread: boolean;
}

export const ChatArea = ({ loading, doowii, query, setQuery, loadingThread }: ChatAreaProps) => {
  const { _ } = useLingui();
  const { userDocument } = useAuth();
  const { allResults, searchHistory, currentThread, answer: answers } = useChatData();
  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [searchHistory, answers]);

  // Get timestamp for bot first message. Can be either the first message timestamp or now, if no messages yet.
  const [initialTime, setInitialTime] = useState(DateTime.now());
  useEffect(() => {
    if (loadingThread) return;

    if (allResults?.length) {
      setInitialTime(allResults[0].timestamp);

      return;
    }

    setInitialTime(DateTime.now());
  }, [currentThread, loadingThread]);

  const userName = fixUserName(userDocument);

  return (
    <>
      <ThreadSelector />

      <FullContainer
        className="overflow-y-auto px-6 py-4"
        ref={scrollRef}
        style={{ scrollbarGutter: "stable" }}
      >
        <div className="flex h-full flex-col">
          <SpeechBubble
            first={true}
            timestamp={initialTime}
            text={_(
              msg`Hello, ${userName.firstName}. I am here to help you find the insights you are looking for. Tell me what you are looking for, or try one of these suggestions.`
            )}
            setQuery={setQuery}
            single={false}
            doowii={doowii}
          />
          {searchHistory.map((item, index) => {
            let timestamp;
            if (allResults?.length && allResults[index]) timestamp = allResults[index].timestamp;

            return (
              <ChatTile
                key={index}
                item={item}
                index={index}
                timestamp={timestamp}
                userName={userName}
                loading={loading}
                followUpQuestions={allResults[index]?.follow_up_prompts ?? []}
                single={searchHistory.length === 1}
                setQuery={setQuery}
                doowii={doowii}
              />
            );
          })}
        </div>
      </FullContainer>
      <SearchBar doowii={doowii} query={query} setQuery={setQuery} />
    </>
  );
};
