const math = require("mathjs");

export class StringSemanticSearch {
  questionsMap: Record<string, number[]>;

  constructor(questions) {
    this.questionsMap = this.createMap(questions);
  }

  createMap(questions) {
    // Create an object with the question as keys and the embeddings as values
    const resultMap = {};
    for (let question in questions) {
      if (questions[question].embedding !== undefined) {
        resultMap[question] = questions[question].embedding;
      }
    }

    return resultMap;
  }

  /**
   * Calculate the cosine similarity between two vectors.
   *
   * @param {Array} vecA - The first vector.
   * @param {Array} vecB - The second vector.
   * @returns {number} The cosine similarity between the two vectors.
   */
  cosineSimilarity(vecA, vecB) {
    const dotProduct = math.dot(vecA, vecB);
    const vecANorm = math.norm(vecA);
    const vecBNorm = math.norm(vecB);
    return dotProduct / (vecANorm * vecBNorm);
  }

  /**
   * Search for the most similar embeddings to a given query embedding.
   *
   * @param {string} queryEmbedding - The embedding of the query string
   * @param {number} topN - The number of top similar embeddings to return. Default is 5.
   * @param {number} threshold - The minimum cosine similarity for an embedding to be considered as similar. Default is 0.9 but we will tweak the default.
   * @returns {Array} An array of objects, each containing the query and the corresponding sql.
   */
  search(queryEmbedding, topN = 5, threshold = 0.9) {
    const scores = {};
    for (const key in this.questionsMap) {
      try {
        const embedding = this.questionsMap[key];
        const similarity = this.cosineSimilarity(queryEmbedding, embedding);
        scores[key] = {
          similarity,
        };
      } catch (e) {
        console.error("Error calculating similarity for key: ", key, e);
      }
    }
    const sortedScores = Object.entries(scores)
      .sort((a: any[], b: any[]) => b[1].similarity - a[1].similarity)
      .filter(([key, embeddingData]: any) => embeddingData.similarity > threshold)
      .slice(0, topN)
      .map((entry) => entry[0]);

    return sortedScores;
  }
}
