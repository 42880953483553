import { CircularProgress } from "@mui/material";
import * as s from "./ChartLoadingSpinner.s";

export const ChartLoadingSpinner = () => {
  return (
    <s.ChartLoadingSpinner>
      <CircularProgress />
    </s.ChartLoadingSpinner>
  );
};
