import React, { useEffect, useMemo, useState } from 'react';
import { Tabs, View } from "@instructure/ui";
import { DataTableWrapper } from "../DataTable/DataTableWrapper";
import { allCanvasCharts, ChartBuilder, ChartProps } from "../ChartBuilder/ChartBuilder";
import { Pin } from "../../../context/pinboard/pinboard.i";
import { Result } from '../../../context/chat/chat.i';
import { ChartType } from '../../types';
import { Trans } from '@lingui/macro';

interface DataVisualizationTabsProps {
  result: Result | Pin;
  setTotalRows?: (rows: number) => void;
  chartType: any;
  setChartType: (type: any) => void;
  loadingText?: string;
  doowii?: any;
  didCreateChart?: (chartProps: ChartProps) => void;
}

const DataVisualizationTabs: React.FC<DataVisualizationTabsProps> = ({
  result,
  setTotalRows,
  chartType,
  setChartType,
  loadingText,
  doowii,
  didCreateChart
}) => {
  const [activeTab, setActiveTab] = useState("table");

  useEffect(() => {
    const chatVisualisation = (result as Pin)?.visualisation;
    setChartType(chatVisualisation);
    if (!allCanvasCharts.includes(chatVisualisation as unknown as ChartType)) {
      setActiveTab("table");
    } else {
      setActiveTab("chartBuilder");
    }
  }, [result?.id, setChartType]);

  const handleTabChange = (event, { index }) => {
    setActiveTab(index === 0 ? "table" : "chartBuilder");
  };

  const memoizedTabs = useMemo(() => {
    return (
      <div className="relative m-3 flex flex-1">
        <Tabs
          onRequestTabChange={handleTabChange}
          width="100%"
          style={{ border: "1px solid red" }}
          className="Tabs-wrapper"
          height="100%"
        >
          <Tabs.Panel
            renderTitle={<Trans>Table View</Trans>}
            isSelected={activeTab === "table"}
            padding="none"
            className="Tabs-panel"
            style={{ border: "1px solid blue" }}
            unmountOnExit={false}
          >
            <View
              as="div"
              padding="none"
              className="view-as-div"
              height="100%"
              display="flex"
              style={{ flexDirection: "column", flex: "1" }}
            >
              <DataTableWrapper
                setTotalRows={setTotalRows}
                result={result}
                loadingText={loadingText}
                doowii={doowii}
                chartType={chartType}
              />
            </View>
          </Tabs.Panel>
          <Tabs.Panel
            renderTitle={<Trans>Chart Builder</Trans>}
            isSelected={activeTab === "chartBuilder"}
            padding="none"
            height="100%"
            unmountOnExit={false}
          >
            <View
              as="div"
              padding="none"
              className="view-as-div"
              height="100%"
              display="flex"
              style={{ flexDirection: "column", flex: "1" }}
            >
              <ChartBuilder 
                result={result} 
                didCreateChart={didCreateChart} 
              />
            </View>
          </Tabs.Panel>
        </Tabs>
      </div>
    );
  }, [activeTab, result.id, setTotalRows, setChartType, didCreateChart]);

  return memoizedTabs;
};

export { DataVisualizationTabs };