import { Button } from "@instructure/ui-buttons";
import {
  IconAnalyticsLine,
  IconArrowOpenDownSolid,
  IconArrowOpenEndSolid,
  IconArrowOpenStartSolid,
} from "@instructure/ui-icons";
import React, { useState } from "react";
import { useAuth } from "../../../context";
import { useChatData } from "../../../context/chat";
import { useUI } from "../../../context/ui";
import { ViewEnum } from "../../../context/ui/ui.i";
import { handleAnalyticsHubNavigation } from "../../../helpers";
import { useLingui } from "@lingui/react";
import { Trans, msg } from "@lingui/macro";

const TopNavBar = () => {
  const { aboutModalOpen, availableDataModalOpen } = useUI();

  const isInfoAndHelpModalOpen = aboutModalOpen || availableDataModalOpen;

  const [isOpen, setIsOpen] = useState(false);
  const { tipsModalOpen } = useUI();
  return (
    <nav
      className={`relative ${
        tipsModalOpen ? "z-auto" : "z-[9999999]"
      } items-center border-b border-gray-300 bg-[#F5F5F5] px-4 py-3 chat-desktop:z-auto  chat-desktop:justify-start  chat-desktop:gap-x-10 ${
        isInfoAndHelpModalOpen ? "hidden" : "flex"
      } chat-desktop:flex`}
    >
      <NavBarContent isMobile={false} setIsOpen={setIsOpen} />
      <div
        id="mobile-menu"
        className={`fixed right-0 top-0 z-10 h-full w-full transform bg-[#F5F5F5] p-5 text-gray-800 ${
          isOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-500 chat-desktop:hidden`}
        role="dialog"
        aria-modal="true"
      >
        <NavBarContent isMobile setIsOpen={setIsOpen} />
      </div>
    </nav>
  );
};

type NavBarContentProps = {
  isMobile?: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const NavBarContent: React.FC<NavBarContentProps> = ({ isMobile, setIsOpen }) => {
  const { _ } = useLingui();
  const IconButton = isMobile ? MenuCloseButton : MenuMobileButton;
  const { featureFlags, baseURL, accountId } = useAuth();

  return (
    <>
      <div className="flex w-full items-center justify-between chat-desktop:w-auto">
        <MenuHeader />
        <IconButton onClick={() => setIsOpen((e) => !e)} />
      </div>
      {isMobile && <BackToCanvasButton />}
      <ul
        className={`text-gray-800 ${
          isMobile ? "mt-4 text-start" : "hidden gap-x-10  chat-desktop:flex"
        }`}
      >
        <MenuItem text={_(msg`Chats`)} view={ViewEnum.CHAT_VIEW} setIsOpen={setIsOpen} />
        <MenuItem text={_(msg`Pinboard`)} view={ViewEnum.PINBOARD_VIEW} setIsOpen={setIsOpen} />
        {isMobile && <InfoAndHelpMenuItem setIsOpen={setIsOpen} />}
        {/* <MenuItem text="Analytics Hub" view={ViewEnum.CANVAS_USAGE} icon={<IconAnalyticsLine />} /> */}
      </ul>
      <div className="ml-auto hidden chat-desktop:flex">
        <a
          target="_parent"
          href={handleAnalyticsHubNavigation(baseURL, accountId)}
          style={{
            textDecoration: "none",
          }}
        >
          <Button
            renderIcon={<IconAnalyticsLine />}
            margin="0 0 0 auto"
            withBackground={false}
            themeOverride={{
              borderWidth: "0",
              borderStyle: "none",
            }}
          >
            <Trans>Analytics Hub</Trans>
          </Button>
        </a>
      </div>
    </>
  );
};

type MenuItemProps = {
  text: string;
  view: ViewEnum;
  icon?: React.ReactNode;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const MenuItem: React.FC<MenuItemProps> = ({ text, view, icon, setIsOpen }) => {
  const { view: currentView, setView } = useUI();
  const { loading } = useChatData();

  const isActive = currentView === view;

  return (
    <li className="border-b border-gray-300 py-2 chat-desktop:border-none chat-desktop:py-0">
      <button
        disabled={loading}
        className={`flex w-full items-center justify-between px-2 py-2 chat-desktop:w-auto ${
          isActive ? " font-bold underline decoration-2 underline-offset-8" : ""
        }`}
        onClick={() => {
          setView(view);
          setIsOpen(false);
        }}
      >
        {icon && <span className="mr-2">{icon}</span>}
        {text}
        {!isActive && (
          <span className="chat-desktop:hidden">
            <IconArrowOpenEndSolid />
          </span>
        )}
      </button>
    </li>
  );
};

const InfoAndHelpMenuItem: React.FC<Pick<MenuItemProps, "setIsOpen">> = ({ setIsOpen }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const { setAvailableDataModalOpen, setAboutModalOpen } = useUI();
  const { loading } = useChatData();
  const { _ } = useLingui();

  return (
    <li className="border-b border-gray-300 py-2 chat-desktop:border-none chat-desktop:py-0">
      <button
        disabled={loading}
        className={`flex w-full items-center justify-between px-2 py-2 chat-desktop:w-auto`}
        onClick={() => setOpenMenu((e) => !e)}
      >
        <Trans>Info & Help</Trans>
        <span className="chat-desktop:hidden">
          {openMenu ? <IconArrowOpenDownSolid /> : <IconArrowOpenEndSolid />}
        </span>
      </button>
      {openMenu && (
        <ul className="ml-6 mt-4 text-start text-gray-800">
          <MenuItem
            text={_(msg`Usage Trends`)}
            view={ViewEnum.CANVAS_USAGE}
            setIsOpen={setIsOpen}
          />

          <li className="border-b border-gray-300 py-2 chat-desktop:border-none chat-desktop:py-0">
            <button
              disabled={loading}
              className={`flex w-full items-center justify-between px-2 py-2 chat-desktop:w-auto`}
              onClick={() => {
                setAvailableDataModalOpen(true);
                setIsOpen(false);
              }}
            >
              <Trans>Available Data</Trans>
            </button>
          </li>
          <li className=" py-2 chat-desktop:border-none chat-desktop:py-0">
            <button
              disabled={loading}
              className={`flex w-full items-center justify-between px-2 py-2 chat-desktop:w-auto`}
              onClick={() => {
                setAboutModalOpen(true);
                setIsOpen(false);
              }}
            >
              <Trans>About</Trans>
            </button>
          </li>
        </ul>
      )}
    </li>
  );
};

const MenuHeader: React.FC = () => {
  return (
    <div className="ml-1">
      <p className="text-[22px] font-bold leading-tight text-[#394B58]">
        <Trans>Ask Your Data</Trans>
      </p>
    </div>
  );
};

const MenuMobileButton: React.FC<{ isOpen: boolean; onClick: () => void }> = ({
  isOpen,
  onClick,
}) => {
  return (
    <div className="leading-none chat-desktop:hidden">
      <button
        className="text-gray-800"
        onClick={onClick}
        aria-label="Toggle Menu"
        aria-expanded={isOpen}
        aria-controls="mobile-menu"
      >
        <svg
          className="h-8 w-8"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M4 6h16M4 12h16M4 18h16"
          ></path>
        </svg>
      </button>
    </div>
  );
};

const MenuCloseButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <button className="text-gray-800" onClick={onClick} aria-label="Close Menu">
      <svg
        className="h-6 w-6"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M6 18L18 6M6 6l12 12"
        ></path>
      </svg>
    </button>
  );
};

const BackToCanvasButton: React.FC = () => {
  const { baseURL, featureFlags, accountId } = useAuth();

  return (
    <>
      <div className="my-5 flex items-center gap-2">
        <IconArrowOpenStartSolid />
        <a href={handleAnalyticsHubNavigation(baseURL, accountId, false)}>
          <Trans>Back to Canvas</Trans>
        </a>
      </div>
      <div className="my-5 flex items-center gap-2">
        <IconArrowOpenStartSolid />
        <a target="_parent" href={handleAnalyticsHubNavigation(baseURL, accountId)}>
          <Trans>Back to Analytics Hub</Trans>
        </a>
      </div>
    </>
  );
};

export default TopNavBar;
